import React from 'react'

const Footer = () => {
  return (
    <footer>
    <div className="container-fluid p-0 border-top bg-white">
      <div className="container pt-5">
        <div className="pb-5 border-bottom">
          <ul className="nav justify-content-between">
            <li className="nav-item d-flex ">
              <div className="pe-2">
                <svg
                  className="svg-inline--fa fa-location-dot fa-2x"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="location-dot"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                  data-fa-i2svg=""
                >
                  <path
                    fill="currentColor"
                    d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"
                  />
                </svg>
                {/* <i class="fa fa-2x fa-location-dot" aria-hidden="true"></i> Font Awesome fontawesome.com */}
              </div>
              <div className="default-cursor">
                <h5 className="fw-bold">Find Us</h5>
                <p className="text-capitalize">
                  Time Square Empire, WRTeam , Mirzapar Highway , Bhuj , Kutch ,
                  Gujarat - 370001
                </p>
              </div>
            </li>
            <a href="tel:1234567890" className="text-reset text-decoration-none">
              <li className="nav-item d-flex ">
                <div className="pe-2">
                  <svg
                    className="svg-inline--fa fa-phone-volume fa-2x"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="phone-volume"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M280 0C408.1 0 512 103.9 512 232c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-101.6-82.4-184-184-184c-13.3 0-24-10.7-24-24s10.7-24 24-24zm8 192a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm-32-72c0-13.3 10.7-24 24-24c75.1 0 136 60.9 136 136c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-48.6-39.4-88-88-88c-13.3 0-24-10.7-24-24zM117.5 1.4c19.4-5.3 39.7 4.6 47.4 23.2l40 96c6.8 16.3 2.1 35.2-11.6 46.3L144 207.3c33.3 70.4 90.3 127.4 160.7 160.7L345 318.7c11.2-13.7 30-18.4 46.3-11.6l96 40c18.6 7.7 28.5 28 23.2 47.4l-24 88C481.8 499.9 466 512 448 512C200.6 512 0 311.4 0 64C0 46 12.1 30.2 29.5 25.4l88-24z"
                    />
                  </svg>
                  {/* <i class="fa-solid fa-phone-volume fa-2x"></i> Font Awesome fontawesome.com */}
                </div>
                <div className="default-cursor">
                  <h5 className="fw-bold">Call Us</h5>
                  <p className="text-capitalize">1234567890</p>
                </div>
              </li>
            </a>
            <a
              href="mailto:eshop@gmail.com"
              className="text-reset text-decoration-none"
            >
              <li className="nav-item d-flex ">
                <div className="pe-2">
                  <svg
                    className="svg-inline--fa fa-envelope-open fa-2x"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="far"
                    data-icon="envelope-open"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M255.4 48.2c.2-.1 .4-.2 .6-.2s.4 .1 .6 .2L460.6 194c2.1 1.5 3.4 3.9 3.4 6.5v13.6L291.5 355.7c-20.7 17-50.4 17-71.1 0L48 214.1V200.5c0-2.6 1.2-5 3.4-6.5L255.4 48.2zM48 276.2L190 392.8c38.4 31.5 93.7 31.5 132 0L464 276.2V456c0 4.4-3.6 8-8 8H56c-4.4 0-8-3.6-8-8V276.2zM256 0c-10.2 0-20.2 3.2-28.5 9.1L23.5 154.9C8.7 165.4 0 182.4 0 200.5V456c0 30.9 25.1 56 56 56H456c30.9 0 56-25.1 56-56V200.5c0-18.1-8.7-35.1-23.4-45.6L284.5 9.1C276.2 3.2 266.2 0 256 0z"
                    />
                  </svg>
                  {/* <i class="fa-regular fa-envelope-open fa-2x"></i> Font Awesome fontawesome.com */}
                </div>
                <div className="default-cursor">
                  <h5 className="fw-bold">Mail Us</h5>
                  <p>eshop@gmail.com</p>
                </div>
              </li>
            </a>
          </ul>
        </div>
        <div className="row overflow-hidden pt-5">
          <div className="col-lg-5 col-md-12 footer-icons-section">
            <a className="footer-logo pointer" href="https://eshopweb.store/">
              <img
               src="/Images/Logo.jpg"
                className=""
                alt='Logo'
              />
            </a>
            <h5 className="fw-bold mx-2 my-3">Subscribe</h5>
            <a
              href="https://facebook.com/"
              className="style-none"
              target="_blank"
            >
              <ion-icon
                name="logo-facebook"
                className="social-media-icon pointer md hydrated"
                role="img"
              />
            </a>
            <a href="https://twitter.com/" className="style-none" target="_blank">
              <ion-icon
                name="logo-twitter"
                className="social-media-icon pointer md hydrated"
                role="img"
              />
            </a>
            <a
              href="https://instagram.com/"
              className="style-none"
              target="_blank"
            >
              <ion-icon
                name="logo-instagram"
                className="social-media-icon pointer md hydrated"
                role="img"
              />
            </a>
            <a href="https://youtube.com/" className="style-none" target="_blank">
              <ion-icon
                name="logo-youtube"
                className="social-media-icon pointer md hydrated"
                role="img"
              />
            </a>
          </div>
          <div className="col-lg-7 col-md-12 footer-text-section">
            <div className="row justify-content-around ps-md-4">
              <div className="col-4 pe-1 default-cursor">
                <h5>Company</h5>
                <a
                  href="https://eshopweb.store/home/about-us"
                  className="text-reset text-decoration-none"
                >
                  <p className="m-0">About Us</p>
                </a>
                <a
                  href="https://eshopweb.store/home/system-contact-us"
                  className="text-reset text-decoration-none"
                >
                  <p className="m-0">Contact Us</p>
                </a>
                <a
                  href="https://eshopweb.store/products"
                  className="text-reset text-decoration-none"
                >
                  <p className="m-0">Products</p>
                </a>
                <a
                  href="https://eshopweb.store/home/categories"
                  className="text-reset text-decoration-none"
                >
                  <p className="m-0">Categories</p>
                </a>
              </div>
              <div className="col-4 pe-1 default-cursor">
                <h5>Legal</h5>
                <a
                  href="https://eshopweb.store/home/privacy-policy"
                  className="text-reset text-decoration-none"
                >
                  <p className="m-0">Privacy Policy</p>
                </a>
                <a
                  href="https://eshopweb.store/home/terms-and-conditions"
                  className="text-reset text-decoration-none"
                >
                  <p className="m-0">Terms &amp; Condition</p>
                </a>
                <a
                  href="https://eshopweb.store/home/return-policy"
                  className="text-reset text-decoration-none"
                >
                  <p className="m-0">Return Policy</p>
                </a>
                <a
                  href="https://eshopweb.store/home/shipping-policy"
                  className="text-reset text-decoration-none"
                >
                  <p className="m-0">Shipping Policy</p>
                </a>
              </div>
              <div className="col-4 default-cursor">
                <h5>Resources</h5>
                <a href="https://eshopweb.store/home/contact-us">
                  <p className="m-0">Support</p>
                </a>
                <a href="https://play.google.com/" target="_blank">
                  <p className="m-0">Android App</p>
                </a>
                <a href="https://www.apple.com/in/app-store/" target="_blank">
                  <p className="m-0">Ios App</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-section default-cursor">
        <span>
          Copyright © 2024 - 2025 eShop - ecommerce, All Right Reserved WRTeam.
        </span>
      </div>
    </div>
  </footer>
  
  )
}

export default Footer