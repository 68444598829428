import React from "react";

const Brands = () => {
  return (
    <section className="container mt-3">
      <div className="row">
        <div className="col-md-2 col-4">
          <div className="brands-card">
            <a
              href="https://eshopweb.store/products?brand=puma-1"
              className="text-reset text-decoration-none"
            >
              <div className="brands-page-image">
                <img
                  src="https://eshopweb.store/uploads/media/2024/Puma-Logo.png"
                  alt="Puma"
                />
              </div>
              <div className="categories-card-text">
                <h4>Puma</h4>
              </div>
            </a>
          </div>
        </div>
        <div className="col-md-2 col-4">
          <div className="brands-card">
            <a
              href="https://eshopweb.store/products?brand=apple"
              className="text-reset text-decoration-none"
            >
              <div className="brands-page-image">
                <img
                  src="https://eshopweb.store/uploads/media/2023/Apple_logo_black_(1).png"
                  alt="Apple"
                />
              </div>
              <div className="categories-card-text">
                <h4>Apple</h4>
              </div>
            </a>
          </div>
        </div>
        <div className="col-md-2 col-4">
          <div className="brands-card">
            <a
              href="https://eshopweb.store/products?brand=acer"
              className="text-reset text-decoration-none"
            >
              <div className="brands-page-image">
                <img
                  src="https://eshopweb.store/uploads/media/2024/Acer-Logo.png"
                  alt="Acer"
                />
              </div>
              <div className="categories-card-text">
                <h4>Acer</h4>
              </div>
            </a>
          </div>
        </div>
        <div className="col-md-2 col-4">
          <div className="brands-card">
            <a
              href="https://eshopweb.store/products?brand=adobe"
              className="text-reset text-decoration-none"
            >
              <div className="brands-page-image">
                <img
                  src="https://eshopweb.store/uploads/media/2024/adobe.jpeg"
                  alt="Adobe"
                />
              </div>
              <div className="categories-card-text">
                <h4>Adobe</h4>
              </div>
            </a>
          </div>
        </div>
        <div className="col-md-2 col-4">
          <div className="brands-card">
            <a
              href="https://eshopweb.store/products?brand=away-1"
              className="text-reset text-decoration-none"
            >
              <div className="brands-page-image">
                <img
                  src="https://eshopweb.store/uploads/chat_media//Screenshot_2024-07-21-17-08-44-68_8725ce44afe22ce17e3660df7f33c32e.jpg"
                  alt="away"
                />
              </div>
              <div className="categories-card-text">
                <h4>away</h4>
              </div>
            </a>
          </div>
        </div>
        <div className="col-md-2 col-4">
          <div className="brands-card">
            <a
              href="https://eshopweb.store/products?brand=samsung-1"
              className="text-reset text-decoration-none"
            >
              <div className="brands-page-image">
                <img
                  src="https://eshopweb.store/uploads/media/2023/Samsung-Logo_wine.png"
                  alt="Samsung"
                />
              </div>
              <div className="categories-card-text">
                <h4>Samsung</h4>
              </div>
            </a>
          </div>
        </div>
        <div className="col-md-2 col-4">
          <div className="brands-card">
            <a
              href="https://eshopweb.store/products?brand=one-plus"
              className="text-reset text-decoration-none"
            >
              <div className="brands-page-image">
                <img
                  src="https://eshopweb.store/uploads/media/2023/OnePlus_Logo.png"
                  alt="One plus"
                />
              </div>
              <div className="categories-card-text">
                <h4>One plus</h4>
              </div>
            </a>
          </div>
        </div>
        <div className="col-md-2 col-4">
          <div className="brands-card">
            <a
              href="https://eshopweb.store/products?brand=lenovo"
              className="text-reset text-decoration-none"
            >
              <div className="brands-page-image">
                <img
                  src="https://eshopweb.store/uploads/media/2024/Lenovo-Logo-1.png"
                  alt="Lenovo"
                />
              </div>
              <div className="categories-card-text">
                <h4>Lenovo</h4>
              </div>
            </a>
          </div>
        </div>
        <div className="col-md-2 col-4">
          <div className="brands-card">
            <a
              href="https://eshopweb.store/products?brand=hp"
              className="text-reset text-decoration-none"
            >
              <div className="brands-page-image">
                <img
                  src="https://eshopweb.store/uploads/media/2024/1024px-HP_logo_2012_svg.png"
                  alt="HP"
                />
              </div>
              <div className="categories-card-text">
                <h4>HP</h4>
              </div>
            </a>
          </div>
        </div>
        <div className="col-md-2 col-4">
          <div className="brands-card">
            <a
              href="https://eshopweb.store/products?brand=godrej"
              className="text-reset text-decoration-none"
            >
              <div className="brands-page-image">
                <img
                  src="https://eshopweb.store/uploads/media/2024/Godrej_Logo_svg.png"
                  alt="Godrej"
                />
              </div>
              <div className="categories-card-text">
                <h4>Godrej</h4>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Brands;
