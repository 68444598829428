import React from "react";

const AboutUs = () => {
  return (
    <section className="container py-4">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="https://eshopweb.store/">Home</a>
          </li>
          <li className="breadcrumb-item active">About Us</li>
        </ol>
      </nav>
      <div className="text-center">
        <h2 className="section-title">About Us</h2>
      </div>
      <div className="text-justify">
        <div className="hrDiv text-body-secondary">
          <p></p>
          <p>
            Costumers ACCESSING, BROWSING OR OTHERWISE USING THE WEBSITE
            cityecommerce.com, Missed Call Service or mobile application
            INDICATES user is in AGREEMENT with cityecommerce vegetables &amp;
            fruits Pvt Ltd for ALL THE TERMS AND CONDITIONS MENTIONED
            henceforth. User is requested to READ terms and conditions CAREFULLY
            BEFORE PROCEEDING FURTHER.
            <br />
            User is the person, group of person, company, trust, society, legal
            entity, legal personality or anyone who visits website, mobile app
            or gives missed call or places order with Gmartfresh via phone or
            website or mobile application or browse through website
            www.Gmartfresh.com.
          </p>
          <p>
            Gmartfresh reserves the right to add, alter, change, modify or
            delete any of these terms and conditions at any time without prior
            information. The altered terms and conditions becomes binding on the
            user since the moment same are unloaded on the website
            www.Gmartfresh.com
          </p>
          <p>
            Gmartfresh is in trade of fresh fruits and vegetables and delivers
            the order to home (user’s desired address) directly.
          </p>
          <p>
            That any user who gives missed call/call for order on any number
            published/used by Gmartfresh.com, consents to receive, accept calls
            and messages or any after communication from Gmartfresh vegetables
            &amp; fruits Pvt Ltd for Promotion and Telemarketing Purposes within
            a week.
          </p>
          <p>
            If a customer do not wish to receive any communication from
            Gmartfresh, please SMS NO OFFERS to 9512512125.
          </p>
          <p>
            Gmartfresh accept orders on all seven days and user will receive the
            delivery next day from date of order placement, as we at Gmartfresh
            procure the fresh produce from the procurement center and deliver it
            straight to user.
          </p>
          <p>
            There is Minimum Order value of Rs. 200. There are no delivery
            charges on an order worth Rs. 200 or above. In special cases, if
            permitted, order value is less then Rs. 200/– , Rs. 40 as shipping
            charges shall be charged from user.
          </p>
          <p>
            Gmartfresh updates the prices on daily basis and the price displayed
            at our website www.Gmartfresh.com, at the time of placement of order
            by user he/she/it will be charged as per the price listed at the
            website www.Gmartfresh.com.
          </p>
          <p>
            In the event, though there are remote possibilities, of wrong
            invoice generation due to any reason, in case it happens Gmartfresh
            vegetables &amp; fruits Pvt Ltd reserve its right to again raise the
            correct invoice at the revised amount and same shall be paid by
            user.
          </p>
          <p>
            At times it is difficult to weigh certain vegetables or fruits
            exactly as per the order or desired quantity of user, hence the
            delivery might be with five percent variation on both higher or
            lower side of exact ordered quantity, user are hereby under takes to
            pay to Gmartfresh vegetables &amp; fruits Pvt Ltd as per the final
            invoice. We at Gmartfresh understands and our endeavor is to always
            deliver in exact quantity in consonance with quantity ordered but
            every time it’s not possible but Gmartfresh guarantee the fair deal
            and weight to all its users. Gmartfresh further assures its users
            that at no instance delivery weights/quantity vary dramatically from
            what quantity ordered by user.
          </p>
          <p>
            If some product is not available or is not of good quality, the same
            item will not be delivered and will be adjusted accordingly in the
            invoice; all rights in this regards are reserved with Gmartfresh.
            Images of Fruits &amp; Vegetables present in the website are for
            demonstration purpose and may not resemble exactly in size, colour,
            weight, contrast etc; though we assure our best to maintain the best
            quality in product, which is being our foremost commitment to the
            customer.
          </p>
          <p>
            All orders placed before 11 PM in the Night will be delivered next
            day or as per delivery date chosen.
          </p>
          <p>&nbsp;</p> <p />
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
