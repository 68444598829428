import React from "react";
import { NavLink } from "react-router-dom";
const Category = () => {
  return (
    <section className="container">
      <div className="mb-4 mb-md-5">
        <div className="align-items-center d-flex flex-wrap justify-content-between pb-3">
          <h1 className="section-title  pointer">Popular Categories</h1>

          <NavLink to="/categories">
            <button className="btn viewmorebtn">View More</button>
          </NavLink>
        </div>
        <div className="swiperForCategories swiper mySwiper2 swiper-initialized swiper-horizontal swiper-backface-hidden">
          <div
            className="swiper-wrapper grab text-center"
            id="swiper-wrapper-2923ea77737cfaec"
            aria-live="polite"
          >
            <div
              className="swiper-slide overflow-hidden swiper-slide-active"
              role="group"
              aria-label="1 / 9"
              style={{ width: "153.25px", marginRight: "10px;" }}
            >
              <div className="col p-0 fit-content">
                <div className="categories-card">
                  <a
                    href="https://eshopweb.store/products/category/electronics-1"
                    className="text-reset text-decoration-none"
                  >
                    <div className="categories-image">
                      <img
                        src="https://eshopweb.store/uploads/media/2024/thumb-sm/2150757517.jpg"
                        alt=""
                      />
                    </div>
                    <div className="categories-card-text">
                      <h4>Electronics</h4>
                    </div>
                  </a>
                </div>
              </div>
            </div>

            <div
              className="swiper-slide overflow-hidden swiper-slide-next"
              role="group"
              aria-label="2 / 9"
              style={{ width: "153.25px", marginRight: "10px;" }}
            >
              <div className="col p-0 fit-content">
                <div className="categories-card">
                  <a
                    href="https://eshopweb.store/products/category/home"
                    className="text-reset text-decoration-none"
                  >
                    <div className="categories-image">
                      <img
                        src="https://eshopweb.store/uploads/media/2024/thumb-sm/1975355.jpg"
                        alt=""
                      />
                    </div>
                    <div className="categories-card-text">
                      <h4>Home</h4>
                    </div>
                  </a>
                </div>
              </div>
            </div>

            <div
              className="swiper-slide overflow-hidden"
              role="group"
              aria-label="3 / 9"
              style={{ width: "153.25px", marginRight: "10px;" }}
            >
              <div className="col p-0 fit-content">
                <div className="categories-card">
                  <a
                    href="https://eshopweb.store/products/category/baby-care-1"
                    className="text-reset text-decoration-none"
                  >
                    <div className="categories-image">
                      <img
                        src="https://eshopweb.store/uploads/media/2024/thumb-sm/baby-bath-skincare-products.jpg"
                        alt=""
                      />
                    </div>
                    <div className="categories-card-text">
                      <h4>Baby Care</h4>
                    </div>
                  </a>
                </div>
              </div>
            </div>

            <div
              className="swiper-slide overflow-hidden"
              role="group"
              aria-label="4 / 9"
              style={{ width: "153.25px", marginRight: "10px;" }}
            >
              <div className="col p-0 fit-content">
                <div className="categories-card">
                  <a
                    href="https://eshopweb.store/products/category/footwear"
                    className="text-reset text-decoration-none"
                  >
                    <div className="categories-image">
                      <img
                        src="https://eshopweb.store/uploads/media/2024/thumb-sm/25546.jpg"
                        alt=""
                      />
                    </div>
                    <div className="categories-card-text">
                      <h4>Footwear</h4>
                    </div>
                  </a>
                </div>
              </div>
            </div>

            <div
              className="swiper-slide overflow-hidden"
              role="group"
              aria-label="5 / 9"
              style={{ width: "153.25px", marginRight: "10px;" }}
            >
              <div className="col p-0 fit-content">
                <div className="categories-card">
                  <a
                    href="https://eshopweb.store/products/category/watches-2"
                    className="text-reset text-decoration-none"
                  >
                    <div className="categories-image">
                      <img
                        src="https://eshopweb.store/uploads/media/2024/thumb-sm/wat2.jpg"
                        alt=""
                      />
                    </div>
                    <div className="categories-card-text">
                      <h4>Watches</h4>
                    </div>
                  </a>
                </div>
              </div>
            </div>

            <div
              className="swiper-slide overflow-hidden"
              role="group"
              aria-label="6 / 9"
              style={{ width: "153.25px", marginRight: "10px;" }}
            >
              <div className="col p-0 fit-content">
                <div className="categories-card">
                  <a
                    href="https://eshopweb.store/products/category/mens-fashion"
                    className="text-reset text-decoration-none"
                  >
                    <div className="categories-image">
                      <img
                        src="https://eshopweb.store/uploads/media/2024/thumb-sm/2021687.jpg"
                        alt=""
                      />
                    </div>
                    <div className="categories-card-text">
                      <h4>Mens Fashion</h4>
                    </div>
                  </a>
                </div>
              </div>
            </div>

            <div
              className="swiper-slide overflow-hidden"
              role="group"
              aria-label="7 / 9"
              style={{ width: "153.25px", marginRight: "10px;" }}
            >
              <div className="col p-0 fit-content">
                <div className="categories-card">
                  <a
                    href="https://eshopweb.store/products/category/womens-fashion-2"
                    className="text-reset text-decoration-none"
                  >
                    <div className="categories-image">
                      <img
                        src="https://eshopweb.store/uploads/media/2024/thumb-sm/three-indian-women-engineers-wear-hard-hats-having-tricolour-from-indian-flag.jpg"
                        alt=""
                      />
                    </div>
                    <div className="categories-card-text">
                      <h4>Women's Fashion</h4>
                    </div>
                  </a>
                </div>
              </div>
            </div>

            <div
              className="swiper-slide overflow-hidden"
              role="group"
              aria-label="8 / 9"
              style={{ width: "153.25px", marginRight: "10px;" }}
            >
              <div className="col p-0 fit-content">
                <div className="categories-card">
                  <a
                    href="https://eshopweb.store/products/category/groceries"
                    className="text-reset text-decoration-none"
                  >
                    <div className="categories-image">
                      <img
                        src="https://eshopweb.store/uploads/media/2024/thumb-sm/veg.jpg"
                        alt=""
                      />
                    </div>
                    <div className="categories-card-text">
                      <h4>Groceries</h4>
                    </div>
                  </a>
                </div>
              </div>
            </div>

            <div
              className="swiper-slide overflow-hidden"
              role="group"
              aria-label="9 / 9"
              style={{ width: "153.25px", marginRight: "10px;" }}
            >
              <div className="col p-0 fit-content">
                <div className="categories-card">
                  <a
                    href="https://eshopweb.store/products/category/mobile"
                    className="text-reset text-decoration-none"
                  >
                    <div className="categories-image">
                      <img
                        src="https://eshopweb.store/uploads/media/2024/thumb-sm/mob9.jpg"
                        alt=""
                      />
                    </div>
                    <div className="categories-card-text">
                      <h4>MOBILE</h4>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="swiper-button-next"
            tabindex="0"
            role="button"
            aria-label="Next slide"
            aria-controls="swiper-wrapper-2923ea77737cfaec"
            aria-disabled="false"
          >
            <ion-icon
              name="chevron-forward-outline"
              role="img"
              className="md flip-rtl hydrated"
            ></ion-icon>
          </div>
          <div
            className="swiper-button-prev swiper-button-disabled"
            tabindex="-1"
            role="button"
            aria-label="Previous slide"
            aria-controls="swiper-wrapper-2923ea77737cfaec"
            aria-disabled="true"
          >
            <ion-icon
              name="chevron-back-outline"
              role="img"
              className="md flip-rtl hydrated"
            ></ion-icon>
          </div>
          <span
            className="swiper-notification"
            aria-live="assertive"
            aria-atomic="true"
          ></span>
        </div>
      </div>
    </section>
  );
};

export default Category;
