import React from 'react'

const NewLaunch = () => {
  return (
    <section className="container">
    {/* Style 1 Design*/}
    {/* section 4 */}
    <div className="mb-4 mb-md-5 row">
      <div className="col-xl-8 col-lg-12">
        <div className="align-items-center d-flex flex-wrap justify-content-between pb-3">
          <h1 className="section-title  pointer">Newly Launched Smartphones</h1>
          <a href="https://eshopweb.store/products/section/115/newly-launched-smartphones">
            <button className="btn viewmorebtn">View More</button>
          </a>
        </div>
        <div className="row g-2">
          <div className="col-md-4 col-6">
            <a
              href="https://eshopweb.store/products/details/one-plus-11-pro-5g"
              className="text-reset text-decoration-none"
            ></a>
            <div
              className="card-temp card p-0 mb-3 mb-md-0 pointer product-card"
              data-product-id={69}
            >
              <a
                href="https://eshopweb.store/products/details/one-plus-11-pro-5g"
                className="text-reset text-decoration-none"
              >
                <div className="product-icon-spacebtw">
                  <div
                    className="add-to-fav-btn"
                    title="like"
                    data-product-id={69}
                  >
                    <ion-icon
                      className="ion-icon ion-icon-hover heart-outline text-dark md hydrated"
                      name="heart-outline"
                      role="img"
                    ></ion-icon>
                  </div>
                </div>
                <div className="product-image-1">
                  <img
                    className="pic-1 lazy card-img-top"
                    src="https://eshopweb.store/assets/front_end/modern/image/pictures/placeholder-image.png"
                    data-src="https://eshopweb.store/uploads/media/2023/thumb-sm/pzcVlrD6SlHhTuOg.png"
                  />
                </div>
              </a>
              <div className="card-body">
                <a
                  href="https://eshopweb.store/products/details/one-plus-11-pro-5g"
                  className="text-reset text-decoration-none"
                >
                  <h4 className="card-title">One plus 11 Pro 5G </h4>
                  <p className="card-text">Smartphone </p>
                  <div className="d-flex flex-column">
                    <div className="rating-container theme-krajee-svg rating-xs rating-animate rating-disabled">
                      <div className="rating-stars" tabIndex={0}>
                        <span className="empty-stars">
                          <span className="star" title="One Star">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Two Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Three Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Four Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Five Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                        </span>
                        <span className="filled-stars" style={{ width: "96%" }}>
                          <span className="star" title="One Star">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Two Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Three Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Four Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Five Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                        </span>
                      </div>
                    </div>
                    <input
                      id="input-3-ltr-star-md"
                      name="input-3-ltr-star-md"
                      className="kv-ltr-theme-svg-star rating-input"
                      defaultValue="4.80"
                      dir="ltr"
                      data-size="xs"
                      data-show-clear="false"
                      data-show-caption="false"
                      readOnly="readonly"
                      tabIndex={-1}
                    />
                  </div>
                  <div className="d-flex gap-1">
                    <h4 className="card-price" itemProp="offers">
                      <span itemProp="price"> $17,500</span>
                    </h4>
                  </div>
                </a>
                <a
                  href="#"
                  className="btn add-in-cart-btn add_to_cart w-100 p-1 btn-sm quickview-trigger"
                  data-product-id={69}
                  data-product-variant-id=""
                  data-product-title="One plus 11 Pro 5G"
                  data-product-image="https://eshopweb.store/uploads/media/2023/pzcVlrD6SlHhTuOg.png"
                  data-product-price={56999}
                  data-min={1}
                  data-step={1}
                  data-product-description="8 GB RAM+128 GB Storage: (Eternal Green/Titan Black)

16 GB RAM+256 GB Storage: (Eternal Green/Titan Black)

16 GB RAM+256 GB Storage: (Marble Odyssey)"
                  data-bs-toggle="modal"
                  data-bs-target="#quickview"
                >
                  <span className="add-in-cart">Add to Cart</span>
                  <span className="add-in-cart-icon">
                    <svg
                      className="svg-inline--fa fa-cart-shopping color-white"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="cart-shopping"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                      data-fa-i2svg=""
                    >
                      <path
                        fill="currentColor"
                        d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"
                      />
                    </svg>
                    {/* <i class="fa-solid fa-cart-shopping
                          color-white"></i> Font Awesome fontawesome.com */}
                  </span>
                </a>
                {/* <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#quickview">
                                  Launch demo modal
                              </button> */}
              </div>
              <div className="product-icon-onhover">
                <div className="product-icon-spacebtw">
                  <div className="shuffle-box">
                    <a
                      className="compare text-reset text-decoration-none shuffle"
                      data-tip="Compare"
                      data-product-id={69}
                      data-product-variant-id=""
                    >
                      <ion-icon
                        name="shuffle-outline"
                        className="ion-icon-hover pointer shuffle md hydrated"
                        role="img"
                      ></ion-icon>
                    </a>
                  </div>
                </div>
                <div className="product-icon-spacebtw">
                  <div
                    className="quick-search-box quickview-trigger"
                    data-tip="Quick View"
                    data-product-id={69}
                    data-product-variant-id={1132}
                  >
                    <ion-icon
                      name="search-outline"
                      className="ion-icon-hover pointer md hydrated"
                      data-bs-toggle="modal"
                      data-bs-target="#quickview"
                      role="img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-6">
            <a
              href="https://eshopweb.store/products/details/oneplus-nord-1"
              className="text-reset text-decoration-none"
            ></a>
            <div
              className="card-temp card p-0 mb-3 mb-md-0 pointer product-card"
              data-product-id={68}
            >
              <a
                href="https://eshopweb.store/products/details/oneplus-nord-1"
                className="text-reset text-decoration-none"
              >
                <div className="product-icon-spacebtw">
                  <div
                    className="add-to-fav-btn"
                    title="like"
                    data-product-id={68}
                  >
                    <ion-icon
                      className="ion-icon ion-icon-hover heart-outline text-dark md hydrated"
                      name="heart-outline"
                      role="img"
                    ></ion-icon>
                  </div>
                </div>
                <div className="product-image-1">
                  <img
                    className="pic-1 lazy card-img-top"
                    src="https://eshopweb.store/assets/front_end/modern/image/pictures/placeholder-image.png"
                    data-src="https://eshopweb.store/uploads/media/2023/thumb-sm/eig4OrX041cn85I2.jpg"
                  />
                </div>
              </a>
              <div className="card-body">
                <a
                  href="https://eshopweb.store/products/details/oneplus-nord-1"
                  className="text-reset text-decoration-none"
                >
                  <h4 className="card-title">Oneplus Nord </h4>
                  <p className="card-text">Smartphone </p>
                  <div className="d-flex flex-column">
                    <div className="rating-container theme-krajee-svg rating-xs rating-animate rating-disabled">
                      <div className="rating-stars" tabIndex={0}>
                        <span className="empty-stars">
                          <span className="star" title="One Star">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Two Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Three Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Four Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Five Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                        </span>
                        <span className="filled-stars" style={{ width: "90%" }}>
                          <span className="star" title="One Star">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Two Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Three Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Four Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Five Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                        </span>
                      </div>
                    </div>
                    <input
                      id="input-3-ltr-star-md"
                      name="input-3-ltr-star-md"
                      className="kv-ltr-theme-svg-star rating-input"
                      defaultValue="4.50"
                      dir="ltr"
                      data-size="xs"
                      data-show-clear="false"
                      data-show-caption="false"
                      readOnly="readonly"
                      tabIndex={-1}
                    />
                  </div>
                  <div className="d-flex gap-1">
                    <h4 className="card-price" itemProp="offers">
                      $23,996{" "}
                    </h4>
                    <p className="m-0 offer_price">
                      <small>
                        <span
                          className="text-decoration-line-through fw-bold"
                          itemProp="price"
                        >
                          $24,999{" "}
                        </span>
                        <span className="text-success fw-bold">
                          <span className="product-discount-label fw-bold">
                            4% off
                          </span>
                        </span>
                      </small>
                    </p>
                  </div>
                </a>
                <a
                  href="#"
                  className="btn add-in-cart-btn add_to_cart w-100 p-1 btn-sm quickview-trigger"
                  data-product-id={68}
                  data-product-variant-id=""
                  data-product-title="Oneplus Nord"
                  data-product-image="https://eshopweb.store/uploads/media/2023/eig4OrX041cn85I2.jpg"
                  data-product-price={27000}
                  data-min={1}
                  data-step={1}
                  data-product-description="6.44″ display,  OxygenOS, 90 Hz AMOLED display"
                  data-bs-toggle="modal"
                  data-bs-target="#quickview"
                >
                  <span className="add-in-cart">Add to Cart</span>
                  <span className="add-in-cart-icon">
                    <svg
                      className="svg-inline--fa fa-cart-shopping color-white"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="cart-shopping"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                      data-fa-i2svg=""
                    >
                      <path
                        fill="currentColor"
                        d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"
                      />
                    </svg>
                    {/* <i class="fa-solid fa-cart-shopping
                          color-white"></i> Font Awesome fontawesome.com */}
                  </span>
                </a>
                {/* <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#quickview">
                                  Launch demo modal
                              </button> */}
              </div>
              <div className="product-icon-onhover">
                <div className="product-icon-spacebtw">
                  <div className="shuffle-box">
                    <a
                      className="compare text-reset text-decoration-none shuffle"
                      data-tip="Compare"
                      data-product-id={68}
                      data-product-variant-id=""
                    >
                      <ion-icon
                        name="shuffle-outline"
                        className="ion-icon-hover pointer shuffle md hydrated"
                        role="img"
                      ></ion-icon>
                    </a>
                  </div>
                </div>
                <div className="product-icon-spacebtw">
                  <div
                    className="quick-search-box quickview-trigger"
                    data-tip="Quick View"
                    data-product-id={68}
                    data-product-variant-id={128}
                  >
                    <ion-icon
                      name="search-outline"
                      className="ion-icon-hover pointer md hydrated"
                      data-bs-toggle="modal"
                      data-bs-target="#quickview"
                      role="img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-6">
            <a
              href="https://eshopweb.store/products/details/samsung-s23-5g"
              className="text-reset text-decoration-none"
            ></a>
            <div
              className="card-temp card p-0 mb-3 mb-md-0 pointer product-card"
              data-product-id={64}
            >
              <a
                href="https://eshopweb.store/products/details/samsung-s23-5g"
                className="text-reset text-decoration-none"
              >
                <div className="product-icon-spacebtw">
                  <div
                    className="add-to-fav-btn"
                    title="like"
                    data-product-id={64}
                  >
                    <ion-icon
                      className="ion-icon ion-icon-hover heart-outline text-dark md hydrated"
                      name="heart-outline"
                      role="img"
                    ></ion-icon>
                  </div>
                </div>
                <div className="product-image-1">
                  <img
                    className="pic-1 lazy card-img-top"
                    src="https://eshopweb.store/assets/front_end/modern/image/pictures/placeholder-image.png"
                    data-src="https://eshopweb.store/uploads/media/2023/thumb-sm/a1d69358e5d13c50b215b75f321da986ac312cc4.jpg"
                  />
                </div>
              </a>
              <div className="card-body">
                <a
                  href="https://eshopweb.store/products/details/samsung-s23-5g"
                  className="text-reset text-decoration-none"
                >
                  <h4 className="card-title">Samsung s23 5G </h4>
                  <p className="card-text">Smartphone </p>
                  <div className="d-flex flex-column">
                    <div className="rating-container theme-krajee-svg rating-xs rating-animate rating-disabled">
                      <div className="rating-stars" tabIndex={0}>
                        <span className="empty-stars">
                          <span className="star" title="One Star">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Two Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Three Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Four Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Five Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                        </span>
                        <span
                          className="filled-stars"
                          style={{ width: "100%" }}
                        >
                          <span className="star" title="One Star">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Two Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Three Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Four Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Five Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                        </span>
                      </div>
                    </div>
                    <input
                      id="input-3-ltr-star-md"
                      name="input-3-ltr-star-md"
                      className="kv-ltr-theme-svg-star rating-input"
                      defaultValue={5.0}
                      dir="ltr"
                      data-size="xs"
                      data-show-clear="false"
                      data-show-caption="false"
                      readOnly="readonly"
                      tabIndex={-1}
                    />
                  </div>
                  <div className="d-flex gap-1">
                    <h4 className="card-price" itemProp="offers">
                      $8,500{" "}
                    </h4>
                    <p className="m-0 offer_price">
                      <small>
                        <span
                          className="text-decoration-line-through fw-bold"
                          itemProp="price"
                        >
                          $9,000{" "}
                        </span>
                        <span className="text-success fw-bold">
                          <span className="product-discount-label fw-bold">
                            5% off
                          </span>
                        </span>
                      </small>
                    </p>
                  </div>
                </a>
                <a
                  href="#"
                  className="btn add-in-cart-btn add_to_cart w-100 p-1 btn-sm quickview-trigger"
                  data-product-id={64}
                  data-product-variant-id=""
                  data-product-title="Samsung s23 5G"
                  data-product-image="https://eshopweb.store/uploads/media/2023/a1d69358e5d13c50b215b75f321da986ac312cc4.jpg"
                  data-product-price={10500}
                  data-min={1}
                  data-step={1}
                  data-product-description="Display 6.5 inches (16.51 cm)270 PPI, IPS LCD60 Hz Refresh Rate"
                  data-bs-toggle="modal"
                  data-bs-target="#quickview"
                >
                  <span className="add-in-cart">Add to Cart</span>
                  <span className="add-in-cart-icon">
                    <svg
                      className="svg-inline--fa fa-cart-shopping color-white"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="cart-shopping"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                      data-fa-i2svg=""
                    >
                      <path
                        fill="currentColor"
                        d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"
                      />
                    </svg>
                    {/* <i class="fa-solid fa-cart-shopping
                          color-white"></i> Font Awesome fontawesome.com */}
                  </span>
                </a>
                {/* <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#quickview">
                                  Launch demo modal
                              </button> */}
              </div>
              <div className="product-icon-onhover">
                <div className="product-icon-spacebtw">
                  <div className="shuffle-box">
                    <a
                      className="compare text-reset text-decoration-none shuffle"
                      data-tip="Compare"
                      data-product-id={64}
                      data-product-variant-id=""
                    >
                      <ion-icon
                        name="shuffle-outline"
                        className="ion-icon-hover pointer shuffle md hydrated"
                        role="img"
                      ></ion-icon>
                    </a>
                  </div>
                </div>
                <div className="product-icon-spacebtw">
                  <div
                    className="quick-search-box quickview-trigger"
                    data-tip="Quick View"
                    data-product-id={64}
                    data-product-variant-id={118}
                  >
                    <ion-icon
                      name="search-outline"
                      className="ion-icon-hover pointer md hydrated"
                      data-bs-toggle="modal"
                      data-bs-target="#quickview"
                      role="img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-4">
        <div className="border-radius-10 overflow-hidden product-banner-container">
          <a href="https://eshopweb.store/products/section/115/newly-launched-smartphones">
            <img
              className="pic-1 product-banner lazy entered loaded"
              src="https://eshopweb.store/uploads/media/2023/thumb-sm/a1d69358e5d13c50b215b75f321da986ac312cc4.jpg"
              data-src="https://eshopweb.store/uploads/media/2023/thumb-sm/a1d69358e5d13c50b215b75f321da986ac312cc4.jpg"
              data-ll-status="loaded"
            />
          </a>
        </div>
      </div>
    </div>
  </section>
  )
}

export default NewLaunch