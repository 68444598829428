import React from "react";
import SearchBar from "../components/SearchBar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Categories from "../components/Categories/Categories";
import Home from "../components/Home/Home";
import Products from "../components/Products/Products";
import Offers from "../components/OfferAdnFlashSales/Offers";
import ContactUs from "../components/ContactUs/ContactUs";
import AboutUs from "../components/AboutUs/AboutUs";
import Brands from "../components/Brands/Brands";
import Details from "../components/Details/Details";
import BottomDetails from "../components/Details/BottomDetails";

const Final = () => {
  return (
    <BrowserRouter>
      <SearchBar />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/products" element={<Products/>}/>
        <Route path="/offers_and_flash_sale" element={<Offers/>}/>
        <Route path="/contact_us" element={<ContactUs/>}/>
        <Route path="/about_us" element={<AboutUs/>}/>
        <Route path="/brand" element={<Brands/>}/> 
        <Route path="/products/details" element={<>
            <Details/>
            <BottomDetails/>
        </>}/>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default Final;
