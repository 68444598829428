import React from 'react'
import SliderShow from '../SliderShow'
import Category from '../Category'
import Brand from '../Brand'
import NewLaunch from '../NewLaunch'
import BestSofa from '../BestSofa'
import BigDeals from '../BigDeals'
import Grocery from '../Grocery'
import Electronics from '../Electronics'
import WatchDeals from '../WatchDeals'

const Home = () => {
  return (
    <>
    <SliderShow/>
    <Category/>
    <Brand/>
    <NewLaunch/>
    <BestSofa/>
    <BigDeals/>
    <Grocery/>
    <Electronics/>
    <WatchDeals/>
    </>
  )
}

export default Home