import React from 'react'

const Offers = () => {
  return (
    <section className="container py-4">
    <h2 className="section-title mb-3">Flash Sale</h2>
    <div className="main-content">
      <div className="">
        {/* Swiper */}
        <div className="swiper-container banner-swiper">
          <div className="swiper-wrapper"></div>
          {/* Add Pagination */}
          <div className="swiper-pagination swiper1-pagination" />
          {/* Add Pagination */}
          <div className="swiper-button-next" />
          <div className="swiper-button-prev" />
        </div>
      </div>
    </div>
    {/* offer-slider */}
    <main>
      <section className="my-4 bg-white">
        {/* Swiper1 */}
        <div className="swiper mySwiper grab border-radius-10 swiper-initialized swiper-horizontal swiper-backface-hidden">
          <div
            className="swiper-wrapper"
            id="swiper-wrapper-db86cbbf673cadb7"
            aria-live="off"
            style={{
              transitionDuration: "300ms",
              transform: "translate3d(-3348px, 0px, 0px)"
            }}
          >
            <div
              className="swiper-slide center-swiper-slide swiper-slide-next"
              role="group"
              aria-label="4 / 4"
              data-swiper-slide-index={3}
              style={{ width: 1116 }}
            >
              <a href="https://eshopweb.store/products/manage_offers/51">
                <img src="https://eshopweb.store/uploads/media/2022/watch.jpg" />
              </a>
            </div>
            <div
              className="swiper-slide center-swiper-slide"
              role="group"
              aria-label="1 / 4"
              data-swiper-slide-index={0}
              style={{ width: 1116 }}
            >
              <a href="https://eshopweb.store/products/manage_offers/48">
                <img src="https://eshopweb.store/uploads/media/2022/Women’s_Cosmetic.jpg" />
              </a>
            </div>
            <div
              className="swiper-slide center-swiper-slide swiper-slide-prev"
              role="group"
              aria-label="2 / 4"
              data-swiper-slide-index={1}
              style={{ width: 1116 }}
            >
              <a href="https://eshopweb.store/products/manage_offers/49">
                <img src="https://eshopweb.store/uploads/media/2022/Men’s_Accessories.jpg" />
              </a>
            </div>
            <div
              className="swiper-slide center-swiper-slide swiper-slide-active"
              role="group"
              aria-label="3 / 4"
              data-swiper-slide-index={2}
              style={{ width: 1116 }}
            >
              <a href="https://eshopweb.store/products/manage_offers/50">
                <img src="https://eshopweb.store/uploads/media/2022/Snack.jpg" />
              </a>
            </div>
          </div>
          {/* Add Pagination */}
          <div
            className="swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal swiper-pagination-bullets-dynamic"
            style={{ width: 80 }}
          >
            <span
              className="swiper-pagination-bullet swiper-pagination-bullet-active-prev-prev"
              style={{ left: "-8px" }}
            />
            <span
              className="swiper-pagination-bullet swiper-pagination-bullet-active-prev"
              style={{ left: "-8px" }}
            />
            <span
              className="swiper-pagination-bullet swiper-pagination-bullet-active swiper-pagination-bullet-active-main"
              aria-current="true"
              style={{ left: "-8px" }}
            />
            <span
              className="swiper-pagination-bullet swiper-pagination-bullet-active-next"
              style={{ left: "-8px" }}
            />
          </div>
          <span
            className="swiper-notification"
            aria-live="assertive"
            aria-atomic="true"
          />
        </div>
      </section>
    </main>
    <main>
      <section className="my-4 bg-white">
        <div className="offer-section2 container-fluid text-center">
          {/* Swiper3 */}
          <div className="swiper mySwiper3 swiper-arrow swiper-wid swiper-initialized swiper-horizontal swiper-backface-hidden">
            <div
              className="swiper-wrapper grab"
              id="swiper-wrapper-73c8720fed662c13"
              aria-live="polite"
            >
              <div
                className="swiper-slide background-none swiper-slide-active"
                role="group"
                aria-label="1 / 9"
                style={{ width: "265.5px", marginRight: 10 }}
              >
                <a href="https://eshopweb.store/products/manage_offers/72">
                  <img src="https://eshopweb.store/uploads/media/2022/wo1.jpg" />
                </a>
              </div>
              <div
                className="swiper-slide background-none swiper-slide-next"
                role="group"
                aria-label="2 / 9"
                style={{ width: "265.5px", marginRight: 10 }}
              >
                <a href="https://eshopweb.store/products/manage_offers/73">
                  <img src="https://eshopweb.store/uploads/media/2022/j1.jpg" />
                </a>
              </div>
              <div
                className="swiper-slide background-none"
                role="group"
                aria-label="3 / 9"
                style={{ width: "265.5px", marginRight: 10 }}
              >
                <a href="https://eshopweb.store/products/manage_offers/74">
                  <img src="https://eshopweb.store/uploads/media/2022/w03.jpg" />
                </a>
              </div>
              <div
                className="swiper-slide background-none"
                role="group"
                aria-label="4 / 9"
                style={{ width: "265.5px", marginRight: 10 }}
              >
                <a href="https://eshopweb.store/products/manage_offers/76">
                  <img src="https://eshopweb.store/uploads/media/2022/w04.jpg" />
                </a>
              </div>
              <div
                className="swiper-slide background-none"
                role="group"
                aria-label="5 / 9"
                style={{ width: "265.5px", marginRight: 10 }}
              >
                <a href="https://eshopweb.store/products/manage_offers/77">
                  <img src="https://eshopweb.store/uploads/media/2022/w02.jpg" />
                </a>
              </div>
              <div
                className="swiper-slide background-none"
                role="group"
                aria-label="6 / 9"
                style={{ width: "265.5px", marginRight: 10 }}
              >
                <a href="https://eshopweb.store/products/manage_offers/78">
                  <img src="https://eshopweb.store/uploads/media/2022/m1.jpg" />
                </a>
              </div>
              <div
                className="swiper-slide background-none"
                role="group"
                aria-label="7 / 9"
                style={{ width: "265.5px", marginRight: 10 }}
              >
                <a href="https://eshopweb.store/products/manage_offers/79">
                  <img src="https://eshopweb.store/uploads/media/2022/s1.jpg" />
                </a>
              </div>
              <div
                className="swiper-slide background-none"
                role="group"
                aria-label="8 / 9"
                style={{ width: "265.5px", marginRight: 10 }}
              >
                <a href="https://eshopweb.store/products/manage_offers/80">
                  <img src="https://eshopweb.store/uploads/media/2022/w1.jpg" />
                </a>
              </div>
              <div
                className="swiper-slide background-none"
                role="group"
                aria-label="9 / 9"
                style={{ width: "265.5px", marginRight: 10 }}
              >
                <a href="https://eshopweb.store/products/manage_offers/81">
                  <img src="https://eshopweb.store/uploads/media/2022/s2.jpg" />
                </a>
              </div>
            </div>
            {/* Add Pagination */}
            <div className="swiper-pagination swiper1-pagination" />
            {/* Add Pagination */}
            <div
              className="swiper-button-next"
              tabIndex={0}
              role="button"
              aria-label="Next slide"
              aria-controls="swiper-wrapper-73c8720fed662c13"
              aria-disabled="false"
            >
              <ion-icon
                name="chevron-forward-outline"
                role="img"
                className="md hydrated"
              />
            </div>
            <div
              className="swiper-button-prev swiper-button-disabled"
              tabIndex={-1}
              role="button"
              aria-label="Previous slide"
              aria-controls="swiper-wrapper-73c8720fed662c13"
              aria-disabled="true"
            >
              <ion-icon
                name="chevron-back-outline"
                role="img"
                className="md hydrated"
              />
            </div>
            <span
              className="swiper-notification"
              aria-live="assertive"
              aria-atomic="true"
            />
          </div>
        </div>
      </section>
    </main>
    <main>
      <section className="my-4 bg-white">
        {/* Swiper1 */}
        <div className="swiper mySwiper grab border-radius-10 swiper-initialized swiper-horizontal swiper-backface-hidden">
          <div
            className="swiper-wrapper"
            id="swiper-wrapper-361856fe1d0e10446"
            aria-live="off"
            style={{
              transitionDuration: "300ms",
              transform: "translate3d(-2232px, 0px, 0px)"
            }}
          >
            <div
              className="swiper-slide center-swiper-slide swiper-slide-next"
              role="group"
              aria-label="1 / 3"
              data-swiper-slide-index={0}
              style={{ width: 1116 }}
            >
              <a href="https://eshopweb.store/products/manage_offers/54">
                <img src="https://eshopweb.store/uploads/media/2022/babyfootware.jpg" />
              </a>
            </div>
            <div
              className="swiper-slide center-swiper-slide swiper-slide-prev"
              role="group"
              aria-label="2 / 3"
              data-swiper-slide-index={1}
              style={{ width: 1116 }}
            >
              <a href="https://eshopweb.store/products/manage_offers/55">
                <img src="https://eshopweb.store/uploads/media/2022/women_footware.jpg" />
              </a>
            </div>
            <div
              className="swiper-slide center-swiper-slide swiper-slide-active"
              role="group"
              aria-label="3 / 3"
              data-swiper-slide-index={2}
              style={{ width: 1116 }}
            >
              <a href="https://eshopweb.store/products/manage_offers/53">
                <img src="https://eshopweb.store/uploads/media/2022/MAN_FOOTWARE.jpg" />
              </a>
            </div>
          </div>
          {/* Add Pagination */}
          <div
            className="swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal swiper-pagination-bullets-dynamic"
            style={{ width: 80 }}
          >
            <span
              className="swiper-pagination-bullet swiper-pagination-bullet-active-prev-prev"
              style={{ left: "-16px" }}
            />
            <span
              className="swiper-pagination-bullet swiper-pagination-bullet-active-prev"
              style={{ left: "-16px" }}
            />
            <span
              className="swiper-pagination-bullet swiper-pagination-bullet-active swiper-pagination-bullet-active-main"
              aria-current="true"
              style={{ left: "-16px" }}
            />
          </div>
          <span
            className="swiper-notification"
            aria-live="assertive"
            aria-atomic="true"
          />
        </div>
      </section>
    </main>
    <main>
      <section className="my-4 bg-white">
        {/* <div class="category-section container-fluid text-center dark-category-section icon-dark-sec"> */}
        <div className="offer-section container-fluid text-center">
          {/* Swiper2 */}
          <div className="swiper mySwiper3 swiper-arrow swiper-wid swiper-initialized swiper-horizontal swiper-backface-hidden">
            <div
              className="swiper-wrapper grab"
              id="swiper-wrapper-267c4c399f632649"
              aria-live="polite"
            >
              <div
                className="swiper-slide background-none swiper-slide-active"
                role="group"
                aria-label="1 / 9"
                style={{ width: "265.5px", marginRight: 10 }}
              >
                <a href="https://eshopweb.store/products/manage_offers/62">
                  <img src="https://eshopweb.store/uploads/media/2022/men.jpg" />
                </a>
              </div>
              <div
                className="swiper-slide background-none swiper-slide-next"
                role="group"
                aria-label="2 / 9"
                style={{ width: "265.5px", marginRight: 10 }}
              >
                <a href="https://eshopweb.store/products/manage_offers/63">
                  <img src="https://eshopweb.store/uploads/media/2022/watch1.jpg" />
                </a>
              </div>
              <div
                className="swiper-slide background-none"
                role="group"
                aria-label="3 / 9"
                style={{ width: "265.5px", marginRight: 10 }}
              >
                <a href="https://eshopweb.store/products/manage_offers/64">
                  <img src="https://eshopweb.store/uploads/media/2022/men2.jpg" />
                </a>
              </div>
              <div
                className="swiper-slide background-none"
                role="group"
                aria-label="4 / 9"
                style={{ width: "265.5px", marginRight: 10 }}
              >
                <a href="https://eshopweb.store/products/manage_offers/65">
                  <img src="https://eshopweb.store/uploads/media/2022/shoes.jpg" />
                </a>
              </div>
              <div
                className="swiper-slide background-none"
                role="group"
                aria-label="5 / 9"
                style={{ width: "265.5px", marginRight: 10 }}
              >
                <a href="https://eshopweb.store/products/manage_offers/66">
                  <img src="https://eshopweb.store/uploads/media/2022/watch11.jpg" />
                </a>
              </div>
              <div
                className="swiper-slide background-none"
                role="group"
                aria-label="6 / 9"
                style={{ width: "265.5px", marginRight: 10 }}
              >
                <a href="https://eshopweb.store/products/manage_offers/67">
                  <img src="https://eshopweb.store/uploads/media/2022/men3.jpg" />
                </a>
              </div>
              <div
                className="swiper-slide background-none"
                role="group"
                aria-label="7 / 9"
                style={{ width: "265.5px", marginRight: 10 }}
              >
                <a href="https://eshopweb.store/products/manage_offers/69">
                  <img src="https://eshopweb.store/uploads/media/2022/women.jpg" />
                </a>
              </div>
              <div
                className="swiper-slide background-none"
                role="group"
                aria-label="8 / 9"
                style={{ width: "265.5px", marginRight: 10 }}
              >
                <a href="https://eshopweb.store/products/manage_offers/70">
                  <img src="https://eshopweb.store/uploads/media/2022/women1.jpg" />
                </a>
              </div>
              <div
                className="swiper-slide background-none"
                role="group"
                aria-label="9 / 9"
                style={{ width: "265.5px", marginRight: 10 }}
              >
                <a href="https://eshopweb.store/products/manage_offers/71">
                  <img src="https://eshopweb.store/uploads/media/2022/women3.jpg" />
                </a>
              </div>
            </div>
            {/* Add Pagination */}
            <div className="swiper-pagination swiper1-pagination" />
            {/* Add Pagination */}
            <div
              className="swiper-button-next"
              tabIndex={0}
              role="button"
              aria-label="Next slide"
              aria-controls="swiper-wrapper-267c4c399f632649"
              aria-disabled="false"
            >
              <ion-icon
                name="chevron-forward-outline"
                role="img"
                className="md hydrated"
              />
            </div>
            <div
              className="swiper-button-prev swiper-button-disabled"
              tabIndex={-1}
              role="button"
              aria-label="Previous slide"
              aria-controls="swiper-wrapper-267c4c399f632649"
              aria-disabled="true"
            >
              <ion-icon
                name="chevron-back-outline"
                role="img"
                className="md hydrated"
              />
            </div>
            <span
              className="swiper-notification"
              aria-live="assertive"
              aria-atomic="true"
            />
          </div>
        </div>
      </section>
    </main>
    <main>
      <section className="my-4 bg-white">
        <div className="offer-section3 container-fluid text-center">
          {/* Swiper4 */}
          <div className="swiper mySwiper3 swiper-arrow swiper-wid swiper-initialized swiper-horizontal swiper-backface-hidden">
            <div
              className="swiper-wrapper grab"
              id="swiper-wrapper-a930c37c6f6d80ae"
              aria-live="polite"
            >
              <div
                className="swiper-slide background-none swiper-slide-active"
                role="group"
                aria-label="1 / 5"
                style={{ width: "265.5px", marginRight: 10 }}
              >
                <a href="https://eshopweb.store/products/manage_offers/56">
                  <img src="https://eshopweb.store/uploads/media/2022/Ac1.jpg" />
                </a>
              </div>
              <div
                className="swiper-slide background-none swiper-slide-next"
                role="group"
                aria-label="2 / 5"
                style={{ width: "265.5px", marginRight: 10 }}
              >
                <a href="https://eshopweb.store/products/manage_offers/58">
                  <img src="https://eshopweb.store/uploads/media/2022/Smartphone.jpg" />
                </a>
              </div>
              <div
                className="swiper-slide background-none"
                role="group"
                aria-label="3 / 5"
                style={{ width: "265.5px", marginRight: 10 }}
              >
                <a href="https://eshopweb.store/products/manage_offers/59">
                  <img src="https://eshopweb.store/uploads/media/2022/furniture_sofa.jpg" />
                </a>
              </div>
              <div
                className="swiper-slide background-none"
                role="group"
                aria-label="4 / 5"
                style={{ width: "265.5px", marginRight: 10 }}
              >
                <a href="https://eshopweb.store/products/manage_offers/60">
                  <img src="https://eshopweb.store/uploads/media/2022/Chair1.jpg" />
                </a>
              </div>
              <div
                className="swiper-slide background-none"
                role="group"
                aria-label="5 / 5"
                style={{ width: "265.5px", marginRight: 10 }}
              >
                <a href="https://eshopweb.store/products/manage_offers/61">
                  <img src="https://eshopweb.store/uploads/media/2022/fridge.jpg" />
                </a>
              </div>
            </div>
            {/* Add Pagination */}
            <div className="swiper-pagination swiper1-pagination" />
            {/* Add Pagination */}
            <div
              className="swiper-button-next"
              tabIndex={0}
              role="button"
              aria-label="Next slide"
              aria-controls="swiper-wrapper-a930c37c6f6d80ae"
              aria-disabled="false"
            >
              <ion-icon
                name="chevron-forward-outline"
                role="img"
                className="md hydrated"
              />
            </div>
            <div
              className="swiper-button-prev swiper-button-disabled"
              tabIndex={-1}
              role="button"
              aria-label="Previous slide"
              aria-controls="swiper-wrapper-a930c37c6f6d80ae"
              aria-disabled="true"
            >
              <ion-icon
                name="chevron-back-outline"
                role="img"
                className="md hydrated"
              />
            </div>
            <span
              className="swiper-notification"
              aria-live="assertive"
              aria-atomic="true"
            />
            <span
              className="swiper-notification"
              aria-live="assertive"
              aria-atomic="true"
            />
            <span
              className="swiper-notification"
              aria-live="assertive"
              aria-atomic="true"
            />
          </div>
        </div>
      </section>
    </main>
    <main>
      <section className="my-4 bg-white">
        {/* Swiper1 */}
        <div className="swiper mySwiper grab border-radius-10 swiper-initialized swiper-horizontal swiper-backface-hidden">
          <div
            className="swiper-wrapper"
            id="swiper-wrapper-a102fc6b3104f74267"
            aria-live="off"
          >
            <div
              className="swiper-slide center-swiper-slide swiper-slide-active swiper-slide-next"
              role="group"
              aria-label="1 / 1"
              data-swiper-slide-index={0}
              style={{ width: 1116 }}
            >
              <a href="https://eshopweb.store/products/manage_offers/49">
                <img src="https://eshopweb.store/uploads/media/2022/Men’s_Accessories.jpg" />
              </a>
            </div>
          </div>
          {/* Add Pagination */}
          <div
            className="swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal swiper-pagination-bullets-dynamic swiper-pagination-lock"
            style={{ width: 40 }}
          >
            <span
              className="swiper-pagination-bullet swiper-pagination-bullet-active swiper-pagination-bullet-active-main"
              aria-current="true"
              style={{ left: 0 }}
            />
          </div>
          <span
            className="swiper-notification"
            aria-live="assertive"
            aria-atomic="true"
          />
        </div>
      </section>
    </main>
  </section>
  
  )
}

export default Offers