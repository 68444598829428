import React from 'react'

const Grocery = () => {
  return (
    <section className="container">
    <div className="mb-4 mb-md-5">
      <div className="align-items-center d-flex flex-wrap justify-content-between pb-3">
        <h1 className="section-title  pointer">Daily Grocery Needs</h1>
        <a href="https://eshopweb.store/products/section/122/daily-grocery-needs">
          <button className="btn viewmorebtn">View More</button>
        </a>
      </div>
      <div className="swiper mySwiper5 swiper-arrow swiper-initialized swiper-horizontal swiper-backface-hidden">
        <div
          className="swiper-wrapper grab"
          id="swiper-wrapper-2b6655ea1713838a"
          aria-live="polite"
        >
          <div
            className="swiper-slide-5 swiper-slide swiper-slide-active"
            role="group"
            aria-label="1 / 5"
            style={{ width: 643, marginRight: 10 }}
          >
            <a
              href="https://eshopweb.store/products/details/100-natural-californian-inshell-raw-walnut-kernels-500g-value-pack-1"
              className="text-reset text-decoration-none"
            ></a>
            <div
              className="card card6 mb-3 box-shadows product-card"
              data-product-id={167}
            >
              <a
                href="https://eshopweb.store/products/details/100-natural-californian-inshell-raw-walnut-kernels-500g-value-pack-1"
                className="text-reset text-decoration-none"
              ></a>
              <div className="d-flex">
                <a
                  href="https://eshopweb.store/products/details/100-natural-californian-inshell-raw-walnut-kernels-500g-value-pack-1"
                  className="text-reset text-decoration-none"
                >
                  <div className="card6-img">
                    <img
                      className="pic-1 img-fluid rounded-start lazy entered loaded"
                      src="https://eshopweb.store/uploads/media/2023/thumb-sm/walnuts-_(2)-min.jpg"
                      data-src="https://eshopweb.store/uploads/media/2023/thumb-sm/walnuts-_(2)-min.jpg"
                      data-ll-status="loaded"
                    />
                  </div>
                </a>
                <div className="card-body card-body-6">
                  <a
                    href="https://eshopweb.store/products/details/100-natural-californian-inshell-raw-walnut-kernels-500g-value-pack-1"
                    className="text-reset text-decoration-none"
                  >
                    <h5 className="card-title">100% Natural Californi... </h5>
                    <p className="card-text">Groceries </p>
                    <div className="rating-container theme-krajee-svg rating-xs rating-animate rating-disabled">
                      <div className="rating-stars" tabIndex={0}>
                        <span className="empty-stars">
                          <span className="star" title="One Star">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Two Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Three Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Four Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Five Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                        </span>
                        <span className="filled-stars" style={{ width: "60%" }}>
                          <span className="star" title="One Star">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Two Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Three Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Four Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Five Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                        </span>
                      </div>
                    </div>
                    <input
                      id="input-3-ltr-star-md"
                      name="input-3-ltr-star-md"
                      className="kv-ltr-theme-svg-star rating-input"
                      defaultValue={3.0}
                      dir="ltr"
                      data-size="xs"
                      data-show-clear="false"
                      data-show-caption="false"
                      readOnly="readonly"
                      tabIndex={-1}
                    />
                    <div className="d-flex gap-1">
                      <h4 className="card-price" itemProp="offers">
                        $200{" "}
                      </h4>
                      <p className="m-0 offer_price">
                        <small>
                          <span
                            className="text-decoration-line-through fw-bold"
                            itemProp="price"
                          >
                            $220{" "}
                          </span>
                          <span className="text-success fw-bold">
                            <span className="product-discount-label fw-bold">
                              9% off
                            </span>
                          </span>
                        </small>
                      </p>
                    </div>
                    <small className="product-disc">
                      100% Natural Raw Walnut Kernels 2 pcs 500g Value Pack |
                      Premium Akrot Giri | High in Protein &amp; Iron | Low
                      Calorie Nut | Dry Fruit
                    </small>
                  </a>
                  <a
                    href="#"
                    className="btn add-in-cart-btn add_to_cart w-100 btn-sm px-1 quickview-trigger"
                    data-product-id={167}
                    data-product-variant-id=""
                    data-product-title="100% Natural Californian Inshell Raw Walnut Kernels 500g Value Pack"
                    data-product-image="https://eshopweb.store/uploads/media/2023/walnuts-_(2)-min.jpg"
                    data-product-price={200}
                    data-min={1}
                    data-step={1}
                    data-product-description="100% Natural Raw Walnut Kernels 2 pcs 500g Value Pack | Premium Akrot Giri | High in Protein & Iron | Low Calorie Nut | Dry Fruit"
                    data-bs-toggle="modal"
                    data-bs-target="#quickview"
                  >
                    <span className="add-in-cart">Add to Cart</span>
                    <span className="add-in-cart-icon">
                      <svg
                        className="svg-inline--fa fa-cart-shopping color-white"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="cart-shopping"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="currentColor"
                          d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"
                        />
                      </svg>
                      {/* <i class="fa-solid fa-cart-shopping
                                            color-white"></i> Font Awesome fontawesome.com */}
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="swiper-slide-5 swiper-slide swiper-slide-next"
            role="group"
            aria-label="2 / 5"
            style={{ width: 643, marginRight: 10 }}
          >
            <a
              href="https://eshopweb.store/products/details/fresh-coconuts-1"
              className="text-reset text-decoration-none"
            ></a>
            <div
              className="card card6 mb-3 box-shadows product-card"
              data-product-id={164}
            >
              <a
                href="https://eshopweb.store/products/details/fresh-coconuts-1"
                className="text-reset text-decoration-none"
              ></a>
              <div className="d-flex">
                <a
                  href="https://eshopweb.store/products/details/fresh-coconuts-1"
                  className="text-reset text-decoration-none"
                >
                  <div className="card6-img">
                    <img
                      className="pic-1 img-fluid rounded-start lazy entered loaded"
                      src="https://eshopweb.store/uploads/media/2023/thumb-sm/coconut-_(1)-min.jpg"
                      data-src="https://eshopweb.store/uploads/media/2023/thumb-sm/coconut-_(1)-min.jpg"
                      data-ll-status="loaded"
                    />
                  </div>
                </a>
                <div className="card-body card-body-6">
                  <a
                    href="https://eshopweb.store/products/details/fresh-coconuts-1"
                    className="text-reset text-decoration-none"
                  >
                    <h5 className="card-title">Fresh Coconuts </h5>
                    <p className="card-text">Groceries </p>
                    <div className="rating-container theme-krajee-svg rating-xs rating-animate rating-disabled">
                      <div className="rating-stars" tabIndex={0}>
                        <span className="empty-stars">
                          <span className="star" title="One Star">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Two Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Three Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Four Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Five Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                        </span>
                        <span className="filled-stars" style={{ width: "80%" }}>
                          <span className="star" title="One Star">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Two Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Three Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Four Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Five Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                        </span>
                      </div>
                    </div>
                    <input
                      id="input-3-ltr-star-md"
                      name="input-3-ltr-star-md"
                      className="kv-ltr-theme-svg-star rating-input"
                      defaultValue={4.0}
                      dir="ltr"
                      data-size="xs"
                      data-show-clear="false"
                      data-show-caption="false"
                      readOnly="readonly"
                      tabIndex={-1}
                    />
                    <div className="d-flex gap-1">
                      <h4 className="card-price" itemProp="offers">
                        $300{" "}
                      </h4>
                      <p className="m-0 offer_price">
                        <small>
                          <span
                            className="text-decoration-line-through fw-bold"
                            itemProp="price"
                          >
                            $350{" "}
                          </span>
                          <span className="text-success fw-bold">
                            <span className="product-discount-label fw-bold">
                              14% off
                            </span>
                          </span>
                        </small>
                      </p>
                    </div>
                    <small className="product-disc">
                      Moist and milky, the coconut has a sweet, nutty flavor.
                    </small>
                  </a>
                  <a
                    href="#"
                    className="btn add-in-cart-btn add_to_cart w-100 btn-sm px-1 quickview-trigger"
                    data-product-id={164}
                    data-product-variant-id=""
                    data-product-title="Fresh Coconuts"
                    data-product-image="https://eshopweb.store/uploads/media/2023/coconut-_(1)-min.jpg"
                    data-product-price={600}
                    data-min={1}
                    data-step={1}
                    data-product-description="Moist and milky, the coconut has a sweet, nutty flavor."
                    data-bs-toggle="modal"
                    data-bs-target="#quickview"
                  >
                    <span className="add-in-cart">Add to Cart</span>
                    <span className="add-in-cart-icon">
                      <svg
                        className="svg-inline--fa fa-cart-shopping color-white"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="cart-shopping"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="currentColor"
                          d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"
                        />
                      </svg>
                      {/* <i class="fa-solid fa-cart-shopping
                                            color-white"></i> Font Awesome fontawesome.com */}
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="swiper-slide-5 swiper-slide"
            role="group"
            aria-label="3 / 5"
            style={{ width: 643, marginRight: 10 }}
          >
            <a
              href="https://eshopweb.store/products/details/kissan-tomato-ketchup"
              className="text-reset text-decoration-none"
            ></a>
            <div
              className="card card6 mb-3 box-shadows product-card"
              data-product-id={162}
            >
              <a
                href="https://eshopweb.store/products/details/kissan-tomato-ketchup"
                className="text-reset text-decoration-none"
              ></a>
              <div className="d-flex">
                <a
                  href="https://eshopweb.store/products/details/kissan-tomato-ketchup"
                  className="text-reset text-decoration-none"
                >
                  <div className="card6-img">
                    <img
                      className="pic-1 img-fluid rounded-start lazy"
                      src="https://eshopweb.store/assets/front_end/modern/image/pictures/placeholder-image.png"
                      data-src="https://eshopweb.store/uploads/media/2020/thumb-sm/KISSANBTL-500x500-min.jpg"
                    />
                  </div>
                </a>
                <div className="card-body card-body-6">
                  <a
                    href="https://eshopweb.store/products/details/kissan-tomato-ketchup"
                    className="text-reset text-decoration-none"
                  >
                    <h5 className="card-title">Kissan Tomato Ketchup </h5>
                    <p className="card-text">Snacks &amp;Ketchup </p>
                    <div className="rating-container theme-krajee-svg rating-xs rating-animate rating-disabled">
                      <div className="rating-stars" tabIndex={0}>
                        <span className="empty-stars">
                          <span className="star" title="One Star">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Two Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Three Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Four Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Five Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                        </span>
                        <span className="filled-stars" style={{ width: "70%" }}>
                          <span className="star" title="One Star">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Two Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Three Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Four Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Five Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                        </span>
                      </div>
                    </div>
                    <input
                      id="input-3-ltr-star-md"
                      name="input-3-ltr-star-md"
                      className="kv-ltr-theme-svg-star rating-input"
                      defaultValue="3.50"
                      dir="ltr"
                      data-size="xs"
                      data-show-clear="false"
                      data-show-caption="false"
                      readOnly="readonly"
                      tabIndex={-1}
                    />
                    <div className="d-flex gap-1">
                      <h4 className="card-price" itemProp="offers">
                        $250{" "}
                      </h4>
                      <p className="m-0 offer_price">
                        <small>
                          <span
                            className="text-decoration-line-through fw-bold"
                            itemProp="price"
                          >
                            $300{" "}
                          </span>
                          <span className="text-success fw-bold">
                            <span className="product-discount-label fw-bold">
                              16% off
                            </span>
                          </span>
                        </small>
                      </p>
                    </div>
                    <small className="product-disc">
                      Fresh Tomato 100% Fresh.
                    </small>
                  </a>
                  <a
                    href="#"
                    className="btn add-in-cart-btn add_to_cart w-100 btn-sm px-1 quickview-trigger"
                    data-product-id={162}
                    data-product-variant-id=""
                    data-product-title="Kissan Tomato Ketchup"
                    data-product-image="https://eshopweb.store/uploads/media/2020/KISSANBTL-500x500-min.jpg"
                    data-product-price={550}
                    data-min={1}
                    data-step={1}
                    data-product-description="Fresh Tomato 100% Fresh."
                    data-bs-toggle="modal"
                    data-bs-target="#quickview"
                  >
                    <span className="add-in-cart">Add to Cart</span>
                    <span className="add-in-cart-icon">
                      <svg
                        className="svg-inline--fa fa-cart-shopping color-white"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="cart-shopping"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="currentColor"
                          d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"
                        />
                      </svg>
                      {/* <i class="fa-solid fa-cart-shopping
                                            color-white"></i> Font Awesome fontawesome.com */}
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="swiper-slide-5 swiper-slide"
            role="group"
            aria-label="4 / 5"
            style={{ width: 643, marginRight: 10 }}
          >
            <a
              href="https://eshopweb.store/products/details/unpolished-urad-dal-1"
              className="text-reset text-decoration-none"
            ></a>
            <div
              className="card card6 mb-3 box-shadows product-card"
              data-product-id={160}
            >
              <a
                href="https://eshopweb.store/products/details/unpolished-urad-dal-1"
                className="text-reset text-decoration-none"
              ></a>
              <div className="d-flex">
                <a
                  href="https://eshopweb.store/products/details/unpolished-urad-dal-1"
                  className="text-reset text-decoration-none"
                >
                  <div className="card6-img">
                    <img
                      className="pic-1 img-fluid rounded-start lazy"
                      src="https://eshopweb.store/assets/front_end/modern/image/pictures/placeholder-image.png"
                      data-src="https://eshopweb.store/uploads/media/2020/thumb-sm/a87.jpg"
                    />
                  </div>
                </a>
                <div className="card-body card-body-6">
                  <a
                    href="https://eshopweb.store/products/details/unpolished-urad-dal-1"
                    className="text-reset text-decoration-none"
                  >
                    <h5 className="card-title">Unpolished Urad Dal </h5>
                    <p className="card-text">Dal,Pulses &amp; Flour </p>
                    <div className="rating-container theme-krajee-svg rating-xs rating-animate rating-disabled">
                      <div className="rating-stars" tabIndex={0}>
                        <span className="empty-stars">
                          <span className="star" title="One Star">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Two Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Three Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Four Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Five Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                        </span>
                        <span className="filled-stars" style={{ width: "70%" }}>
                          <span className="star" title="One Star">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Two Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Three Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Four Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Five Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                        </span>
                      </div>
                    </div>
                    <input
                      id="input-3-ltr-star-md"
                      name="input-3-ltr-star-md"
                      className="kv-ltr-theme-svg-star rating-input"
                      defaultValue="3.50"
                      dir="ltr"
                      data-size="xs"
                      data-show-clear="false"
                      data-show-caption="false"
                      readOnly="readonly"
                      tabIndex={-1}
                    />
                    <div className="d-flex gap-1">
                      <h4 className="card-price" itemProp="offers">
                        $80{" "}
                      </h4>
                      <p className="m-0 offer_price">
                        <small>
                          <span
                            className="text-decoration-line-through fw-bold"
                            itemProp="price"
                          >
                            $100{" "}
                          </span>
                          <span className="text-success fw-bold">
                            <span className="product-discount-label fw-bold">
                              20% off
                            </span>
                          </span>
                        </small>
                      </p>
                    </div>
                    <small className="product-disc">Tata Sampann</small>
                  </a>
                  <a
                    href="#"
                    className="btn add-in-cart-btn add_to_cart w-100 btn-sm px-1 quickview-trigger"
                    data-product-id={160}
                    data-product-variant-id=""
                    data-product-title="Unpolished Urad Dal"
                    data-product-image="https://eshopweb.store/uploads/media/2020/a87.jpg"
                    data-product-price={178}
                    data-min={1}
                    data-step={1}
                    data-product-description="Tata Sampann"
                    data-bs-toggle="modal"
                    data-bs-target="#quickview"
                  >
                    <span className="add-in-cart">Add to Cart</span>
                    <span className="add-in-cart-icon">
                      <svg
                        className="svg-inline--fa fa-cart-shopping color-white"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="cart-shopping"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="currentColor"
                          d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"
                        />
                      </svg>
                      {/* <i class="fa-solid fa-cart-shopping
                                            color-white"></i> Font Awesome fontawesome.com */}
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="swiper-slide-5 swiper-slide"
            role="group"
            aria-label="5 / 5"
            style={{ width: 643, marginRight: 10 }}
          >
            <a
              href="https://eshopweb.store/products/details/toor-dal"
              className="text-reset text-decoration-none"
            ></a>
            <div
              className="card card6 mb-3 box-shadows product-card"
              data-product-id={156}
            >
              <a
                href="https://eshopweb.store/products/details/toor-dal"
                className="text-reset text-decoration-none"
              ></a>
              <div className="d-flex">
                <a
                  href="https://eshopweb.store/products/details/toor-dal"
                  className="text-reset text-decoration-none"
                >
                  <div className="card6-img">
                    <img
                      className="pic-1 img-fluid rounded-start lazy"
                      src="https://eshopweb.store/assets/front_end/modern/image/pictures/placeholder-image.png"
                      data-src="https://eshopweb.store/uploads/media/2020/thumb-sm/a18.jpg"
                    />
                  </div>
                </a>
                <div className="card-body card-body-6">
                  <a
                    href="https://eshopweb.store/products/details/toor-dal"
                    className="text-reset text-decoration-none"
                  >
                    <h5 className="card-title">Toor Dal </h5>
                    <p className="card-text">Dal,Pulses &amp; Flour </p>
                    <div className="rating-container theme-krajee-svg rating-xs rating-animate rating-disabled">
                      <div className="rating-stars" tabIndex={0}>
                        <span className="empty-stars">
                          <span className="star" title="One Star">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Two Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Three Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Four Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Five Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                        </span>
                        <span className="filled-stars" style={{ width: "80%" }}>
                          <span className="star" title="One Star">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Two Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Three Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Four Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Five Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                        </span>
                      </div>
                    </div>
                    <input
                      id="input-3-ltr-star-md"
                      name="input-3-ltr-star-md"
                      className="kv-ltr-theme-svg-star rating-input"
                      defaultValue={4.0}
                      dir="ltr"
                      data-size="xs"
                      data-show-clear="false"
                      data-show-caption="false"
                      readOnly="readonly"
                      tabIndex={-1}
                    />
                    <div className="d-flex gap-1">
                      <h4 className="card-price" itemProp="offers">
                        $89{" "}
                      </h4>
                      <p className="m-0 offer_price">
                        <small>
                          <span
                            className="text-decoration-line-through fw-bold"
                            itemProp="price"
                          >
                            $110{" "}
                          </span>
                          <span className="text-success fw-bold">
                            <span className="product-discount-label fw-bold">
                              18% off
                            </span>
                          </span>
                        </small>
                      </p>
                    </div>
                    <small className="product-disc">Laxmi Polished</small>
                  </a>
                  <a
                    href="#"
                    className="btn add-in-cart-btn add_to_cart w-100 btn-sm px-1 quickview-trigger"
                    data-product-id={156}
                    data-product-variant-id=""
                    data-product-title="Toor Dal"
                    data-product-image="https://eshopweb.store/uploads/media/2020/a18.jpg"
                    data-product-price={189}
                    data-min={6}
                    data-step={5}
                    data-product-description="Laxmi Polished"
                    data-bs-toggle="modal"
                    data-bs-target="#quickview"
                  >
                    <span className="add-in-cart">Add to Cart</span>
                    <span className="add-in-cart-icon">
                      <svg
                        className="svg-inline--fa fa-cart-shopping color-white"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="cart-shopping"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="currentColor"
                          d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"
                        />
                      </svg>
                      {/* <i class="fa-solid fa-cart-shopping
                                            color-white"></i> Font Awesome fontawesome.com */}
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="swiper-button-next"
          tabIndex={0}
          role="button"
          aria-label="Next slide"
          aria-controls="swiper-wrapper-2b6655ea1713838a"
          aria-disabled="false"
        >
          <ion-icon
            name="chevron-forward-outline"
            role="img"
            className="md flip-rtl hydrated"
          />
        </div>
        <div
          className="swiper-button-prev swiper-button-disabled"
          tabIndex={-1}
          role="button"
          aria-label="Previous slide"
          aria-controls="swiper-wrapper-2b6655ea1713838a"
          aria-disabled="true"
        >
          <ion-icon
            name="chevron-back-outline"
            role="img"
            className="md flip-rtl hydrated"
          />
        </div>
        <span
          className="swiper-notification"
          aria-live="assertive"
          aria-atomic="true"
        />
      </div>
    </div>
  </section>
  
  )
}

export default Grocery