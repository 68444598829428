import React from "react";
import "./SearchBar.css"

const SearchBar = () => {
  return (
    <div className="bg-white top-nav">
    <div className="container d-none d-xl-block d-xxl-block">
      <nav className="navbar navbar-expand-xl navbar-light bg-white ">
        <a className="company-logo" href="https://eshopweb.store/">
          <img
            src="/Images/Logo.jpg"
            className="me-3v pointer brand-logo-link"
            alt="Logo"
          />
        </a>
        <form className="searchcontainer mx-5 searchbar">
          {/* <input class="form-control me-2 searchbar" type="text" placeholder="Search for Product" aria-label="Search" required>
            <button class="searchicon" type="submit">
                <i class="ionicon-search-outline"></i>
            </button> */}
          <select
            className="me-2 search_product opacity-0 select2-hidden-accessible"
            type="text"
            aria-label="Search"
            data-select2-id={1}
            tabIndex={-1}
            aria-hidden="true"
          >
            search
          </select>
          <span
            className="select2 select2-container select2-container--adwitt"
            dir="ltr"
            data-select2-id={2}
            style={{ width: "21.6px" }}
          >
            <span className="selection">
              <span
                className="select2-selection select2-selection--single search-container"
                role="combobox"
                aria-haspopup="true"
                aria-expanded="false"
                tabIndex={0}
                aria-disabled="false"
                aria-labelledby="select2-p215-container"
              >
                <span
                  className="select2-selection__rendered"
                  id="select2-p215-container"
                  role="textbox"
                  aria-readonly="true"
                >
                  <span className="select2-selection__placeholder">
                    Search for products
                  </span>
                </span>
                <span className="select2-selection__arrow" role="presentation">
                  <b role="presentation" />
                </span>
              </span>
            </span>
            <span className="dropdown-wrapper" aria-hidden="true" />
          </span>
        </form>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div
          className="collapse navbar-collapse fw-semibold "
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav me-auto mb-2 mb-lg-0 justify-content-around width100 white-space-nowrap">
            <li className="nav-item">
              <a
                className="nav-link font-color"
                title="Support"
                aria-current="page"
                href="https://eshopweb.store/home/contact-us"
              >
                Support
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link font-color"
                title="About Us"
                href="https://eshopweb.store/home/about-us"
              >
                About Us
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link font-color"
                title="FAQs"
                href="https://eshopweb.store/home/faq"
              >
                FAQs
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
  
  );
};

export default SearchBar;
