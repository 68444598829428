import React from "react";
import { TiArrowShuffle } from "react-icons/ti";
import { CgShoppingBag } from "react-icons/cg";
import { FaRegHeart } from "react-icons/fa";
import { BsPerson } from "react-icons/bs";
import "./Header.css";
import { NavLink } from "react-router-dom";

const Header = () => {
  return (
    <header className="header">
      <div className="container-option">
        <ul className="nav">
          <li className="nav-item nav-list">
            <NavLink className="nav-link" to="/">
              Home
            </NavLink>
          </li>
          <li className="nav-item nav-list">
            <NavLink className="nav-link" to="/categories">
              Category
            </NavLink>
          </li>
          <li className="nav-item nav-list">
            <NavLink className="nav-link" to="/products">
              Products
            </NavLink>
          </li>
          <li className="nav-item nav-list">
            <NavLink className="nav-link" to="/offers_and_flash_sale">
              Top offers
            </NavLink>
          </li>
          <li className="nav-item nav-list">
            <NavLink className="nav-link" to="/contact_us">
              Contact Us
            </NavLink>
          </li>
          <li className="nav-item nav-list">
            <NavLink className="nav-link" to="/about_us">
              About Us
            </NavLink>
          </li>
        </ul>
      </div>
      <div className="container-option-icons">
        <div className="language-selection">
          <div className="btn-group">
            <button
              type="button"
              className="btn dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              EN
            </button>
            <ul className="dropdown-menu">
              <li>
                <NavLink className="dropdown-item" to="#">
                  EN-ENGLISH
                </NavLink>
              </li>
              <li>
                <NavLink className="dropdown-item" to="#">
                  HI-HINDI
                </NavLink>
              </li>
              <li>
                <NavLink className="dropdown-item" to="#">
                  TI-TAMIL
                </NavLink>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <NavLink className="dropdown-item" to="#">
                    UR-URIA
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="option-icons">
          <ul className="nav">
            <li className="nav-item nav-list-icon">
              <NavLink className="nav-link" to="/random">
                <TiArrowShuffle size={"20px"} />
              </NavLink>
            </li>
            <li className="nav-item nav-list-icon">
              <NavLink className="nav-link" to="/shopping">
                <CgShoppingBag size={"20px"} />
              </NavLink>
            </li>
            <li className="nav-item nav-list-icon">
              <NavLink className="nav-link" to="/favorite">
                <FaRegHeart size={"20px"} />
              </NavLink>
            </li>
            <li className="nav-item nav-list-icon">
              <NavLink className="nav-link" to="/account">
                <BsPerson size={"20px"} />
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
