import React from "react";
// import "./SliderShow.css";

const SliderShow = () => {
  const sliderList = [
    {
      name: "model1",
      src: "/Images/Slideshow/slide1.png",
      alt: "Model 1 Slide Image",
      line: "Be bright and young thoughts",
      headline: "Hola, bienvenido a",
    },
    {
      name: "model2",
      src: "/Images/Slideshow/slide2.png",
      alt: "Model 2 Slide Image",
      line: "Live like a wide lion in the Jungle",
      headline: "Bonjour, bienvenue sur",
    },
    {
      name: "model3",
      src: "/Images/Slideshow/slide3.png",
      alt: "Model 3 Slide Image",
      line: "Passion that keeps you young and bright",
      headline: "Hello, Welcome to",
    },
    {
      name: "model4",
      src: "/Images/Slideshow/slide4.png",
      alt: "Model 4 Slide Image",
      line: "Passion that keeps you young and bright",
      headline: "Hello, Welcome to",
    },
    {
      name: "model5",
      src: "/Images/Slideshow/slide5.png",
      alt: "Model 5 Slide Image",
      line: "Passion that keeps you young and bright",
      headline: "Hello, Welcome to",
    },
  ];

  return (
    <section className="container mt-4">
      {/* section 1 */}
      <div className="mb-4 mb-md-5">
        <div className="swiper mySwiper grab border-radius-10 swiper-initialized swiper-horizontal swiper-backface-hidden">
          <div className="swiper-wrapper" aria-live="off">
            {sliderList.map((slide, index) => (
              <div
                className="swiper-slide center-swiper-slide"
                key={index}
                role="group"
                aria-label={`${index + 1} / ${sliderList.length}`}
                data-swiper-slide-index={index}
              >
                <a href="#">
                  <img src={slide.src} alt={slide.alt} />
                </a>
                <div className="slide-caption">
                  <h3>{slide.headline}</h3>
                  <p>{slide.line}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal"></div>
          <span
            className="swiper-notification"
            aria-live="assertive"
            aria-atomic="true"
          />
        </div>
      </div>
    </section>
  );
};

export default SliderShow;
