import React from 'react'

const BestSofa = () => {
  return (
    <section className="container mt-3">
    <div className="mb-4 mb-md-5">
      <div className="align-items-center d-flex flex-wrap justify-content-between pb-3">
        <h1 className="section-title  pointer">Best living sofa</h1>
        <a href="https://eshopweb.store/home/brands/">
          <button className="btn viewmorebtn">View More</button>
        </a>
      </div>
      <div className="swiper swiper-arrow mySwiper4 swiper-initialized swiper-horizontal swiper-backface-hidden">
        <div
          className="swiper-wrapper my-5  grab"
          id="swiper-wrapper-107f915cbaf1fad97"
          aria-live="polite"
        >
          <div
            className="swiper-slide box-shadow swiper-slide-active"
            role="group"
            aria-label="1 / 2"
            style={{ width: 309, marginRight: 20 }}
          >
            <a
              href="https://eshopweb.store/products/details/purple-sleeper-sofa-bad-with-storage-chaise"
              className="text-reset text-decoration-none"
            >
              <div className="card5">
                <img
                  className="pic-1 lazy card-img-top pointer entered loaded"
                  src="https://eshopweb.store/uploads/media/2024/thumb-sm/soft-purple-sofa-purple-background.jpg"
                  data-src="https://eshopweb.store/uploads/media/2024/thumb-sm/soft-purple-sofa-purple-background.jpg"
                  data-ll-status="loaded"
                />
                <div className="card-body">
                  <h5 className="card-title">purple sleeper sofa ba... </h5>
                  <div className="rating-container theme-krajee-svg rating-xs rating-animate rating-disabled">
                    <div className="rating-stars" tabIndex={0}>
                      <span className="empty-stars">
                        <span className="star" title="One Star">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Two Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Three Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Four Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Five Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                      </span>
                      <span className="filled-stars" style={{ width: "0%" }}>
                        <span className="star" title="One Star">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Two Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Three Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Four Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Five Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                      </span>
                    </div>
                  </div>
                  <input
                    id="input-3-ltr-star-md"
                    name="input-3-ltr-star-md"
                    className="kv-ltr-theme-svg-star rating-input"
                    defaultValue={0.0}
                    dir="ltr"
                    data-size="xs"
                    data-show-clear="false"
                    data-show-caption="false"
                    readOnly="readonly"
                    tabIndex={-1}
                  />
                  {/* <h5 class="card-price">
                                                                                                                <p class="mb-0 mt-2 price text-muted">
                                                            <span id="price" style='font-size: 20px;'>
                                                                $                                                                    459.00                                                                </span>
                                                        </p>
                                                                                                        </h5> */}
                  <div className="d-flex gap-1">
                    <h4 className="card-price" itemProp="offers">
                      <span itemProp="price"> $459</span>
                    </h4>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div
            className="swiper-slide box-shadow swiper-slide-next"
            role="group"
            aria-label="2 / 2"
            style={{ width: 309, marginRight: 20 }}
          >
            <a
              href="https://eshopweb.store/products/details/nature-look-soft-sofa"
              className="text-reset text-decoration-none"
            >
              <div className="card5">
                <img
                  className="pic-1 lazy card-img-top pointer entered loaded"
                  src="https://eshopweb.store/uploads/media/2024/thumb-sm/631884.jpg"
                  data-src="https://eshopweb.store/uploads/media/2024/thumb-sm/631884.jpg"
                  data-ll-status="loaded"
                />
                <div className="card-body">
                  <h5 className="card-title">nature look soft sofa </h5>
                  <div className="rating-container theme-krajee-svg rating-xs rating-animate rating-disabled">
                    <div className="rating-stars" tabIndex={0}>
                      <span className="empty-stars">
                        <span className="star" title="One Star">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Two Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Three Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Four Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Five Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                      </span>
                      <span className="filled-stars" style={{ width: "0%" }}>
                        <span className="star" title="One Star">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Two Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Three Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Four Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Five Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                      </span>
                    </div>
                  </div>
                  <input
                    id="input-3-ltr-star-md"
                    name="input-3-ltr-star-md"
                    className="kv-ltr-theme-svg-star rating-input"
                    defaultValue={0.0}
                    dir="ltr"
                    data-size="xs"
                    data-show-clear="false"
                    data-show-caption="false"
                    readOnly="readonly"
                    tabIndex={-1}
                  />
                  {/* <h5 class="card-price">
                                                                                                                <p class="mb-0 mt-2 price text-muted">
                                                            <span id="price" style='font-size: 20px;'>
                                                                $                                                                    499.00                                                                </span>
                                                        </p>
                                                                                                        </h5> */}
                  <div className="d-flex gap-1">
                    <h4 className="card-price" itemProp="offers">
                      <span itemProp="price"> $499</span>
                    </h4>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div
          className="swiper-button-next swiper-button-disabled swiper-button-lock"
          tabIndex={-1}
          role="button"
          aria-label="Next slide"
          aria-controls="swiper-wrapper-107f915cbaf1fad97"
          aria-disabled="true"
        >
          <ion-icon
            name="chevron-forward-outline"
            role="img"
            className="md flip-rtl hydrated"
          />
        </div>
        <div
          className="swiper-button-prev swiper-button-disabled swiper-button-lock"
          tabIndex={-1}
          role="button"
          aria-label="Previous slide"
          aria-controls="swiper-wrapper-107f915cbaf1fad97"
          aria-disabled="true"
        >
          <ion-icon
            name="chevron-back-outline"
            role="img"
            className="md flip-rtl hydrated"
          />
        </div>
        <span
          className="swiper-notification"
          aria-live="assertive"
          aria-atomic="true"
        />
      </div>
    </div>
  </section>
  
  )
}

export default BestSofa