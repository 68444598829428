import "./App.css";
import "swiper/css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Final from "./final/Final";
function App() {
  return (
     <Final/>
  );
}

export default App;
