import React from 'react'

const Brand = () => {
  return (
    <section class="container mt-3">
            <div class="mb-4 mb-md-5">
                <div class="align-items-center d-flex flex-wrap justify-content-between pb-3">
                    <h1 class="section-title  pointer">Brands</h1>

                    <a href="https://eshopweb.store/home/brands/">
                        <button class="btn viewmorebtn">See All</button>
                    </a>
                </div>
                <div class="swiperForBrands swiper mySwiper2 swiper-initialized swiper-horizontal swiper-backface-hidden">
                    <div class="swiper-wrapper grab text-center" id="swiper-wrapper-fc2efe9d6ff752210" aria-live="polite">
                        
                            <div class="swiper-slide overflow-hidden swiper-slide-active" role="group" aria-label="1 / 10" style={{width:"153.25px",marginRight:"10px;"}}>
                                <div class="col p-0 fit-content">
                                    <div class="categories-card">
                                        <a href="https://eshopweb.store/products?brand=apple" class="text-reset text-decoration-none">
                                            <div class="brands-image">
                                                <img src="uploads/media/2023/Apple_logo_black_(1).png" alt="Apple"/>
                                            </div>
                                            <div class="categories-card-text">
                                                <h4>Apple</h4>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        
                            <div class="swiper-slide overflow-hidden swiper-slide-next" role="group" aria-label="2 / 10" style={{width:"153.25px",marginRight:"10px;"}}>
                                <div class="col p-0 fit-content">
                                    <div class="categories-card">
                                        <a href="https://eshopweb.store/products?brand=acer" class="text-reset text-decoration-none">
                                            <div class="brands-image">
                                                <img src="uploads/media/2024/Acer-Logo.png" alt="Acer"/>
                                            </div>
                                            <div class="categories-card-text">
                                                <h4>Acer</h4>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        
                            <div class="swiper-slide overflow-hidden" role="group" aria-label="3 / 10" style={{width:"153.25px",marginRight:"10px;"}}>
                                <div class="col p-0 fit-content">
                                    <div class="categories-card">
                                        <a href="https://eshopweb.store/products?brand=adobe" class="text-reset text-decoration-none">
                                            <div class="brands-image">
                                                <img src="uploads/media/2024/adobe.jpeg" alt="Adobe"/>
                                            </div>
                                            <div class="categories-card-text">
                                                <h4>Adobe</h4>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        
                            <div class="swiper-slide overflow-hidden" role="group" aria-label="4 / 10" style={{width:"153.25px",marginRight:"10px;"}}>
                                <div class="col p-0 fit-content">
                                    <div class="categories-card">
                                        <a href="https://eshopweb.store/products?brand=away-1" class="text-reset text-decoration-none">
                                            <div class="brands-image">
                                                <img src="uploads/chat_media//Screenshot_2024-07-21-17-08-44-68_8725ce44afe22ce17e3660df7f33c32e.jpg" alt="away"/>
                                            </div>
                                            <div class="categories-card-text">
                                                <h4>away</h4>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        
                            <div class="swiper-slide overflow-hidden" role="group" aria-label="5 / 10" style={{width:"153.25px",marginRight:"10px;"}}>
                                <div class="col p-0 fit-content">
                                    <div class="categories-card">
                                        <a href="https://eshopweb.store/products?brand=puma-1" class="text-reset text-decoration-none">
                                            <div class="brands-image">
                                                <img src="uploads/media/2024/Puma-Logo.png" alt="Puma"/>
                                            </div>
                                            <div class="categories-card-text">
                                                <h4>Puma</h4>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        
                            <div class="swiper-slide overflow-hidden" role="group" aria-label="6 / 10" style={{width:"153.25px",marginRight:"10px;"}}>
                                <div class="col p-0 fit-content">
                                    <div class="categories-card">
                                        <a href="https://eshopweb.store/products?brand=samsung-1" class="text-reset text-decoration-none">
                                            <div class="brands-image">
                                                <img src="uploads/media/2023/Samsung-Logo_wine.png" alt="Samsung"/>
                                            </div>
                                            <div class="categories-card-text">
                                                <h4>Samsung</h4>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        
                            <div class="swiper-slide overflow-hidden" role="group" aria-label="7 / 10" style={{width:"153.25px",marginRight:"10px;"}}>
                                <div class="col p-0 fit-content">
                                    <div class="categories-card">
                                        <a href="https://eshopweb.store/products?brand=one-plus" class="text-reset text-decoration-none">
                                            <div class="brands-image">
                                                <img src="uploads/media/2023/OnePlus_Logo.png" alt="One plus"/>
                                            </div>
                                            <div class="categories-card-text">
                                                <h4>One plus</h4>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        
                            <div class="swiper-slide overflow-hidden" role="group" aria-label="8 / 10" style={{width:"153.25px",marginRight:"10px;"}}>
                                <div class="col p-0 fit-content">
                                    <div class="categories-card">
                                        <a href="https://eshopweb.store/products?brand=lenovo" class="text-reset text-decoration-none">
                                            <div class="brands-image">
                                                <img src="uploads/media/2024/Lenovo-Logo-1.png" alt="Lenovo"/>
                                            </div>
                                            <div class="categories-card-text">
                                                <h4>Lenovo</h4>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        
                            <div class="swiper-slide overflow-hidden" role="group" aria-label="9 / 10" style={{width:"153.25px",marginRight:"10px"}}>
                                <div class="col p-0 fit-content">
                                    <div class="categories-card">
                                        <a href="https://eshopweb.store/products?brand=hp" class="text-reset text-decoration-none">
                                            <div class="brands-image">
                                                <img src="uploads/media/2024/1024px-HP_logo_2012_svg.png" alt="HP"/>
                                            </div>
                                            <div class="categories-card-text">
                                                <h4>HP</h4>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        
                            <div class="swiper-slide overflow-hidden" role="group" aria-label="10 / 10" style={{width:"153.25px",marginRight:"10px;"}}>
                                <div class="col p-0 fit-content">
                                    <div class="categories-card">
                                        <a href="https://eshopweb.store/products?brand=godrej" class="text-reset text-decoration-none">
                                            <div class="brands-image">
                                                <img src="uploads/media/2024/Godrej_Logo_svg.png" alt="Godrej"/>
                                            </div>
                                            <div class="categories-card-text">
                                                <h4>Godrej</h4>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                                            </div>
                    <div class="swiper-button-next" tabindex="0" role="button" aria-label="Next slide" aria-controls="swiper-wrapper-fc2efe9d6ff752210" aria-disabled="false">
                        <ion-icon name="chevron-forward-outline" role="img" class="md flip-rtl hydrated"></ion-icon>
                    </div>
                    <div class="swiper-button-prev swiper-button-disabled" tabindex="-1" role="button" aria-label="Previous slide" aria-controls="swiper-wrapper-fc2efe9d6ff752210" aria-disabled="true">
                        <ion-icon name="chevron-back-outline" role="img" class="md flip-rtl hydrated"></ion-icon>
                    </div>
                <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>
            </div>
        </section>
  )
}

export default Brand