import React from "react";

const ContactUs = () => {
  return (
    <section id="content" className="container py-4">
      <h2 className="section-title text-center pb-3">Contact Us </h2>
      <div className="main-content">
        <div className="row ">
          <div className="col-lg-7">
            <div className="sign-up-image">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d58652.60185263579!2d69.63381478835316!3d23.250814410717105!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3950e209000b6f17:0x7077f358af0774a6!2sBhuj, Gujarat!5e0!3m2!1sen!2sin!4v1614852897708!5m2!1sen!2sin"
                width={600}
                height={450}
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
              />{" "}
            </div>
          </div>
          <div className="col-lg-5 login-form mt-md-0 mt-3">
            <form
              id="contact-us-form"
              action="https://eshopweb.store/home/send-contact-us-email"
              method="POST"
            >
              <div className="form-row">
                <div className="form-group mb-2">
                  <label htmlFor="inputEmail4">User Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputEmail4"
                    name="username"
                    placeholder="User Name"
                  />
                </div>
                <div className="form-group mb-2">
                  <label htmlFor="inputPassword4">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="inputPassword4"
                    name="email"
                    placeholder="Email"
                  />
                </div>
              </div>
              <div className="form-group mb-2">
                <label htmlFor="inputAddress">Subject</label>
                <input
                  type="text"
                  className="form-control"
                  id="inputAddress"
                  name="subject"
                  placeholder="Subject"
                />
              </div>
              <div className="form-group mb-2">
                <label htmlFor="inputAddress">Message</label>
                <textarea
                  className="form-control"
                  name="message"
                  rows={4}
                  cols={58}
                  defaultValue={""}
                />
              </div>
              <button
                id="contact-us-submit-btn"
                className="btn btn-primary mt-2"
              >
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
