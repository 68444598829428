import React from 'react'

const BigDeals = () => {
  return (
    <section className="container">
    {/* Style 2 Design*/}
    {/* section 5 */}
    <div className="mb-4 mb-md-5">
      <div className="row">
        <div className="col-4">
          <div className="border-radius-10 overflow-hidden product-banner-container">
            <a href="https://eshopweb.store/products/section/126/biggest-deals-on-top-brands">
              <img
                className="pic-1 product-banner lazy entered loaded"
                src="https://eshopweb.store/uploads/media/2023/thumb-sm/virat-kohli-the-superstar-fashion-icon-3.jpg"
                data-src="https://eshopweb.store/uploads/media/2023/thumb-sm/virat-kohli-the-superstar-fashion-icon-3.jpg"
                data-ll-status="loaded"
              />
            </a>
          </div>
        </div>
        <div className="col-xl-8 col-lg-12">
          <div className="align-items-center d-flex flex-wrap justify-content-between pb-3">
            <h1 className="section-title mb-0">BIGGEST DEALS ON TOP BRANDS</h1>
            <a href="https://eshopweb.store/products/section/126/biggest-deals-on-top-brands">
              <button className="btn viewmorebtn">View More</button>
            </a>
          </div>
          <div className="row g-2">
            <div className="col-md-4 col-6">
              <a
                href="https://eshopweb.store/products/details/black-full-sleeves-slim-fit-t-shirt"
                className="text-reset text-decoration-none"
              ></a>
              <div
                className="card-temp card p-0 mb-3 mb-md-0 pointer product-card"
                data-product-id={187}
              >
                <a
                  href="https://eshopweb.store/products/details/black-full-sleeves-slim-fit-t-shirt"
                  className="text-reset text-decoration-none"
                >
                  <div className="product-icon-spacebtw">
                    <div
                      className="add-to-fav-btn"
                      title="like"
                      data-product-id={187}
                    >
                      <ion-icon
                        className="ion-icon ion-icon-hover heart-outline text-dark md hydrated"
                        name="heart-outline"
                        role="img"
                      ></ion-icon>
                    </div>
                  </div>
                  <div className="product-image-1">
                    <img
                      className="pic-1 lazy card-img-top entered loaded"
                      src="https://eshopweb.store/uploads/media/2023/thumb-sm/1_mfk-9146-p-01-black_1.jpg"
                      data-src="https://eshopweb.store/uploads/media/2023/thumb-sm/1_mfk-9146-p-01-black_1.jpg"
                      data-ll-status="loaded"
                    />
                  </div>
                </a>
                <div className="card-body">
                  <a
                    href="https://eshopweb.store/products/details/black-full-sleeves-slim-fit-t-shirt"
                    className="text-reset text-decoration-none"
                  >
                    <h4 className="card-title">Black Full Sleeves Sli... </h4>
                    <p className="card-text">Cloth </p>
                    <div className="d-flex flex-column">
                      <div className="rating-container theme-krajee-svg rating-xs rating-animate rating-disabled">
                        <div className="rating-stars" tabIndex={0}>
                          <span className="empty-stars">
                            <span className="star" title="One Star">
                              <span className="krajee-icon krajee-icon-star" />
                            </span>
                            <span className="star" title="Two Stars">
                              <span className="krajee-icon krajee-icon-star" />
                            </span>
                            <span className="star" title="Three Stars">
                              <span className="krajee-icon krajee-icon-star" />
                            </span>
                            <span className="star" title="Four Stars">
                              <span className="krajee-icon krajee-icon-star" />
                            </span>
                            <span className="star" title="Five Stars">
                              <span className="krajee-icon krajee-icon-star" />
                            </span>
                          </span>
                          <span
                            className="filled-stars"
                            style={{ width: "100%" }}
                          >
                            <span className="star" title="One Star">
                              <span className="krajee-icon krajee-icon-star" />
                            </span>
                            <span className="star" title="Two Stars">
                              <span className="krajee-icon krajee-icon-star" />
                            </span>
                            <span className="star" title="Three Stars">
                              <span className="krajee-icon krajee-icon-star" />
                            </span>
                            <span className="star" title="Four Stars">
                              <span className="krajee-icon krajee-icon-star" />
                            </span>
                            <span className="star" title="Five Stars">
                              <span className="krajee-icon krajee-icon-star" />
                            </span>
                          </span>
                        </div>
                      </div>
                      <input
                        id="input-3-ltr-star-md"
                        name="input-3-ltr-star-md"
                        className="kv-ltr-theme-svg-star rating-input"
                        defaultValue={5.0}
                        dir="ltr"
                        data-size="xs"
                        data-show-clear="false"
                        data-show-caption="false"
                        readOnly="readonly"
                        tabIndex={-1}
                      />
                    </div>
                    <div className="d-flex gap-1">
                      <h4 className="card-price" itemProp="offers">
                        $945{" "}
                      </h4>
                      <p className="m-0 offer_price">
                        <small>
                          <span
                            className="text-decoration-line-through fw-bold"
                            itemProp="price"
                          >
                            $1,889{" "}
                          </span>
                          <span className="text-success fw-bold">
                            <span className="product-discount-label fw-bold">
                              49% off
                            </span>
                          </span>
                        </small>
                      </p>
                    </div>
                  </a>
                  <a
                    href="#"
                    className="btn add-in-cart-btn add_to_cart w-100 px-1 btn-sm  "
                    data-product-id={187}
                    data-product-variant-id={382}
                    data-product-title="Black Full Sleeves Slim Fit T-Shirt"
                    data-product-image="https://eshopweb.store/uploads/media/2023/1_mfk-9146-p-01-black_1.jpg"
                    data-product-price={945}
                    data-min={1}
                    data-step={1}
                    data-product-description="A perfect fusion of style and comfort, this black T-shirt from Mufti is the ideal choice for you."
                    data-bs-toggle="modal"
                    data-bs-target=""
                  >
                    <span className="add-in-cart">Add to Cart</span>
                    <span className="add-in-cart-icon">
                      <svg
                        className="svg-inline--fa fa-cart-shopping color-white"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="cart-shopping"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="currentColor"
                          d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"
                        />
                      </svg>
                      {/* <i class="fa-solid fa-cart-shopping
                                            color-white"></i> Font Awesome fontawesome.com */}
                    </span>
                  </a>
                </div>
                <div className="product-icon-onhover">
                  <div className="product-icon-spacebtw">
                    <div className="shuffle-box">
                      <a
                        className="compare text-reset text-decoration-none shuffle"
                        data-tip="Compare"
                        data-product-id={187}
                        data-product-variant-id={382}
                      >
                        <ion-icon
                          name="shuffle-outline"
                          className="ion-icon-hover pointer shuffle md hydrated"
                          role="img"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="product-icon-spacebtw">
                    <div
                      className="quick-search-box quickview-trigger"
                      data-tip="Quick View"
                      data-product-id={187}
                      data-product-variant-id={382}
                    >
                      <ion-icon
                        name="search-outline"
                        className="ion-icon-hover pointer md hydrated"
                        data-bs-toggle="modal"
                        data-bs-target="#quickview"
                        role="img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-6">
              <a
                href="https://eshopweb.store/products/details/men-shirt"
                className="text-reset text-decoration-none"
              ></a>
              <div
                className="card-temp card p-0 mb-3 mb-md-0 pointer product-card"
                data-product-id={114}
              >
                <a
                  href="https://eshopweb.store/products/details/men-shirt"
                  className="text-reset text-decoration-none"
                >
                  <div className="product-icon-spacebtw">
                    <div
                      className="add-to-fav-btn"
                      title="like"
                      data-product-id={114}
                    >
                      <ion-icon
                        className="ion-icon ion-icon-hover heart-outline text-dark md hydrated"
                        name="heart-outline"
                        role="img"
                      ></ion-icon>
                    </div>
                  </div>
                  <div className="product-image-1">
                    <img
                      className="pic-1 lazy card-img-top entered loaded"
                      src="https://eshopweb.store/uploads/media/2020/thumb-sm/a1.jpg"
                      data-src="https://eshopweb.store/uploads/media/2020/thumb-sm/a1.jpg"
                      data-ll-status="loaded"
                    />
                  </div>
                </a>
                <div className="card-body">
                  <a
                    href="https://eshopweb.store/products/details/men-shirt"
                    className="text-reset text-decoration-none"
                  >
                    <h4 className="card-title">Men Shirt </h4>
                    <p className="card-text">Mens Fashion </p>
                    <div className="d-flex flex-column">
                      <div className="rating-container theme-krajee-svg rating-xs rating-animate rating-disabled">
                        <div className="rating-stars" tabIndex={0}>
                          <span className="empty-stars">
                            <span className="star" title="One Star">
                              <span className="krajee-icon krajee-icon-star" />
                            </span>
                            <span className="star" title="Two Stars">
                              <span className="krajee-icon krajee-icon-star" />
                            </span>
                            <span className="star" title="Three Stars">
                              <span className="krajee-icon krajee-icon-star" />
                            </span>
                            <span className="star" title="Four Stars">
                              <span className="krajee-icon krajee-icon-star" />
                            </span>
                            <span className="star" title="Five Stars">
                              <span className="krajee-icon krajee-icon-star" />
                            </span>
                          </span>
                          <span className="filled-stars" style={{ width: "0%" }}>
                            <span className="star" title="One Star">
                              <span className="krajee-icon krajee-icon-star" />
                            </span>
                            <span className="star" title="Two Stars">
                              <span className="krajee-icon krajee-icon-star" />
                            </span>
                            <span className="star" title="Three Stars">
                              <span className="krajee-icon krajee-icon-star" />
                            </span>
                            <span className="star" title="Four Stars">
                              <span className="krajee-icon krajee-icon-star" />
                            </span>
                            <span className="star" title="Five Stars">
                              <span className="krajee-icon krajee-icon-star" />
                            </span>
                          </span>
                        </div>
                      </div>
                      <input
                        id="input-3-ltr-star-md"
                        name="input-3-ltr-star-md"
                        className="kv-ltr-theme-svg-star rating-input"
                        defaultValue={0.0}
                        dir="ltr"
                        data-size="xs"
                        data-show-clear="false"
                        data-show-caption="false"
                        readOnly="readonly"
                        tabIndex={-1}
                      />
                    </div>
                    <div className="d-flex gap-1">
                      <h4 className="card-price" itemProp="offers">
                        <span itemProp="price"> $800</span>
                      </h4>
                    </div>
                  </a>
                  <a
                    href="#"
                    className="btn add-in-cart-btn add_to_cart w-100 px-1 btn-sm  quickview-trigger"
                    data-product-id={114}
                    data-product-variant-id=""
                    data-product-title="Men Shirt"
                    data-product-image="https://eshopweb.store/uploads/media/2020/a1.jpg"
                    data-product-price={900}
                    data-min={1}
                    data-step={1}
                    data-product-description="Casual Shirt"
                    data-bs-toggle="modal"
                    data-bs-target="#quickview"
                  >
                    <span className="add-in-cart">Add to Cart</span>
                    <span className="add-in-cart-icon">
                      <svg
                        className="svg-inline--fa fa-cart-shopping color-white"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="cart-shopping"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="currentColor"
                          d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"
                        />
                      </svg>
                      {/* <i class="fa-solid fa-cart-shopping
                                            color-white"></i> Font Awesome fontawesome.com */}
                    </span>
                  </a>
                </div>
                <div className="product-icon-onhover">
                  <div className="product-icon-spacebtw">
                    <div className="shuffle-box">
                      <a
                        className="compare text-reset text-decoration-none shuffle"
                        data-tip="Compare"
                        data-product-id={114}
                        data-product-variant-id=""
                      >
                        <ion-icon
                          name="shuffle-outline"
                          className="ion-icon-hover pointer shuffle md hydrated"
                          role="img"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="product-icon-spacebtw">
                    <div
                      className="quick-search-box quickview-trigger"
                      data-tip="Quick View"
                      data-product-id={114}
                      data-product-variant-id={223}
                    >
                      <ion-icon
                        name="search-outline"
                        className="ion-icon-hover pointer md hydrated"
                        data-bs-toggle="modal"
                        data-bs-target="#quickview"
                        role="img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  )
}

export default BigDeals