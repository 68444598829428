import React from 'react'

const Categories = () => {
  return (
    <section className="container py-4">
    <div className="mb-4">
      <h3 className="section-title">Categories</h3>
    </div>
    <div className="row g-4">
      <div className="col-xl-4 col-md-6 col-6">
        <a href="https://eshopweb.store/products/category/electronics-1">
          <div className="categorises-container">
            <div className="categorises-banner-img">
              <img
                className="lazy entered loaded"
                src="https://eshopweb.store/uploads/media/2023/thumb-md/home-appliances-gas-cooker-tv-cinema-refrigerator-microwave-laptop-washing-machine-3d-illustration-min.jpg"
                data-src="https://eshopweb.store/uploads/media/2023/thumb-md/home-appliances-gas-cooker-tv-cinema-refrigerator-microwave-laptop-washing-machine-3d-illustration-min.jpg"
                alt="Electronics"
                data-ll-status="loaded"
              />
            </div>
            <div className="overlay" />
            <div className="category-body">
              <h3>Electronics</h3>
              <button className="btn btn-primary explore-btn">Explore</button>
            </div>
          </div>
        </a>
      </div>
      <div className="col-xl-4 col-md-6 col-6">
        <a href="https://eshopweb.store/products/category/home">
          <div className="categorises-container">
            <div className="categorises-banner-img">
              <img
                className="lazy entered loaded"
                src="https://eshopweb.store/uploads/media/2023/category-img-farni.jpg"
                data-src="https://eshopweb.store/uploads/media/2023/category-img-farni.jpg"
                alt="Home"
                data-ll-status="loaded"
              />
            </div>
            <div className="overlay" />
            <div className="category-body">
              <h3>Home</h3>
              <button className="btn btn-primary explore-btn">Explore</button>
            </div>
          </div>
        </a>
      </div>
      <div className="col-xl-4 col-md-6 col-6">
        <a href="https://eshopweb.store/products/category/baby-care-1">
          <div className="categorises-container">
            <div className="categorises-banner-img">
              <img
                className="lazy entered loaded"
                src="https://eshopweb.store/uploads/media/2020/thumb-md/BABY_AND_KIDS.jpg"
                data-src="https://eshopweb.store/uploads/media/2020/thumb-md/BABY_AND_KIDS.jpg"
                alt="Baby Care"
                data-ll-status="loaded"
              />
            </div>
            <div className="overlay" />
            <div className="category-body">
              <h3>Baby Care</h3>
              <button className="btn btn-primary explore-btn">Explore</button>
            </div>
          </div>
        </a>
      </div>
      <div className="col-xl-4 col-md-6 col-6">
        <a href="https://eshopweb.store/products/category/footwear">
          <div className="categorises-container">
            <div className="categorises-banner-img">
              <img
                className="lazy entered loaded"
                src="https://eshopweb.store/uploads/media/2023/thumb-md/basketball-shoes-sports-shoes-casual-shoes-various-types-shoes-product-display-background-min.jpg"
                data-src="https://eshopweb.store/uploads/media/2023/thumb-md/basketball-shoes-sports-shoes-casual-shoes-various-types-shoes-product-display-background-min.jpg"
                alt="Footwear"
                data-ll-status="loaded"
              />
            </div>
            <div className="overlay" />
            <div className="category-body">
              <h3>Footwear</h3>
              <button className="btn btn-primary explore-btn">Explore</button>
            </div>
          </div>
        </a>
      </div>
      <div className="col-xl-4 col-md-6 col-6">
        <a href="https://eshopweb.store/products/category/watches-2">
          <div className="categorises-container">
            <div className="categorises-banner-img">
              <img
                className="lazy entered loaded"
                src="https://eshopweb.store/uploads/media/2023/swiss-watches-stand-min.jpg"
                data-src="https://eshopweb.store/uploads/media/2023/swiss-watches-stand-min.jpg"
                alt="Watches"
                data-ll-status="loaded"
              />
            </div>
            <div className="overlay" />
            <div className="category-body">
              <h3>Watches</h3>
              <button className="btn btn-primary explore-btn">Explore</button>
            </div>
          </div>
        </a>
      </div>
      <div className="col-xl-4 col-md-6 col-6">
        <a href="https://eshopweb.store/products/category/mens-fashion">
          <div className="categorises-container">
            <div className="categorises-banner-img">
              <img
                className="lazy entered loaded"
                src="https://eshopweb.store/uploads/media/2023/thumb-md/high-street-retail-small-business-commercial-interior-fashion-store-english-countryside-style-elegant-country-clothing-shop-showroom-luxurious-boutique-old-town-generative-ai-min.jpg"
                data-src="https://eshopweb.store/uploads/media/2023/thumb-md/high-street-retail-small-business-commercial-interior-fashion-store-english-countryside-style-elegant-country-clothing-shop-showroom-luxurious-boutique-old-town-generative-ai-min.jpg"
                alt="Mens Fashion"
                data-ll-status="loaded"
              />
            </div>
            <div className="overlay" />
            <div className="category-body">
              <h3>Mens Fashion</h3>
              <button className="btn btn-primary explore-btn">Explore</button>
            </div>
          </div>
        </a>
      </div>
      <div className="col-xl-4 col-md-6 col-6">
        <a href="https://eshopweb.store/products/category/womens-fashion-2">
          <div className="categorises-container">
            <div className="categorises-banner-img">
              <img
                className="lazy entered loaded"
                src="https://eshopweb.store/uploads/media/2023/fashion-clothes-clothing-rack-colorful-closet-min.jpg"
                data-src="https://eshopweb.store/uploads/media/2023/fashion-clothes-clothing-rack-colorful-closet-min.jpg"
                alt="Women's Fashion"
                data-ll-status="loaded"
              />
            </div>
            <div className="overlay" />
            <div className="category-body">
              <h3>Women's Fashion</h3>
              <button className="btn btn-primary explore-btn">Explore</button>
            </div>
          </div>
        </a>
      </div>
      <div className="col-xl-4 col-md-6 col-6">
        <a href="https://eshopweb.store/products/category/groceries">
          <div className="categorises-container">
            <div className="categorises-banner-img">
              <img
                className="lazy entered loaded"
                src="https://eshopweb.store/uploads/media/2023/many-grocery-bags-sitting-some-plants-food-min.jpg"
                data-src="https://eshopweb.store/uploads/media/2023/many-grocery-bags-sitting-some-plants-food-min.jpg"
                alt="Groceries"
                data-ll-status="loaded"
              />
            </div>
            <div className="overlay" />
            <div className="category-body">
              <h3>Groceries</h3>
              <button className="btn btn-primary explore-btn">Explore</button>
            </div>
          </div>
        </a>
      </div>
    </div>
  </section>
  
  )
}

export default Categories