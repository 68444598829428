import React from 'react'

const BottomDetails = () => {
  return (
    <section className="container">
  <div className="row mt-4">
    <ul className="nav nav-tabs" id="myTab" role="tablist">
      {/*? //php if (isset($product['product'][0]['description']) && !empty($product['product'][0]['description'])) { 
          ?*/}
      <li className="nav-item" role="presentation">
        <button
          className="nav-link active"
          id="home-tab"
          data-bs-toggle="tab"
          data-bs-target="#home-tab-pane"
          type="button"
          role="tab"
          aria-controls="home-tab-pane"
          aria-selected="true"
        >
          Description
        </button>
      </li>
      {/*? //php } 
          ?*/}
      <li className="nav-item" role="presentation">
        <button
          className="nav-link"
          id="profile-tab"
          data-bs-toggle="tab"
          data-bs-target="#profile-tab-pane"
          type="button"
          role="tab"
          aria-controls="profile-tab-pane"
          aria-selected="false"
          tabIndex={-1}
        >
          Reviews
        </button>
      </li>
      <li className="nav-item" role="presentation">
        <button
          className="nav-link"
          id="contact-tab"
          data-bs-toggle="tab"
          data-bs-target="#contact-tab-pane"
          type="button"
          role="tab"
          aria-controls="contact-tab-pane"
          aria-selected="false"
          tabIndex={-1}
        >
          FAQs
        </button>
      </li>
    </ul>
    <div className="tab-content" id="myTabContent">
      <div
        className="tab-pane fade show active"
        id="home-tab-pane"
        role="tabpanel"
        aria-labelledby="home-tab"
        tabIndex={0}
      >
        <div className="row">
          <div className="col-12 description overflow-auto description_img">
            <p>
              <strong>
                Fresho Potatoes are nutrient-dense, non-fattening and have
                reasonable amount of calories. Include them in your regular
                meals so that the body receives a good supply of carbohydrates,
                dietary fibers and essential minerals such as copper, magnesium,
                and iron. In India, potatoes are probably the second-most
                consumed vegetables after onions.
              </strong>
            </p>{" "}
          </div>
        </div>
      </div>
      {/* product review tab */}
      <div
        className="tab-pane fade"
        id="profile-tab-pane"
        role="tabpanel"
        aria-labelledby="profile-tab"
        tabIndex={0}
      >
        <div className="row justify-content-center border-radius-10 mt-3">
          {/* REVIEW-box */}
          <div className="col-md-6">
            <div className="row customar-review py-4 border-0">
              <div className="customar-review-view">
                <h4 className="element-title fw-semibold default-cursor m-0 pb-3">
                  Customer Reviews
                </h4>
                <div className="text-center">
                  <h1 className="fw-semibold">
                    <span id="no_ratings">1</span>
                  </h1>
                  <div className="rating-container theme-krajee-svg rating-sm rating-animate rating-disabled">
                    <div className="rating-stars" tabIndex={0}>
                      <span className="empty-stars">
                        <span className="star" title="One Star">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Two Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Three Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Four Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Five Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                      </span>
                      <span className="filled-stars" style={{ width: "80%" }}>
                        <span className="star" title="One Star">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Two Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Three Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Four Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Five Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                      </span>
                    </div>
                  </div>
                  <input
                    id="input-2-ltr-star-sm"
                    name="rating"
                    className="kv-ltr-theme-svg-star rating-input"
                    defaultValue={4.0}
                    dir="ltr"
                    data-size="sm"
                    data-show-clear="false"
                    data-show-caption="false"
                    readOnly="readonly"
                    tabIndex={-1}
                  />
                  <small>
                    <span id="no_ratings">1</span> Reviews
                  </small>
                </div>
                <div className="d-flex align-items-center">
                  <div className="rating-container theme-krajee-svg rating-xs rating-animate rating-disabled">
                    <div className="rating-stars" tabIndex={0}>
                      <span className="empty-stars">
                        <span className="star" title="One Star">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Two Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Three Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Four Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Five Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                      </span>
                      <span className="filled-stars" style={{ width: "100%" }}>
                        <span className="star" title="One Star">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Two Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Three Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Four Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Five Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                      </span>
                    </div>
                  </div>
                  <input
                    id="input-3-ltr-star-md"
                    name="input-3-ltr-star-md"
                    className="kv-ltr-theme-svg-star rating-input"
                    defaultValue={5}
                    dir="ltr"
                    data-size="xs"
                    data-show-clear="false"
                    data-show-caption="false"
                    readOnly="readonly"
                    tabIndex={-1}
                  />
                  <div className="review-line">
                    <div
                      className="progress"
                      role="progressbar"
                      aria-label="Basic example"
                      aria-valuenow={25}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    >
                      <div
                        className="progress-bar bg-success"
                        style={{ width: "0%" }}
                      />
                    </div>
                  </div>
                  <div>0</div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="rating-container theme-krajee-svg rating-xs rating-animate rating-disabled">
                    <div className="rating-stars" tabIndex={0}>
                      <span className="empty-stars">
                        <span className="star" title="One Star">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Two Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Three Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Four Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Five Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                      </span>
                      <span className="filled-stars" style={{ width: "80%" }}>
                        <span className="star" title="One Star">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Two Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Three Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Four Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Five Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                      </span>
                    </div>
                  </div>
                  <input
                    id="input-3-ltr-star-md"
                    name="input-3-ltr-star-md"
                    className="kv-ltr-theme-svg-star rating-input"
                    defaultValue={4}
                    dir="ltr"
                    data-size="xs"
                    data-show-clear="false"
                    data-show-caption="false"
                    readOnly="readonly"
                    tabIndex={-1}
                  />
                  <div className="review-line">
                    <div
                      className="progress"
                      role="progressbar"
                      aria-label="Basic example"
                      aria-valuenow={25}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    >
                      <div
                        className="progress-bar bg-success"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div>1</div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="rating-container theme-krajee-svg rating-xs rating-animate rating-disabled">
                    <div className="rating-stars" tabIndex={0}>
                      <span className="empty-stars">
                        <span className="star" title="One Star">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Two Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Three Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Four Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Five Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                      </span>
                      <span className="filled-stars" style={{ width: "60%" }}>
                        <span className="star" title="One Star">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Two Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Three Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Four Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Five Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                      </span>
                    </div>
                  </div>
                  <input
                    id="input-3-ltr-star-md"
                    name="input-3-ltr-star-md"
                    className="kv-ltr-theme-svg-star rating-input"
                    defaultValue={3}
                    dir="ltr"
                    data-size="xs"
                    data-show-clear="false"
                    data-show-caption="false"
                    readOnly="readonly"
                    tabIndex={-1}
                  />
                  <div className="review-line">
                    <div
                      className="progress"
                      role="progressbar"
                      aria-label="Basic example"
                      aria-valuenow={25}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    >
                      <div
                        className="progress-bar bg-success"
                        style={{ width: "0%" }}
                      />
                    </div>
                  </div>
                  <div>0</div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="rating-container theme-krajee-svg rating-xs rating-animate rating-disabled">
                    <div className="rating-stars" tabIndex={0}>
                      <span className="empty-stars">
                        <span className="star" title="One Star">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Two Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Three Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Four Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Five Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                      </span>
                      <span className="filled-stars" style={{ width: "40%" }}>
                        <span className="star" title="One Star">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Two Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Three Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Four Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Five Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                      </span>
                    </div>
                  </div>
                  <input
                    id="input-3-ltr-star-md"
                    name="input-3-ltr-star-md"
                    className="kv-ltr-theme-svg-star rating-input"
                    defaultValue={2}
                    dir="ltr"
                    data-size="xs"
                    data-show-clear="false"
                    data-show-caption="false"
                    readOnly="readonly"
                    tabIndex={-1}
                  />
                  <div className="review-line">
                    <div
                      className="progress"
                      role="progressbar"
                      aria-label="Basic example"
                      aria-valuenow={25}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    >
                      <div
                        className="progress-bar bg-warning"
                        style={{ width: "0%" }}
                      />
                    </div>
                  </div>
                  <div>0</div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="rating-container theme-krajee-svg rating-xs rating-animate rating-disabled">
                    <div className="rating-stars" tabIndex={0}>
                      <span className="empty-stars">
                        <span className="star" title="One Star">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Two Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Three Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Four Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Five Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                      </span>
                      <span className="filled-stars" style={{ width: "20%" }}>
                        <span className="star" title="One Star">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Two Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Three Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Four Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Five Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                      </span>
                    </div>
                  </div>
                  <input
                    id="input-3-ltr-star-md"
                    name="input-3-ltr-star-md"
                    className="kv-ltr-theme-svg-star rating-input"
                    defaultValue={1}
                    dir="ltr"
                    data-size="xs"
                    data-show-clear="false"
                    data-show-caption="false"
                    readOnly="readonly"
                    tabIndex={-1}
                  />
                  <div className="review-line">
                    <div
                      className="progress"
                      role="progressbar"
                      aria-label="Basic example"
                      aria-valuenow={25}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    >
                      <div
                        className="progress-bar bg-danger"
                        style={{ width: "0%" }}
                      />
                    </div>
                  </div>
                  <div>0</div>
                </div>
              </div>
            </div>
            <nav className="d-flex mt-10"></nav>
          </div>
          {/* create review */}
          {/*Edit review section  */}
        </div>
        <div className="product-reviews-display px-2">
          <div className="mb-3">
            <h4 className="element-title fw-semibold m-0">
              <span id="no_ratings">1 Review Of Product</span> Fresh Potato{" "}
            </h4>
          </div>
          <div className="row gy-3 mb-3 me-0">
            <div className="col-md-6">
              <div className="comment-text">
                <div className="d-flex justify-content-between mb-1">
                  <p className="comment-title" />
                  <p className="comment-time">2022-12-14 12:31:15</p>
                </div>
                <div className="d-flex justify-content-between mb-1">
                  <div className="rating-container theme-krajee-svg rating-xs rating-animate rating-disabled">
                    <div className="rating-stars" tabIndex={0}>
                      <span className="empty-stars">
                        <span className="star" title="One Star">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Two Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Three Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Four Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Five Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                      </span>
                      <span className="filled-stars" style={{ width: "80%" }}>
                        <span className="star" title="One Star">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Two Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Three Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Four Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                        <span className="star" title="Five Stars">
                          <span className="krajee-icon krajee-icon-star" />
                        </span>
                      </span>
                    </div>
                  </div>
                  <input
                    id="star-rating"
                    name="input-3-ltr-star-xs"
                    data-step={1}
                    className="kv-ltr-theme-svg-star star-rating rating-input"
                    defaultValue={4}
                    dir="ltr"
                    data-size="xs"
                    data-show-clear="false"
                    data-show-caption="false"
                    readOnly="readonly"
                    tabIndex={-1}
                  />
                </div>
                <div className="description">
                  <p />
                </div>
                <div className="comment-image">
                  <div className="d-flex overflow-auto"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* product faq tab */}
      <div
        className="tab-pane fade"
        id="contact-tab-pane"
        role="tabpanel"
        aria-labelledby="contact-tab"
        tabIndex={0}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div
                className="accordion accordion-wrapper"
                id="accordionSimpleExample"
              >
                <div></div>
                <div className="modal fade" id="add-faqs-form">
                  <div className="modal-dialog">
                    <div className="modal-content">
                      {/* Modal Header */}
                      <div className="modal-header">
                        <h4 className="modal-title">Add Faq</h4>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        />
                      </div>
                      <div className="modal-body">
                        <form
                          method="post"
                          action="https://eshopweb.store/products/add_faqs"
                          id="add-faqs"
                        >
                          <div className="form-group">
                            <input
                              type="hidden"
                              name=" eshop_security_token"
                              defaultValue="049b6bc590cc5f5892bb859a7584a4d8"
                            />
                            <input
                              type="hidden"
                              name="user_id"
                              defaultValue=""
                            />
                            <input
                              type="hidden"
                              name="product_id"
                              defaultValue={33}
                            />
                            <input
                              type="text"
                              className="form-control"
                              id="question"
                              placeholder="Enter Your Question Here"
                              name="question"
                            />
                          </div>
                          <button
                            type="submit"
                            className="btn btn-primary btn-sm border-radius-10 mt-2"
                            name="add-faqs"
                            value="Save"
                          >
                            Add Question
                          </button>
                          <div className="mt-3">
                            <div id="add_faqs_result" />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column align-items-center">
                  <div className="d-flex flex-column">
                    <img
                      className=""
                      src="https://eshopweb.store/assets/front_end/modern/image/no-faqs.webp"
                      alt="No Faq"
                      width="200px"
                    />
                    <h4 className="text-center">No FAQs Found</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="pt-3 pb-5">
    <div className="align-items-center d-flex gap-4 justify-content-between">
      <h1 className="section-title  pointer">Related Products</h1>
    </div>
    <div className="row gy-3 mb-3 me-0">
      <p className="mt-4 text-muted">No related products found.</p>
    </div>
  </div>
</section>

  )
}

export default BottomDetails