import React from 'react'

const WatchDeals = () => {
  return (
    <section className="container">
    {/* default style */}
    {/* section 3 */}
    <div className="product-style-default mb-4 mb-md-5">
      <div className="align-items-center d-flex flex-wrap justify-content-between pb-3">
        <h1 className="section-title  pointer">Watch on sale</h1>
        <a href="https://eshopweb.store/products/section/136/watch-on-sale">
          <button className="btn viewmorebtn">View More</button>
        </a>
      </div>
      <div className="swiper mySwiper3 swiper-arrow swiper-wid swiper-initialized swiper-horizontal swiper-backface-hidden">
        <div
          className="swiper-wrapper grab"
          id="swiper-wrapper-94f5f1109379410c2e"
          aria-live="polite"
        >
          <div
            className="swiper-slide background-none swiper-slide-active"
            role="group"
            aria-label="1 / 1"
            style={{ width: "251.2px", marginRight: 10 }}
          >
            <a
              href="https://eshopweb.store/products/details/men-watch-125"
              className="text-reset text-decoration-none"
            ></a>
            <div className="card card-h-418 product-card" data-product-id={761}>
              <a
                href="https://eshopweb.store/products/details/men-watch-125"
                className="text-reset text-decoration-none"
              >
                <div className="product-icon-spacebtw">
                  <div
                    className="add-to-fav-btn"
                    title="like"
                    data-product-id={761}
                  >
                    <ion-icon
                      className="ion-icon ion-icon-hover heart-outline text-dark md hydrated"
                      name="heart-outline"
                      role="img"
                    ></ion-icon>
                  </div>
                </div>
                <div className="product-img-style3">
                  <img
                    alt=" "
                    className=""
                    src="https://eshopweb.store/uploads/media/2024/thumb-sm/white-smartwatch-with-dynamic-background-fitness-tracking-waterproof.jpg"
                  />
                </div>
              </a>
              <div className="card-body">
                <a
                  href="https://eshopweb.store/products/details/men-watch-125"
                  className="text-reset text-decoration-none"
                >
                  <h4 className="card-title">men Watch 125 </h4>
                  <p className="card-text">Watche </p>
                  <div className="d-flex flex-column">
                    <div className="rating-container theme-krajee-svg rating-xs rating-animate rating-disabled">
                      <div className="rating-stars" tabIndex={0}>
                        <span className="empty-stars">
                          <span className="star" title="One Star">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Two Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Three Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Four Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Five Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                        </span>
                        <span className="filled-stars" style={{ width: "0%" }}>
                          <span className="star" title="One Star">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Two Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Three Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Four Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Five Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                        </span>
                      </div>
                    </div>
                    <input
                      id="input-3-ltr-star-md"
                      name="input-3-ltr-star-md"
                      className="kv-ltr-theme-svg-star rating-input"
                      defaultValue={0.0}
                      dir="ltr"
                      data-size="xs"
                      data-show-clear="false"
                      data-show-caption="false"
                      readOnly="readonly"
                      tabIndex={-1}
                    />
                    {/* <h4 class="card-price">
  
                                                                                                                        <p class="mb-0 mt-2 price text-muted">
                                                                <span id="price" style='font-size: 20px;'>
                                                                    $                                                                        200.00                                                                    </span>
                                                            </p>
                                                        
                                                    </h4> */}
                    <div className="d-flex gap-1">
                      <h4 className="card-price" itemProp="offers">
                        <span itemProp="price"> $200</span>
                      </h4>
                    </div>
                  </div>
                </a>
                <a
                  href="#"
                  data-tip="Add to Cart"
                  className="btn add-in-cart-btn add_to_cart w-100 btn-sm px-1 "
                  data-product-id={761}
                  data-product-variant-id={1149}
                  data-product-title="men Watch 125"
                  data-product-image="https://eshopweb.store/uploads/media/2024/white-smartwatch-with-dynamic-background-fitness-tracking-waterproof.jpg"
                  data-product-price={200}
                  data-min={1}
                  data-step={1}
                  data-product-description="men watch yellow and black combination"
                  data-bs-toggle="modal"
                  data-bs-target=""
                >
                  <span className="add-in-cart">Add to Cart</span>
                  <span className="add-in-cart-icon">
                    <svg
                      className="svg-inline--fa fa-cart-shopping color-white"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="cart-shopping"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                      data-fa-i2svg=""
                    >
                      <path
                        fill="currentColor"
                        d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"
                      />
                    </svg>
                    {/* <i class="fa-solid fa-cart-shopping
                                            color-white"></i> Font Awesome fontawesome.com */}
                  </span>
                </a>
              </div>
              <div className="product-icon-onhover">
                <div className="product-icon-spacebtw">
                  <div className="shuffle-box">
                    <a
                      className="compare text-reset text-decoration-none shuffle"
                      data-tip="Compare"
                      data-product-id={761}
                      data-product-variant-id={1149}
                    >
                      <ion-icon
                        name="shuffle-outline"
                        className="ion-icon-hover pointer shuffle md hydrated"
                        role="img"
                      ></ion-icon>
                    </a>
                  </div>
                </div>
                <div className="product-icon-spacebtw">
                  <div
                    className="quick-search-box quickview-trigger"
                    data-tip="Quick View"
                    data-product-id={761}
                    data-product-variant-id={1149}
                  >
                    <ion-icon
                      name="search-outline"
                      className="ion-icon-hover pointer md hydrated"
                      data-bs-toggle="modal"
                      data-bs-target="#quickview"
                      role="img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="swiper-button-next swiper-button-disabled swiper-button-lock"
          tabIndex={-1}
          role="button"
          aria-label="Next slide"
          aria-controls="swiper-wrapper-94f5f1109379410c2e"
          aria-disabled="true"
        >
          <ion-icon
            name="chevron-forward-outline"
            role="img"
            className="md flip-rtl hydrated"
          />
        </div>
        <div
          className="swiper-button-prev swiper-button-disabled swiper-button-lock"
          tabIndex={-1}
          role="button"
          aria-label="Previous slide"
          aria-controls="swiper-wrapper-94f5f1109379410c2e"
          aria-disabled="true"
        >
          <ion-icon
            name="chevron-back-outline"
            role="img"
            className="md flip-rtl hydrated"
          />
        </div>
        <span
          className="swiper-notification"
          aria-live="assertive"
          aria-atomic="true"
        />
      </div>
    </div>
  </section>
  
  )
}

export default WatchDeals