import React from "react";
import { NavLink } from "react-router-dom";

const Products = () => {
  return (
    <section className="container-lg py-4">
      <section className="breadcrumb-title-bar colored-breadcrumb">
        <div className="main-content responsive-breadcrumb">
          <h5 className="section-title">Product Listing</h5>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <NavLink to="https://eshopweb.store/">Home</NavLink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Products
              </li>
            </ol>
          </nav>
        </div>
      </section>
      {/* product-filter in mobilescreen */}
      <div className="d-lg-none row product-filter-mobilescreen mb-4 py-2">
        <button
          className="btn col py-0 sortby-btn"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#sort-by"
          aria-controls="offcanvasBottom"
        >
          <ion-icon name="funnel-outline" role="img" className="md hydrated" />{" "}
          <span className="mx-2"> Sort By</span>
        </button>
        <button
          className="btn col py-0"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#filter"
          aria-controls="offcanvasBottom"
        >
          <ion-icon name="options-outline" role="img" className="md hydrated" />
          <span className="mx-2"> Filter</span>
        </button>
      </div>
      {/* pc screen view */}
      <div className="row m-0">
        {/* <div class="d-flex gap-4 m-0"> */}
        <div className="col-lg-3 d-none d-lg-block filter-section p-3">
          <h3 className="mb-4">Filter</h3>
          <div id="product-filters-desktop">
            {" "}
            <div
              className="accordion accordion-flush border-top"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#Desktop0"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    Battery Power
                  </button>
                </h2>
                <div
                  id="Desktop0"
                  className="accordion-collapse collapse  "
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="6000 MH"
                        defaultValue="6000 MH"
                        className="toggle-input product_attributes width15px"
                        id="Desktopbattery-power 6000 MH"
                        data-attribute="battery-power"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopbattery-power 6000 MH"
                      >
                        6000 MH
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="4000 mAh"
                        defaultValue="4000 mAh"
                        className="toggle-input product_attributes width15px"
                        id="Desktopbattery-power 4000 mAh"
                        data-attribute="battery-power"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopbattery-power 4000 mAh"
                      >
                        4000 mAh
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div
              className="accordion accordion-flush border-top"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#Desktop1"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    Camera features
                  </button>
                </h2>
                <div
                  id="Desktop1"
                  className="accordion-collapse collapse  "
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="u200eRear"
                        defaultValue="u200eRear"
                        className="toggle-input product_attributes width15px"
                        id="Desktopcamera-features u200eRear"
                        data-attribute="camera-features"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopcamera-features u200eRear"
                      >
                        u200eRear
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="Front"
                        defaultValue="Front"
                        className="toggle-input product_attributes width15px"
                        id="Desktopcamera-features Front"
                        data-attribute="camera-features"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopcamera-features Front"
                      >
                        Front
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div
              className="accordion accordion-flush border-top"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#Desktop2"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    Color
                  </button>
                </h2>
                <div
                  id="Desktop2"
                  className="accordion-collapse collapse  "
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="Red"
                        defaultValue="Red"
                        className="toggle-input product_attributes width15px"
                        id="Desktopcolor Red"
                        data-attribute="color"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopcolor Red"
                      >
                        Red
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="Yellow"
                        defaultValue="Yellow"
                        className="toggle-input product_attributes width15px"
                        id="Desktopcolor Yellow"
                        data-attribute="color"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopcolor Yellow"
                      >
                        Yellow
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="Blue"
                        defaultValue="Blue"
                        className="toggle-input product_attributes width15px"
                        id="Desktopcolor Blue"
                        data-attribute="color"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopcolor Blue"
                      >
                        Blue
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="Black"
                        defaultValue="Black"
                        className="toggle-input product_attributes width15px"
                        id="Desktopcolor Black"
                        data-attribute="color"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopcolor Black"
                      >
                        Black
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="White Color"
                        defaultValue="White Color"
                        className="toggle-input product_attributes width15px"
                        id="Desktopcolor White Color"
                        data-attribute="color"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopcolor White Color"
                      >
                        White Color
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="Green"
                        defaultValue="Green"
                        className="toggle-input product_attributes width15px"
                        id="Desktopcolor Green"
                        data-attribute="color"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopcolor Green"
                      >
                        Green
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="Silver"
                        defaultValue="Silver"
                        className="toggle-input product_attributes width15px"
                        id="Desktopcolor Silver"
                        data-attribute="color"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopcolor Silver"
                      >
                        Silver
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="Pink"
                        defaultValue="Pink"
                        className="toggle-input product_attributes width15px"
                        id="Desktopcolor Pink"
                        data-attribute="color"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopcolor Pink"
                      >
                        Pink
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div
              className="accordion accordion-flush border-top"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#Desktop3"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    Colour
                  </button>
                </h2>
                <div
                  id="Desktop3"
                  className="accordion-collapse collapse  "
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="White"
                        defaultValue="White"
                        className="toggle-input product_attributes width15px"
                        id="Desktopcolour White"
                        data-attribute="colour"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopcolour White"
                      >
                        White
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="Emerald Green"
                        defaultValue="Emerald Green"
                        className="toggle-input product_attributes width15px"
                        id="Desktopcolour Emerald Green"
                        data-attribute="colour"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopcolour Emerald Green"
                      >
                        Emerald Green
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="Matte Black"
                        defaultValue="Matte Black"
                        className="toggle-input product_attributes width15px"
                        id="Desktopcolour Matte Black"
                        data-attribute="colour"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopcolour Matte Black"
                      >
                        Matte Black
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div
              className="accordion accordion-flush border-top"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#Desktop4"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    Connectivity technologies
                  </button>
                </h2>
                <div
                  id="Desktop4"
                  className="accordion-collapse collapse  "
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="u200eBluetooth"
                        defaultValue="u200eBluetooth"
                        className="toggle-input product_attributes width15px"
                        id="Desktopconnectivity-technologies u200eBluetooth"
                        data-attribute="connectivity-technologies"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopconnectivity-technologies u200eBluetooth"
                      >
                        u200eBluetooth
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="Wi-Fi"
                        defaultValue="Wi-Fi"
                        className="toggle-input product_attributes width15px"
                        id="Desktopconnectivity-technologies Wi-Fi"
                        data-attribute="connectivity-technologies"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopconnectivity-technologies Wi-Fi"
                      >
                        Wi-Fi
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="USB"
                        defaultValue="USB"
                        className="toggle-input product_attributes width15px"
                        id="Desktopconnectivity-technologies USB"
                        data-attribute="connectivity-technologies"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopconnectivity-technologies USB"
                      >
                        USB
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div
              className="accordion accordion-flush border-top"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#Desktop5"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    Display Size
                  </button>
                </h2>
                <div
                  id="Desktop5"
                  className="accordion-collapse collapse  "
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="22 in"
                        defaultValue="22 in"
                        className="toggle-input product_attributes width15px"
                        id="Desktopdisplay-size 22 in"
                        data-attribute="display-size"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopdisplay-size 22 in"
                      >
                        22 in
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="21.5 in"
                        defaultValue="21.5 in"
                        className="toggle-input product_attributes width15px"
                        id="Desktopdisplay-size 21.5 in"
                        data-attribute="display-size"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopdisplay-size 21.5 in"
                      >
                        21.5 in
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div
              className="accordion accordion-flush border-top"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#Desktop6"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    Display Technology
                  </button>
                </h2>
                <div
                  id="Desktop6"
                  className="accordion-collapse collapse  "
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="LED"
                        defaultValue="LED"
                        className="toggle-input product_attributes width15px"
                        id="Desktopdisplay-technology LED"
                        data-attribute="display-technology"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopdisplay-technology LED"
                      >
                        LED
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div
              className="accordion accordion-flush border-top"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#Desktop7"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    Expandable Storage
                  </button>
                </h2>
                <div
                  id="Desktop7"
                  className="accordion-collapse collapse  "
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="64 GB"
                        defaultValue="64 GB"
                        className="toggle-input product_attributes width15px"
                        id="Desktopexpandable-storage 64 GB"
                        data-attribute="expandable-storage"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopexpandable-storage 64 GB"
                      >
                        64 GB
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="16 GB"
                        defaultValue="16 GB"
                        className="toggle-input product_attributes width15px"
                        id="Desktopexpandable-storage 16 GB"
                        data-attribute="expandable-storage"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopexpandable-storage 16 GB"
                      >
                        16 GB
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="32 GB"
                        defaultValue="32 GB"
                        className="toggle-input product_attributes width15px"
                        id="Desktopexpandable-storage 32 GB"
                        data-attribute="expandable-storage"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopexpandable-storage 32 GB"
                      >
                        32 GB
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="128 GB"
                        defaultValue="128 GB"
                        className="toggle-input product_attributes width15px"
                        id="Desktopexpandable-storage 128 GB"
                        data-attribute="expandable-storage"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopexpandable-storage 128 GB"
                      >
                        128 GB
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="256 GB"
                        defaultValue="256 GB"
                        className="toggle-input product_attributes width15px"
                        id="Desktopexpandable-storage 256 GB"
                        data-attribute="expandable-storage"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopexpandable-storage 256 GB"
                      >
                        256 GB
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div
              className="accordion accordion-flush border-top"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#Desktop8"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    Item Weight
                  </button>
                </h2>
                <div
                  id="Desktop8"
                  className="accordion-collapse collapse  "
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="250 g"
                        defaultValue="250 g"
                        className="toggle-input product_attributes width15px"
                        id="Desktopitem-weight 250 g"
                        data-attribute="item-weight"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopitem-weight 250 g"
                      >
                        250 g
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="2 kg"
                        defaultValue="2 kg"
                        className="toggle-input product_attributes width15px"
                        id="Desktopitem-weight 2 kg"
                        data-attribute="item-weight"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopitem-weight 2 kg"
                      >
                        2 kg
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div
              className="accordion accordion-flush border-top"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#Desktop9"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    Material Type
                  </button>
                </h2>
                <div
                  id="Desktop9"
                  className="accordion-collapse collapse  "
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="Plastic"
                        defaultValue="Plastic"
                        className="toggle-input product_attributes width15px"
                        id="Desktopmaterial-type Plastic"
                        data-attribute="material-type"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopmaterial-type Plastic"
                      >
                        Plastic
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div
              className="accordion accordion-flush border-top"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#Desktop10"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    OS
                  </button>
                </h2>
                <div
                  id="Desktop10"
                  className="accordion-collapse collapse  "
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="Android 13"
                        defaultValue="Android 13"
                        className="toggle-input product_attributes width15px"
                        id="Desktopos Android 13"
                        data-attribute="os"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopos Android 13"
                      >
                        Android 13
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div
              className="accordion accordion-flush border-top"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#Desktop11"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    Product Dimensions
                  </button>
                </h2>
                <div
                  id="Desktop11"
                  className="accordion-collapse collapse  "
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="10.9 x 0.9 x 7.5 cm"
                        defaultValue="10.9 x 0.9 x 7.5 cm"
                        className="toggle-input product_attributes width15px"
                        id="Desktopproduct-dimensions 10.9 x 0.9 x 7.5 cm"
                        data-attribute="product-dimensions"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopproduct-dimensions 10.9 x 0.9 x 7.5 cm"
                      >
                        10.9 x 0.9 x 7.5 cm
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div
              className="accordion accordion-flush border-top"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#Desktop12"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    RAM
                  </button>
                </h2>
                <div
                  id="Desktop12"
                  className="accordion-collapse collapse  "
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="u200e12 GB"
                        defaultValue="u200e12 GB"
                        className="toggle-input product_attributes width15px"
                        id="Desktopram u200e12 GB"
                        data-attribute="ram"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopram u200e12 GB"
                      >
                        u200e12 GB
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="8 GB"
                        defaultValue="8 GB"
                        className="toggle-input product_attributes width15px"
                        id="Desktopram 8 GB"
                        data-attribute="ram"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopram 8 GB"
                      >
                        8 GB
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div
              className="accordion accordion-flush border-top"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#Desktop13"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    Screen Type
                  </button>
                </h2>
                <div
                  id="Desktop13"
                  className="accordion-collapse collapse  "
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="AMOLED"
                        defaultValue="AMOLED"
                        className="toggle-input product_attributes width15px"
                        id="Desktopscreen-type AMOLED"
                        data-attribute="screen-type"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopscreen-type AMOLED"
                      >
                        AMOLED
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div
              className="accordion accordion-flush border-top"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#Desktop14"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    Size
                  </button>
                </h2>
                <div
                  id="Desktop14"
                  className="accordion-collapse collapse  "
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="1kg"
                        defaultValue="1kg"
                        className="toggle-input product_attributes width15px"
                        id="Desktopsize 1kg"
                        data-attribute="size"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopsize 1kg"
                      >
                        1kg
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="1 L"
                        defaultValue="1 L"
                        className="toggle-input product_attributes width15px"
                        id="Desktopsize 1 L"
                        data-attribute="size"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopsize 1 L"
                      >
                        1 L
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="XL"
                        defaultValue="XL"
                        className="toggle-input product_attributes width15px"
                        id="Desktopsize XL"
                        data-attribute="size"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopsize XL"
                      >
                        XL
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="L"
                        defaultValue="L"
                        className="toggle-input product_attributes width15px"
                        id="Desktopsize L"
                        data-attribute="size"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopsize L"
                      >
                        L
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="M"
                        defaultValue="M"
                        className="toggle-input product_attributes width15px"
                        id="Desktopsize M"
                        data-attribute="size"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopsize M"
                      >
                        M
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="6 UK"
                        defaultValue="6 UK"
                        className="toggle-input product_attributes width15px"
                        id="Desktopsize 6 UK"
                        data-attribute="size"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopsize 6 UK"
                      >
                        6 UK
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="7 UK"
                        defaultValue="7 UK"
                        className="toggle-input product_attributes width15px"
                        id="Desktopsize 7 UK"
                        data-attribute="size"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopsize 7 UK"
                      >
                        7 UK
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="8 UK"
                        defaultValue="8 UK"
                        className="toggle-input product_attributes width15px"
                        id="Desktopsize 8 UK"
                        data-attribute="size"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopsize 8 UK"
                      >
                        8 UK
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="3 UK"
                        defaultValue="3 UK"
                        className="toggle-input product_attributes width15px"
                        id="Desktopsize 3 UK"
                        data-attribute="size"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopsize 3 UK"
                      >
                        3 UK
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="2 UK"
                        defaultValue="2 UK"
                        className="toggle-input product_attributes width15px"
                        id="Desktopsize 2 UK"
                        data-attribute="size"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopsize 2 UK"
                      >
                        2 UK
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="1 UK"
                        defaultValue="1 UK"
                        className="toggle-input product_attributes width15px"
                        id="Desktopsize 1 UK"
                        data-attribute="size"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopsize 1 UK"
                      >
                        1 UK
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="Width 40"
                        defaultValue="Width 40"
                        className="toggle-input product_attributes width15px"
                        id="Desktopsize Width 40"
                        data-attribute="size"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopsize Width 40"
                      >
                        Width 40
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="Height 50"
                        defaultValue="Height 50"
                        className="toggle-input product_attributes width15px"
                        id="Desktopsize Height 50"
                        data-attribute="size"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopsize Height 50"
                      >
                        Height 50
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="500 ml"
                        defaultValue="500 ml"
                        className="toggle-input product_attributes width15px"
                        id="Desktopsize 500 ml"
                        data-attribute="size"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopsize 500 ml"
                      >
                        500 ml
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="300 ml"
                        defaultValue="300 ml"
                        className="toggle-input product_attributes width15px"
                        id="Desktopsize 300 ml"
                        data-attribute="size"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopsize 300 ml"
                      >
                        300 ml
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="300g"
                        defaultValue="300g"
                        className="toggle-input product_attributes width15px"
                        id="Desktopsize 300g"
                        data-attribute="size"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopsize 300g"
                      >
                        300g
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="400g"
                        defaultValue="400g"
                        className="toggle-input product_attributes width15px"
                        id="Desktopsize 400g"
                        data-attribute="size"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopsize 400g"
                      >
                        400g
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="500g"
                        defaultValue="500g"
                        className="toggle-input product_attributes width15px"
                        id="Desktopsize 500g"
                        data-attribute="size"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopsize 500g"
                      >
                        500g
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="xxl"
                        defaultValue="xxl"
                        className="toggle-input product_attributes width15px"
                        id="Desktopsize xxl"
                        data-attribute="size"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopsize xxl"
                      >
                        xxl
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="128 GB"
                        defaultValue="128 GB"
                        className="toggle-input product_attributes width15px"
                        id="Desktopsize 128 GB"
                        data-attribute="size"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopsize 128 GB"
                      >
                        128 GB
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="256 GB"
                        defaultValue="256 GB"
                        className="toggle-input product_attributes width15px"
                        id="Desktopsize 256 GB"
                        data-attribute="size"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopsize 256 GB"
                      >
                        256 GB
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="512 GB"
                        defaultValue="512 GB"
                        className="toggle-input product_attributes width15px"
                        id="Desktopsize 512 GB"
                        data-attribute="size"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopsize 512 GB"
                      >
                        512 GB
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div
              className="accordion accordion-flush border-top"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#Desktop15"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    Star
                  </button>
                </h2>
                <div
                  id="Desktop15"
                  className="accordion-collapse collapse  "
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name={2}
                        defaultValue={2}
                        className="toggle-input product_attributes width15px"
                        id="Desktopstar 2"
                        data-attribute="star"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopstar 2"
                      >
                        2
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name={3}
                        defaultValue={3}
                        className="toggle-input product_attributes width15px"
                        id="Desktopstar 3"
                        data-attribute="star"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopstar 3"
                      >
                        3
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name={4}
                        defaultValue={4}
                        className="toggle-input product_attributes width15px"
                        id="Desktopstar 4"
                        data-attribute="star"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopstar 4"
                      >
                        4
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name={5}
                        defaultValue={5}
                        className="toggle-input product_attributes width15px"
                        id="Desktopstar 5"
                        data-attribute="star"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopstar 5"
                      >
                        5
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div
              className="accordion accordion-flush border-top"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#Desktop16"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    Warranty Type
                  </button>
                </h2>
                <div
                  id="Desktop16"
                  className="accordion-collapse collapse  "
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="manufacturer"
                        defaultValue="manufacturer"
                        className="toggle-input product_attributes width15px"
                        id="Desktopwarranty-type manufacturer"
                        data-attribute="warranty-type"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopwarranty-type manufacturer"
                      >
                        manufacturer
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div
              className="accordion accordion-flush border-top"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#Desktop17"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    Wireless communication technologies
                  </button>
                </h2>
                <div
                  id="Desktop17"
                  className="accordion-collapse collapse  "
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="Bluetooth"
                        defaultValue="Bluetooth"
                        className="toggle-input product_attributes width15px"
                        id="Desktopwireless-communication technologies Bluetooth"
                        data-attribute="wireless-communication technologies"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopwireless-communication technologies Bluetooth"
                      >
                        Bluetooth
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="WiFi Hotspot"
                        defaultValue="WiFi Hotspot"
                        className="toggle-input product_attributes width15px"
                        id="Desktopwireless-communication technologies WiFi Hotspot"
                        data-attribute="wireless-communication technologies"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopwireless-communication technologies WiFi Hotspot"
                      >
                        WiFi Hotspot
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div
              className="accordion accordion-flush border-top"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#Desktop18"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    wooden
                  </button>
                </h2>
                <div
                  id="Desktop18"
                  className="accordion-collapse collapse  "
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="4gb"
                        defaultValue="4gb"
                        className="toggle-input product_attributes width15px"
                        id="Desktopwooden 4gb"
                        data-attribute="wooden"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopwooden 4gb"
                      >
                        4gb
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="8gb"
                        defaultValue="8gb"
                        className="toggle-input product_attributes width15px"
                        id="Desktopwooden 8gb"
                        data-attribute="wooden"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopwooden 8gb"
                      >
                        8gb
                      </label>
                    </div>
                    <div className="form-check d-flex ps-0">
                      <input
                        type="checkbox"
                        name="16 GB hj"
                        defaultValue="16 GB hj"
                        className="toggle-input product_attributes width15px"
                        id="Desktopwooden 16 GB hj"
                        data-attribute="wooden"
                      />{" "}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="Desktopwooden 16 GB hj"
                      >
                        16 GB hj
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="brand-filters-desktop"
            className="filter_brands mb-5 mt-2 bg-white"
          >
            <div className="align-content-center d-flex justify-content-between">
              <h6 className="m-0">Brands</h6>
            </div>
            <div className="brand_filter d-flex flex-wrap gap-4 mb-5 mt-2 p-1">
              <div className="brand_div">
                <label className="form-check-label" htmlFor="2-brand">
                  <input
                    className="brand form-check-input"
                    type="radio"
                    name="brandRadio"
                    data-value="puma-1"
                    id="2-brand"
                  />
                  <img
                    src="https://eshopweb.store/uploads/media/2024/Puma-Logo.png"
                    title="Puma"
                    alt="brand-logo"
                  />
                </label>
              </div>
              <div className="brand_div">
                <label className="form-check-label" htmlFor="4-brand">
                  <input
                    className="brand form-check-input"
                    type="radio"
                    name="brandRadio"
                    data-value="apple"
                    id="4-brand"
                  />
                  <img
                    src="https://eshopweb.store/uploads/media/2023/Apple_logo_black_(1).png"
                    title="Apple"
                    alt="brand-logo"
                  />
                </label>
              </div>
              <div className="brand_div">
                <label className="form-check-label" htmlFor="14-brand">
                  <input
                    className="brand form-check-input"
                    type="radio"
                    name="brandRadio"
                    data-value="acer"
                    id="14-brand"
                  />
                  <img
                    src="https://eshopweb.store/uploads/media/2024/Acer-Logo.png"
                    title="Acer"
                    alt="brand-logo"
                  />
                </label>
              </div>
              <div className="brand_div">
                <label className="form-check-label" htmlFor="19-brand">
                  <input
                    className="brand form-check-input"
                    type="radio"
                    name="brandRadio"
                    data-value="adobe"
                    id="19-brand"
                  />
                  <img
                    src="https://eshopweb.store/uploads/media/2024/adobe.jpeg"
                    title="Adobe"
                    alt="brand-logo"
                  />
                </label>
              </div>
              <div className="brand_div">
                <label className="form-check-label" htmlFor="21-brand">
                  <input
                    className="brand form-check-input"
                    type="radio"
                    name="brandRadio"
                    data-value="away-1"
                    id="21-brand"
                  />
                  <img
                    src="https://eshopweb.store/uploads/chat_media//Screenshot_2024-07-21-17-08-44-68_8725ce44afe22ce17e3660df7f33c32e.jpg"
                    title="away"
                    alt="brand-logo"
                  />
                </label>
              </div>
              <div className="brand_div">
                <label className="form-check-label" htmlFor="3-brand">
                  <input
                    className="brand form-check-input"
                    type="radio"
                    name="brandRadio"
                    data-value="samsung-1"
                    id="3-brand"
                  />
                  <img
                    src="https://eshopweb.store/uploads/media/2023/Samsung-Logo_wine.png"
                    title="Samsung"
                    alt="brand-logo"
                  />
                </label>
              </div>
              <div className="brand_div">
                <label className="form-check-label" htmlFor="7-brand">
                  <input
                    className="brand form-check-input"
                    type="radio"
                    name="brandRadio"
                    data-value="one-plus"
                    id="7-brand"
                  />
                  <img
                    src="https://eshopweb.store/uploads/media/2023/OnePlus_Logo.png"
                    title="One plus"
                    alt="brand-logo"
                  />
                </label>
              </div>
              <div className="brand_div">
                <label className="form-check-label" htmlFor="1-brand">
                  <input
                    className="brand form-check-input"
                    type="radio"
                    name="brandRadio"
                    data-value="lenovo"
                    id="1-brand"
                  />
                  <img
                    src="https://eshopweb.store/uploads/media/2024/Lenovo-Logo-1.png"
                    title="Lenovo"
                    alt="brand-logo"
                  />
                </label>
              </div>
              <div className="brand_div">
                <label className="form-check-label" htmlFor="16-brand">
                  <input
                    className="brand form-check-input"
                    type="radio"
                    name="brandRadio"
                    data-value="hp"
                    id="16-brand"
                  />
                  <img
                    src="https://eshopweb.store/uploads/media/2024/1024px-HP_logo_2012_svg.png"
                    title="HP"
                    alt="brand-logo"
                  />
                </label>
              </div>
              <div className="brand_div">
                <label className="form-check-label" htmlFor="15-brand">
                  <input
                    className="brand form-check-input"
                    type="radio"
                    name="brandRadio"
                    data-value="godrej"
                    id="15-brand"
                  />
                  <img
                    src="https://eshopweb.store/uploads/media/2024/Godrej_Logo_svg.png"
                    title="Godrej"
                    alt="brand-logo"
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="text-center">
            <button className="btn btn-primary product_filter_btn">
              Filter
            </button>
            <NavLink to="#" id="reload" className="btn btn-danger mx-5">
              Reset
            </NavLink>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="productlist-title-section d-flex justify-content-between">
            <h3 className="section-title">Products</h3>
            <div className="d-flex gap-2">
              <NavLink
                id="product_grid_view_btn"
                className="grid-list-icon-box link-dark active"
                to="https://eshopweb.store/products"
              >
                <ion-icon
                  className="grid-icons-outline md hydrated"
                  title="Grid view"
                  role="img"
                />
              </NavLink>
              <NavLink
                id="product_list_view_btn"
                className="grid-list-icon-box  link-dark"
                to="https://eshopweb.store/products?type=list"
              >
                <ion-icon
                  className="list-icons-outline md hydrated"
                  title="List view"
                  role="img"
                />
              </NavLink>
              <div className="sort-by-btn d-none d-lg-block">
                <select
                  className="form-select"
                  id="product_sort_by"
                  aria-label="Default select example"
                >
                  <option value="relevance">Relevance</option>
                  <option value="top-rated">Top Rated</option>
                  <option value="date-desc">Newest First</option>
                  <option value="date-asc">Oldest First</option>
                  <option value="price-asc">Price - Low To High</option>
                  <option value="price-desc">Price - High To Low</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row my-4">
            <div className="col-6 grid-card-container product-card  col-md-4 ">
              <div
                className="card grid-view-card product-card"
                data-product-id={33}
              >
                <div className="rating-div">
                  <div className="product-icon-spacebtw">
                    <div
                      className="add-to-fav-btn"
                      title="like"
                      data-product-id={33}
                    >
                      <ion-icon
                        className="ion-icon ion-icon-hover heart-outline text-dark md hydrated"
                        name="heart-outline"
                        role="img"
                      ></ion-icon>
                    </div>
                  </div>
                </div>
                <NavLink
                  to="/products/details"
                  className="text-reset text-decoration-none"
                >
                  <div>
                    <div className="img-box-h250">
                      <link
                        itemProp="image"
                        href="https://eshopweb.store/uploads/media/2020/thumb-sm/potato_b.png"
                      />
                      <img
                        className="pic-1 lazy entered loading"
                        alt="Fresh Potato"
                        title="Fresh Potato"
                        src="https://eshopweb.store/uploads/media/2020/thumb-sm/potato_b.png"
                        data-ll-status="loading"
                      />
                    </div>
                  </div>
                </NavLink>
                <div className="card-body">
                  <NavLink
                    to="/products/details"
                    className="text-reset text-decoration-none"
                  >
                    <h5 className="brand-title" itemProp="name" />
                    <h5 className="card-title" itemProp="name">
                      Fresh Potato
                    </h5>
                    {/* <p class="card-text">17.27 cm (6.8 inch) Quad HD+ Display, 200MP + 10MP + 12MP +
                                10MP | 12MP Front Camera</p> */}
                    <div className="rating-container theme-krajee-svg rating-xs rating-animate rating-disabled">
                      <div className="rating-stars" tabIndex={0}>
                        <span className="empty-stars">
                          <span className="star" title="One Star">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Two Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Three Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Four Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Five Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                        </span>
                        <span className="filled-stars" style={{ width: "80%" }}>
                          <span className="star" title="One Star">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Two Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Three Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Four Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Five Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                        </span>
                      </div>
                    </div>
                    <input
                      id="input-3-ltr-star-md"
                      name="input-3-ltr-star-md"
                      className="kv-ltr-theme-svg-star rating-input"
                      defaultValue={4.0}
                      dir="ltr"
                      data-size="xs"
                      data-show-clear="false"
                      data-show-caption="false"
                      readOnly="readonly"
                      tabIndex={-1}
                    />
                    <div className="d-flex align-items-center gap-2">
                      <h4
                        className="card-price"
                        itemProp="offers"
                        itemType="https://schema.org/Offer"
                      >
                        <meta itemProp="price" content="10.5" />
                        $11 <meta itemProp="priceCurrency" content="$" />
                      </h4>
                      <span itemProp="price">$11</span>
                    </div>
                  </NavLink>
                  <NavLink
                    className="btn add-in-cart-btn w-100 add_to_cart  p-1 btn-sm quickview-trigger"
                    to="/products/details"
                    data-product-id={33}
                    data-product-variant-id=""
                    data-product-title="Fresh Potato"
                    data-product-image="https://eshopweb.store/uploads/media/2020/potato_b.png"
                    data-product-price="10.5"
                    data-min={1}
                    data-max={5}
                    data-type="variable_product"
                    data-step={1}
                    data-product-description="Fresh Potato"
                    data-bs-toggle="modal"
                    data-bs-target="#quickview"
                  >
                    <span className="add-in-cart">Add to Cart</span>
                    <span className="add-in-cart-icon">
                      <svg
                        className="svg-inline--fa fa-cart-shopping color-white"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="cart-shopping"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="currentColor"
                          d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"
                        />
                      </svg>
                      {/* <i class="fa-solid fa-cart-shopping color-white"></i> Font Awesome fontawesome.com */}
                    </span>
                  </NavLink>
                </div>
                <div className="product-icon-onhover">
                  <div className="product-icon-spacebtw">
                    <div className="shuffle-box">
                      <NavLink
                        className="compare text-reset text-decoration-none shuffle"
                        data-tip="Compare"
                        data-product-id={33}
                        data-product-variant-id=""
                      >
                        <ion-icon
                          name="shuffle-outline"
                          className="ion-icon-hover pointer shuffle md hydrated"
                          role="img"
                        />
                      </NavLink>
                    </div>
                  </div>
                  <div className="product-icon-spacebtw">
                    <div
                      className="quick-search-box quickview-trigger"
                      data-tip="Quick View"
                      data-product-id={33}
                      data-product-variant-id={60}
                      data-izimodal-open="#quickview"
                    >
                      <ion-icon
                        name="search-outline"
                        className="ion-icon-hover pointer md hydrated"
                        data-bs-toggle="modal"
                        data-bs-target="#quickview"
                        role="img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 grid-card-container product-card  col-md-4 ">
              <div
                className="card grid-view-card product-card"
                data-product-id={156}
              >
                <div className="rating-div">
                  <div className="product-icon-spacebtw">
                    <div
                      className="add-to-fav-btn"
                      title="like"
                      data-product-id={156}
                    >
                      <ion-icon
                        className="ion-icon ion-icon-hover heart-outline text-dark md hydrated"
                        name="heart-outline"
                        role="img"
                      ></ion-icon>
                    </div>
                  </div>
                </div>
                <NavLink
                  to="/products/details"
                  className="text-reset text-decoration-none"
                >
                  <div>
                    <div className="img-box-h250">
                      <link
                        itemProp="image"
                        href="https://eshopweb.store/uploads/media/2020/thumb-sm/a18.jpg"
                      />
                      <img
                        className="pic-1 lazy entered loading"
                        alt="Toor Dal"
                        title="Toor Dal"
                        src="https://eshopweb.store/uploads/media/2020/thumb-sm/a18.jpg"
                        data-ll-status="loading"
                      />
                    </div>
                  </div>
                </NavLink>
                <div className="card-body">
                  <NavLink
                    to="/products/details"
                    className="text-reset text-decoration-none"
                  >
                    <h5 className="brand-title" itemProp="name" />
                    <h5 className="card-title" itemProp="name">
                      Toor Dal
                    </h5>
                    {/* <p class="card-text">17.27 cm (6.8 inch) Quad HD+ Display, 200MP + 10MP + 12MP +
                                10MP | 12MP Front Camera</p> */}
                    <div className="rating-container theme-krajee-svg rating-xs rating-animate rating-disabled">
                      <div className="rating-stars" tabIndex={0}>
                        <span className="empty-stars">
                          <span className="star" title="One Star">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Two Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Three Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Four Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Five Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                        </span>
                        <span className="filled-stars" style={{ width: "80%" }}>
                          <span className="star" title="One Star">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Two Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Three Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Four Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Five Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                        </span>
                      </div>
                    </div>
                    <input
                      id="input-3-ltr-star-md"
                      name="input-3-ltr-star-md"
                      className="kv-ltr-theme-svg-star rating-input"
                      defaultValue={4.0}
                      dir="ltr"
                      data-size="xs"
                      data-show-clear="false"
                      data-show-caption="false"
                      readOnly="readonly"
                      tabIndex={-1}
                    />
                    <div className="d-flex align-items-center gap-2">
                      <h4
                        className="card-price"
                        itemProp="offers"
                        itemType="https://schema.org/Offer"
                      >
                        <meta itemProp="price" content={210} />
                        $189 <meta itemProp="priceCurrency" content="$" />
                      </h4>
                      <p className="m-0 offer_price">
                        <small>
                          <span
                            className="text-decoration-line-through fw-bold"
                            itemProp="price"
                          >
                            $210{" "}
                          </span>
                          <span className="text-success fw-bold">
                            <span className="product-discount-label fw-bold">
                              18% off
                            </span>
                          </span>
                        </small>
                      </p>
                    </div>
                  </NavLink>
                  <NavLink
                    className="btn add-in-cart-btn w-100 add_to_cart  p-1 btn-sm quickview-trigger"
                    to="/products/details"
                    data-product-id={156}
                    data-product-variant-id=""
                    data-product-title="Toor Dal"
                    data-product-image="https://eshopweb.store/uploads/media/2020/a18.jpg"
                    data-product-price={189}
                    data-min={6}
                    data-max={11}
                    data-type="variable_product"
                    data-step={5}
                    data-product-description="Laxmi Polished"
                    data-bs-toggle="modal"
                    data-bs-target="#quickview"
                  >
                    <span className="add-in-cart">Add to Cart</span>
                    <span className="add-in-cart-icon">
                      <svg
                        className="svg-inline--fa fa-cart-shopping color-white"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="cart-shopping"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="currentColor"
                          d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"
                        />
                      </svg>
                      {/* <i class="fa-solid fa-cart-shopping color-white"></i> Font Awesome fontawesome.com */}
                    </span>
                  </NavLink>
                </div>
                <div className="product-icon-onhover">
                  <div className="product-icon-spacebtw">
                    <div className="shuffle-box">
                      <NavLink
                        className="compare text-reset text-decoration-none shuffle"
                        data-tip="Compare"
                        data-product-id={156}
                        data-product-variant-id=""
                      >
                        <ion-icon
                          name="shuffle-outline"
                          className="ion-icon-hover pointer shuffle md hydrated"
                          role="img"
                        />
                      </NavLink>
                    </div>
                  </div>
                  <div className="product-icon-spacebtw">
                    <div
                      className="quick-search-box quickview-trigger"
                      data-tip="Quick View"
                      data-product-id={156}
                      data-product-variant-id={325}
                      data-izimodal-open="#quickview"
                    >
                      <ion-icon
                        name="search-outline"
                        className="ion-icon-hover pointer md hydrated"
                        data-bs-toggle="modal"
                        data-bs-target="#quickview"
                        role="img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 grid-card-container product-card  col-md-4 ">
              <div
                className="card grid-view-card product-card"
                data-product-id={160}
              >
                <div className="rating-div">
                  <div className="product-icon-spacebtw">
                    <div
                      className="add-to-fav-btn"
                      title="like"
                      data-product-id={160}
                    >
                      <ion-icon
                        className="ion-icon ion-icon-hover heart-outline text-dark md hydrated"
                        name="heart-outline"
                        role="img"
                      ></ion-icon>
                    </div>
                  </div>
                </div>
                <NavLink
                  to="/products/details"
                  className="text-reset text-decoration-none"
                >
                  <div>
                    <div className="img-box-h250">
                      <link
                        itemProp="image"
                        href="https://eshopweb.store/uploads/media/2020/thumb-sm/a87.jpg"
                      />
                      <img
                        className="pic-1 lazy entered loading"
                        alt="Unpolished Urad Dal"
                        title="Unpolished Urad Dal"
                        src="https://eshopweb.store/uploads/media/2020/thumb-sm/a87.jpg"
                        data-ll-status="loading"
                      />
                    </div>
                  </div>
                </NavLink>
                <div className="card-body">
                  <NavLink
                    to="/products/details"
                    className="text-reset text-decoration-none"
                  >
                    <h5 className="brand-title" itemProp="name" />
                    <h5 className="card-title" itemProp="name">
                      Unpolished Urad Dal
                    </h5>
                    {/* <p class="card-text">17.27 cm (6.8 inch) Quad HD+ Display, 200MP + 10MP + 12MP +
                                10MP | 12MP Front Camera</p> */}
                    <div className="rating-container theme-krajee-svg rating-xs rating-animate rating-disabled">
                      <div className="rating-stars" tabIndex={0}>
                        <span className="empty-stars">
                          <span className="star" title="One Star">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Two Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Three Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Four Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Five Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                        </span>
                        <span className="filled-stars" style={{ width: "70%" }}>
                          <span className="star" title="One Star">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Two Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Three Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Four Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Five Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                        </span>
                      </div>
                    </div>
                    <input
                      id="input-3-ltr-star-md"
                      name="input-3-ltr-star-md"
                      className="kv-ltr-theme-svg-star rating-input"
                      defaultValue="3.50"
                      dir="ltr"
                      data-size="xs"
                      data-show-clear="false"
                      data-show-caption="false"
                      readOnly="readonly"
                      tabIndex={-1}
                    />
                    <div className="d-flex align-items-center gap-2">
                      <h4
                        className="card-price"
                        itemProp="offers"
                        itemType="https://schema.org/Offer"
                      >
                        <meta itemProp="price" content={200} />
                        $178 <meta itemProp="priceCurrency" content="$" />
                      </h4>
                      <p className="m-0 offer_price">
                        <small>
                          <span
                            className="text-decoration-line-through fw-bold"
                            itemProp="price"
                          >
                            $200{" "}
                          </span>
                          <span className="text-success fw-bold">
                            <span className="product-discount-label fw-bold">
                              20% off
                            </span>
                          </span>
                        </small>
                      </p>
                    </div>
                  </NavLink>
                  <NavLink
                    className="btn add-in-cart-btn w-100 add_to_cart  p-1 btn-sm quickview-trigger"
                    to=""
                    data-product-id={160}
                    data-product-variant-id=""
                    data-product-title="Unpolished Urad Dal"
                    data-product-image="https://eshopweb.store/uploads/media/2020/a87.jpg"
                    data-product-price={178}
                    data-min={1}
                    data-max={20}
                    data-type="variable_product"
                    data-step={1}
                    data-product-description="Tata Sampann"
                    data-bs-toggle="modal"
                    data-bs-target="#quickview"
                  >
                    <span className="add-in-cart">Add to Cart</span>
                    <span className="add-in-cart-icon">
                      <svg
                        className="svg-inline--fa fa-cart-shopping color-white"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="cart-shopping"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="currentColor"
                          d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"
                        />
                      </svg>
                      {/* <i class="fa-solid fa-cart-shopping color-white"></i> Font Awesome fontawesome.com */}
                    </span>
                  </NavLink>
                </div>
                <div className="product-icon-onhover">
                  <div className="product-icon-spacebtw">
                    <div className="shuffle-box">
                      <NavLink
                        className="compare text-reset text-decoration-none shuffle"
                        data-tip="Compare"
                        data-product-id={160}
                        data-product-variant-id=""
                      >
                        <ion-icon
                          name="shuffle-outline"
                          className="ion-icon-hover pointer shuffle md hydrated"
                          role="img"
                        />
                      </NavLink>
                    </div>
                  </div>
                  <div className="product-icon-spacebtw">
                    <div
                      className="quick-search-box quickview-trigger"
                      data-tip="Quick View"
                      data-product-id={160}
                      data-product-variant-id={333}
                      data-izimodal-open="#quickview"
                    >
                      <ion-icon
                        name="search-outline"
                        className="ion-icon-hover pointer md hydrated"
                        data-bs-toggle="modal"
                        data-bs-target="#quickview"
                        role="img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 grid-card-container product-card  col-md-4 ">
              <div
                className="card grid-view-card product-card"
                data-product-id={72}
              >
                <div className="rating-div">
                  <div className="product-icon-spacebtw">
                    <div
                      className="add-to-fav-btn"
                      title="like"
                      data-product-id={72}
                    >
                      <ion-icon
                        className="ion-icon ion-icon-hover heart-outline text-dark md hydrated"
                        name="heart-outline"
                        role="img"
                      ></ion-icon>
                    </div>
                  </div>
                </div>
                <NavLink
                  to="/products/details"
                  className="text-reset text-decoration-none"
                >
                  <div>
                    <div className="img-box-h250">
                      <link
                        itemProp="image"
                        href="https://eshopweb.store/uploads/media/2023/thumb-sm/mxw_3840,f_auto@1x_1-1.png"
                      />
                      <img
                        className="pic-1 lazy entered exited"
                        alt="SAMSUNG Galaxy S23 Ultra 5G (12GB RAM, 256GB, Cream)"
                        title="SAMSUNG Galaxy S23 Ultra 5G (12GB RAM, 256GB, Cream)"
                        src="https://eshopweb.store/uploads/media/2023/thumb-sm/mxw_3840,f_auto@1x_1-1.png"
                      />
                    </div>
                  </div>
                </NavLink>
                <div className="card-body">
                  <NavLink
                    to="/products/details"
                    className="text-reset text-decoration-none"
                  >
                    <h5 className="brand-title" itemProp="name">
                      Samsung
                    </h5>
                    <h5 className="card-title" itemProp="name">
                      SAMSUNG Galaxy S23 Ultra 5G (12GB RAM, 256GB, Cream)
                    </h5>
                    {/* <p class="card-text">17.27 cm (6.8 inch) Quad HD+ Display, 200MP + 10MP + 12MP +
                                10MP | 12MP Front Camera</p> */}
                    <div className="rating-container theme-krajee-svg rating-xs rating-animate rating-disabled">
                      <div className="rating-stars" tabIndex={0}>
                        <span className="empty-stars">
                          <span className="star" title="One Star">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Two Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Three Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Four Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Five Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                        </span>
                        <span className="filled-stars" style={{ width: "88%" }}>
                          <span className="star" title="One Star">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Two Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Three Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Four Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Five Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                        </span>
                      </div>
                    </div>
                    <input
                      id="input-3-ltr-star-md"
                      name="input-3-ltr-star-md"
                      className="kv-ltr-theme-svg-star rating-input"
                      defaultValue="4.40"
                      dir="ltr"
                      data-size="xs"
                      data-show-clear="false"
                      data-show-caption="false"
                      readOnly="readonly"
                      tabIndex={-1}
                    />
                    <div className="d-flex align-items-center gap-2">
                      <h4
                        className="card-price"
                        itemProp="offers"
                        itemType="https://schema.org/Offer"
                      >
                        <meta itemProp="price" content={149999} />
                        $124,999 <meta itemProp="priceCurrency" content="$" />
                      </h4>
                      <p className="m-0 offer_price">
                        <small>
                          <span
                            className="text-decoration-line-through fw-bold"
                            itemProp="price"
                          >
                            $149,999{" "}
                          </span>
                          <span className="text-success fw-bold">
                            <span className="product-discount-label fw-bold">
                              16% off
                            </span>
                          </span>
                        </small>
                      </p>
                    </div>
                  </NavLink>
                  <NavLink
                    className="btn add-in-cart-btn w-100 add_to_cart  p-1 btn-sm quickview-trigger"
                    to=""
                    data-product-id={72}
                    data-product-variant-id=""
                    data-product-title="SAMSUNG Galaxy S23 Ultra 5G (12GB RAM, 256GB, Cream)"
                    data-product-image="https://eshopweb.store/uploads/media/2023/mxw_3840,f_auto@1x_1-1.png"
                    data-product-price={124999}
                    data-min={1}
                    data-max={5}
                    data-type="variable_product"
                    data-step={1}
                    data-product-description="Meet the new Galaxy S23 Ultra, designed with the planet in mind and equipped with a built-in S Pen, Nightography camera and powerful chip for epic gaming."
                    data-bs-toggle="modal"
                    data-bs-target="#quickview"
                  >
                    <span className="add-in-cart">Add to Cart</span>
                    <span className="add-in-cart-icon">
                      <svg
                        className="svg-inline--fa fa-cart-shopping color-white"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="cart-shopping"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="currentColor"
                          d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"
                        />
                      </svg>
                      {/* <i class="fa-solid fa-cart-shopping color-white"></i> Font Awesome fontawesome.com */}
                    </span>
                  </NavLink>
                </div>
                <div className="product-icon-onhover">
                  <div className="product-icon-spacebtw">
                    <div className="shuffle-box">
                      <NavLink
                        className="compare text-reset text-decoration-none shuffle"
                        data-tip="Compare"
                        data-product-id={72}
                        data-product-variant-id=""
                      >
                        <ion-icon
                          name="shuffle-outline"
                          className="ion-icon-hover pointer shuffle md hydrated"
                          role="img"
                        />
                      </NavLink>
                    </div>
                  </div>
                  <div className="product-icon-spacebtw">
                    <div
                      className="quick-search-box quickview-trigger"
                      data-tip="Quick View"
                      data-product-id={72}
                      data-product-variant-id={1109}
                      data-izimodal-open="#quickview"
                    >
                      <ion-icon
                        name="search-outline"
                        className="ion-icon-hover pointer md hydrated"
                        data-bs-toggle="modal"
                        data-bs-target="#quickview"
                        role="img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 grid-card-container product-card  col-md-4 ">
              <div
                className="card grid-view-card product-card"
                data-product-id={64}
              >
                <div className="rating-div">
                  <div className="product-icon-spacebtw">
                    <div
                      className="add-to-fav-btn"
                      title="like"
                      data-product-id={64}
                    >
                      <ion-icon
                        className="ion-icon ion-icon-hover heart-outline text-dark md hydrated"
                        name="heart-outline"
                        role="img"
                      ></ion-icon>
                    </div>
                  </div>
                </div>
                <NavLink
                  to="/products/details"
                  className="text-reset text-decoration-none"
                >
                  <div>
                    <div className="img-box-h250">
                      <link
                        itemProp="image"
                        href="https://eshopweb.store/uploads/media/2023/thumb-sm/a1d69358e5d13c50b215b75f321da986ac312cc4.jpg"
                      />
                      <img
                        className="pic-1 lazy entered exited"
                        alt="Samsung s23 5G"
                        title="Samsung s23 5G"
                        src="https://eshopweb.store/uploads/media/2023/thumb-sm/a1d69358e5d13c50b215b75f321da986ac312cc4.jpg"
                      />
                    </div>
                  </div>
                </NavLink>
                <div className="card-body">
                  <NavLink
                    to="/products/details"
                    className="text-reset text-decoration-none"
                  >
                    <h5 className="brand-title" itemProp="name">
                      Samsung
                    </h5>
                    <h5 className="card-title" itemProp="name">
                      Samsung s23 5G
                    </h5>
                    {/* <p class="card-text">17.27 cm (6.8 inch) Quad HD+ Display, 200MP + 10MP + 12MP +
                                10MP | 12MP Front Camera</p> */}
                    <div className="rating-container theme-krajee-svg rating-xs rating-animate rating-disabled">
                      <div className="rating-stars" tabIndex={0}>
                        <span className="empty-stars">
                          <span className="star" title="One Star">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Two Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Three Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Four Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Five Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                        </span>
                        <span
                          className="filled-stars"
                          style={{ width: "100%" }}
                        >
                          <span className="star" title="One Star">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Two Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Three Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Four Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Five Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                        </span>
                      </div>
                    </div>
                    <input
                      id="input-3-ltr-star-md"
                      name="input-3-ltr-star-md"
                      className="kv-ltr-theme-svg-star rating-input"
                      defaultValue={5.0}
                      dir="ltr"
                      data-size="xs"
                      data-show-clear="false"
                      data-show-caption="false"
                      readOnly="readonly"
                      tabIndex={-1}
                    />
                    <div className="d-flex align-items-center gap-2">
                      <h4
                        className="card-price"
                        itemProp="offers"
                        itemType="https://schema.org/Offer"
                      >
                        <meta itemProp="price" content={11000} />
                        $10,500 <meta itemProp="priceCurrency" content="$" />
                      </h4>
                      <p className="m-0 offer_price">
                        <small>
                          <span
                            className="text-decoration-line-through fw-bold"
                            itemProp="price"
                          >
                            $11,000{" "}
                          </span>
                          <span className="text-success fw-bold">
                            <span className="product-discount-label fw-bold">
                              5% off
                            </span>
                          </span>
                        </small>
                      </p>
                    </div>
                  </NavLink>
                  <NavLink
                    className="btn add-in-cart-btn w-100 add_to_cart  p-1 btn-sm quickview-trigger"
                    to=""
                    data-product-id={64}
                    data-product-variant-id=""
                    data-product-title="Samsung s23 5G"
                    data-product-image="https://eshopweb.store/uploads/media/2023/a1d69358e5d13c50b215b75f321da986ac312cc4.jpg"
                    data-product-price={10500}
                    data-min={1}
                    data-max={5}
                    data-type="variable_product"
                    data-step={1}
                    data-product-description="Display 6.5 inches (16.51 cm)270 PPI, IPS LCD60 Hz Refresh Rate"
                    data-bs-toggle="modal"
                    data-bs-target="#quickview"
                  >
                    <span className="add-in-cart">Add to Cart</span>
                    <span className="add-in-cart-icon">
                      <svg
                        className="svg-inline--fa fa-cart-shopping color-white"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="cart-shopping"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="currentColor"
                          d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"
                        />
                      </svg>
                      {/* <i class="fa-solid fa-cart-shopping color-white"></i> Font Awesome fontawesome.com */}
                    </span>
                  </NavLink>
                </div>
                <div className="product-icon-onhover">
                  <div className="product-icon-spacebtw">
                    <div className="shuffle-box">
                      <NavLink
                        className="compare text-reset text-decoration-none shuffle"
                        data-tip="Compare"
                        data-product-id={64}
                        data-product-variant-id=""
                      >
                        <ion-icon
                          name="shuffle-outline"
                          className="ion-icon-hover pointer shuffle md hydrated"
                          role="img"
                        />
                      </NavLink>
                    </div>
                  </div>
                  <div className="product-icon-spacebtw">
                    <div
                      className="quick-search-box quickview-trigger"
                      data-tip="Quick View"
                      data-product-id={64}
                      data-product-variant-id={118}
                      data-izimodal-open="#quickview"
                    >
                      <ion-icon
                        name="search-outline"
                        className="ion-icon-hover pointer md hydrated"
                        data-bs-toggle="modal"
                        data-bs-target="#quickview"
                        role="img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 grid-card-container product-card  col-md-4 ">
              <div
                className="card grid-view-card product-card"
                data-product-id={68}
              >
                <div className="rating-div">
                  <div className="product-icon-spacebtw">
                    <div
                      className="add-to-fav-btn"
                      title="like"
                      data-product-id={68}
                    >
                      <ion-icon
                        className="ion-icon ion-icon-hover heart-outline text-dark md hydrated"
                        name="heart-outline"
                        role="img"
                      ></ion-icon>
                    </div>
                  </div>
                </div>
                <NavLink
                  to="/products/details"
                  className="text-reset text-decoration-none"
                >
                  <div>
                    <div className="img-box-h250">
                      <link
                        itemProp="image"
                        href="https://eshopweb.store/uploads/media/2023/thumb-sm/eig4OrX041cn85I2.jpg"
                      />
                      <img
                        className="pic-1 lazy entered exited"
                        alt="Oneplus Nord"
                        title="Oneplus Nord"
                        src="https://eshopweb.store/uploads/media/2023/thumb-sm/eig4OrX041cn85I2.jpg"
                      />
                    </div>
                  </div>
                </NavLink>
                <div className="card-body">
                  <NavLink
                    to="/products/details"
                    className="text-reset text-decoration-none"
                  >
                    <h5 className="brand-title" itemProp="name" />
                    <h5 className="card-title" itemProp="name">
                      Oneplus Nord
                    </h5>
                    {/* <p class="card-text">17.27 cm (6.8 inch) Quad HD+ Display, 200MP + 10MP + 12MP +
                                10MP | 12MP Front Camera</p> */}
                    <div className="rating-container theme-krajee-svg rating-xs rating-animate rating-disabled">
                      <div className="rating-stars" tabIndex={0}>
                        <span className="empty-stars">
                          <span className="star" title="One Star">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Two Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Three Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Four Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Five Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                        </span>
                        <span className="filled-stars" style={{ width: "90%" }}>
                          <span className="star" title="One Star">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Two Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Three Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Four Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Five Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                        </span>
                      </div>
                    </div>
                    <input
                      id="input-3-ltr-star-md"
                      name="input-3-ltr-star-md"
                      className="kv-ltr-theme-svg-star rating-input"
                      defaultValue="4.50"
                      dir="ltr"
                      data-size="xs"
                      data-show-clear="false"
                      data-show-caption="false"
                      readOnly="readonly"
                      tabIndex={-1}
                    />
                    <div className="d-flex align-items-center gap-2">
                      <h4
                        className="card-price"
                        itemProp="offers"
                        itemType="https://schema.org/Offer"
                      >
                        <meta itemProp="price" content={27999} />
                        $27,000 <meta itemProp="priceCurrency" content="$" />
                      </h4>
                      <p className="m-0 offer_price">
                        <small>
                          <span
                            className="text-decoration-line-through fw-bold"
                            itemProp="price"
                          >
                            $27,999{" "}
                          </span>
                          <span className="text-success fw-bold">
                            <span className="product-discount-label fw-bold">
                              4% off
                            </span>
                          </span>
                        </small>
                      </p>
                    </div>
                  </NavLink>
                  <NavLink
                    className="btn add-in-cart-btn w-100 add_to_cart  p-1 btn-sm quickview-trigger"
                    to=""
                    data-product-id={68}
                    data-product-variant-id=""
                    data-product-title="Oneplus Nord"
                    data-product-image="https://eshopweb.store/uploads/media/2023/eig4OrX041cn85I2.jpg"
                    data-product-price={27000}
                    data-min={1}
                    data-max={5}
                    data-type="variable_product"
                    data-step={1}
                    data-product-description="6.44″ display,  OxygenOS, 90 Hz AMOLED display"
                    data-bs-toggle="modal"
                    data-bs-target="#quickview"
                  >
                    <span className="add-in-cart">Add to Cart</span>
                    <span className="add-in-cart-icon">
                      <svg
                        className="svg-inline--fa fa-cart-shopping color-white"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="cart-shopping"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="currentColor"
                          d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"
                        />
                      </svg>
                      {/* <i class="fa-solid fa-cart-shopping color-white"></i> Font Awesome fontawesome.com */}
                    </span>
                  </NavLink>
                </div>
                <div className="product-icon-onhover">
                  <div className="product-icon-spacebtw">
                    <div className="shuffle-box">
                      <NavLink
                        className="compare text-reset text-decoration-none shuffle"
                        data-tip="Compare"
                        data-product-id={68}
                        data-product-variant-id=""
                      >
                        <ion-icon
                          name="shuffle-outline"
                          className="ion-icon-hover pointer shuffle md hydrated"
                          role="img"
                        />
                      </NavLink>
                    </div>
                  </div>
                  <div className="product-icon-spacebtw">
                    <div
                      className="quick-search-box quickview-trigger"
                      data-tip="Quick View"
                      data-product-id={68}
                      data-product-variant-id={128}
                      data-izimodal-open="#quickview"
                    >
                      <ion-icon
                        name="search-outline"
                        className="ion-icon-hover pointer md hydrated"
                        data-bs-toggle="modal"
                        data-bs-target="#quickview"
                        role="img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 grid-card-container product-card  col-md-4 ">
              <div
                className="card grid-view-card product-card"
                data-product-id={69}
              >
                <div className="rating-div">
                  <div className="product-icon-spacebtw">
                    <div
                      className="add-to-fav-btn"
                      title="like"
                      data-product-id={69}
                    >
                      <ion-icon
                        className="ion-icon ion-icon-hover heart-outline text-dark md hydrated"
                        name="heart-outline"
                        role="img"
                      ></ion-icon>
                    </div>
                  </div>
                </div>
                <NavLink
                  to="/products/details"
                  className="text-reset text-decoration-none"
                >
                  <div>
                    <div className="img-box-h250">
                      <link
                        itemProp="image"
                        href="https://eshopweb.store/uploads/media/2023/thumb-sm/pzcVlrD6SlHhTuOg.png"
                      />
                      <img
                        className="pic-1 lazy entered exited"
                        alt="One plus 11 Pro 5G"
                        title="One plus 11 Pro 5G"
                        src="https://eshopweb.store/uploads/media/2023/thumb-sm/pzcVlrD6SlHhTuOg.png"
                      />
                    </div>
                  </div>
                </NavLink>
                <div className="card-body">
                  <NavLink
                    to="/products/details"
                    className="text-reset text-decoration-none"
                  >
                    <h5 className="brand-title" itemProp="name">
                      One plus
                    </h5>
                    <h5 className="card-title" itemProp="name">
                      One plus 11 Pro 5G
                    </h5>
                    {/* <p class="card-text">17.27 cm (6.8 inch) Quad HD+ Display, 200MP + 10MP + 12MP +
                                10MP | 12MP Front Camera</p> */}
                    <div className="rating-container theme-krajee-svg rating-xs rating-animate rating-disabled">
                      <div className="rating-stars" tabIndex={0}>
                        <span className="empty-stars">
                          <span className="star" title="One Star">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Two Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Three Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Four Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Five Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                        </span>
                        <span className="filled-stars" style={{ width: "96%" }}>
                          <span className="star" title="One Star">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Two Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Three Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Four Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Five Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                        </span>
                      </div>
                    </div>
                    <input
                      id="input-3-ltr-star-md"
                      name="input-3-ltr-star-md"
                      className="kv-ltr-theme-svg-star rating-input"
                      defaultValue="4.80"
                      dir="ltr"
                      data-size="xs"
                      data-show-clear="false"
                      data-show-caption="false"
                      readOnly="readonly"
                      tabIndex={-1}
                    />
                    <div className="d-flex align-items-center gap-2">
                      <h4
                        className="card-price"
                        itemProp="offers"
                        itemType="https://schema.org/Offer"
                      >
                        <meta itemProp="price" content={56999} />
                        $0 <meta itemProp="priceCurrency" content="$" />
                      </h4>
                      <span itemProp="price">$17,500</span>
                    </div>
                  </NavLink>
                  <NavLink
                    className="btn add-in-cart-btn w-100 add_to_cart  p-1 btn-sm quickview-trigger"
                    to=""
                    data-product-id={69}
                    data-product-variant-id=""
                    data-product-title="One plus 11 Pro 5G"
                    data-product-image="https://eshopweb.store/uploads/media/2023/pzcVlrD6SlHhTuOg.png"
                    data-product-price={0}
                    data-min={1}
                    data-max={5}
                    data-type="variable_product"
                    data-step={1}
                    data-product-description="8 GB RAM+128 GB Storage: (Eternal Green/Titan Black)
  
  16 GB RAM+256 GB Storage: (Eternal Green/Titan Black)
  
  16 GB RAM+256 GB Storage: (Marble Odyssey)"
                    data-bs-toggle="modal"
                    data-bs-target="#quickview"
                  >
                    <span className="add-in-cart">Add to Cart</span>
                    <span className="add-in-cart-icon">
                      <svg
                        className="svg-inline--fa fa-cart-shopping color-white"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="cart-shopping"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="currentColor"
                          d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"
                        />
                      </svg>
                      {/* <i class="fa-solid fa-cart-shopping color-white"></i> Font Awesome fontawesome.com */}
                    </span>
                  </NavLink>
                </div>
                <div className="product-icon-onhover">
                  <div className="product-icon-spacebtw">
                    <div className="shuffle-box">
                      <NavLink
                        className="compare text-reset text-decoration-none shuffle"
                        data-tip="Compare"
                        data-product-id={69}
                        data-product-variant-id=""
                      >
                        <ion-icon
                          name="shuffle-outline"
                          className="ion-icon-hover pointer shuffle md hydrated"
                          role="img"
                        />
                      </NavLink>
                    </div>
                  </div>
                  <div className="product-icon-spacebtw">
                    <div
                      className="quick-search-box quickview-trigger"
                      data-tip="Quick View"
                      data-product-id={69}
                      data-product-variant-id={1132}
                      data-izimodal-open="#quickview"
                    >
                      <ion-icon
                        name="search-outline"
                        className="ion-icon-hover pointer md hydrated"
                        data-bs-toggle="modal"
                        data-bs-target="#quickview"
                        role="img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 grid-card-container product-card  col-md-4 ">
              <div
                className="card grid-view-card product-card"
                data-product-id={74}
              >
                <div className="rating-div">
                  <div className="product-icon-spacebtw">
                    <div
                      className="add-to-fav-btn"
                      title="like"
                      data-product-id={74}
                    >
                      <ion-icon
                        className="ion-icon ion-icon-hover heart-outline text-dark md hydrated"
                        name="heart-outline"
                        role="img"
                      ></ion-icon>
                    </div>
                  </div>
                </div>
                <NavLink
                  to="/products/details"
                  className="text-reset text-decoration-none"
                >
                  <div>
                    <div className="img-box-h250">
                      <link
                        itemProp="image"
                        href="https://eshopweb.store/uploads/media/2020/thumb-sm/apple-mned2hn-a-original-imaevdwvynk7vmdc.jpg"
                      />
                      <img
                        className="pic-1 lazy entered exited"
                        alt="Apple Imac"
                        title="Apple Imac"
                        src="https://eshopweb.store/uploads/media/2020/thumb-sm/apple-mned2hn-a-original-imaevdwvynk7vmdc.jpg"
                      />
                    </div>
                  </div>
                </NavLink>
                <div className="card-body">
                  <NavLink
                    to="/products/details"
                    className="text-reset text-decoration-none"
                  >
                    <h5 className="brand-title" itemProp="name" />
                    <h5 className="card-title" itemProp="name">
                      Apple Imac
                    </h5>
                    {/* <p class="card-text">17.27 cm (6.8 inch) Quad HD+ Display, 200MP + 10MP + 12MP +
                                10MP | 12MP Front Camera</p> */}
                    <div className="rating-container theme-krajee-svg rating-xs rating-animate rating-disabled">
                      <div className="rating-stars" tabIndex={0}>
                        <span className="empty-stars">
                          <span className="star" title="One Star">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Two Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Three Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Four Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Five Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                        </span>
                        <span
                          className="filled-stars"
                          style={{ width: "100%" }}
                        >
                          <span className="star" title="One Star">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Two Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Three Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Four Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Five Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                        </span>
                      </div>
                    </div>
                    <input
                      id="input-3-ltr-star-md"
                      name="input-3-ltr-star-md"
                      className="kv-ltr-theme-svg-star rating-input"
                      defaultValue={5.0}
                      dir="ltr"
                      data-size="xs"
                      data-show-clear="false"
                      data-show-caption="false"
                      readOnly="readonly"
                      tabIndex={-1}
                    />
                    <div className="d-flex align-items-center gap-2">
                      <h4
                        className="card-price"
                        itemProp="offers"
                        itemType="https://schema.org/Offer"
                      >
                        <meta itemProp="price" content={169000} />
                        $156,000 <meta itemProp="priceCurrency" content="$" />
                      </h4>
                      <p className="m-0 offer_price">
                        <small>
                          <span
                            className="text-decoration-line-through fw-bold"
                            itemProp="price"
                          >
                            $169,000{" "}
                          </span>
                          <span className="text-success fw-bold">
                            <span className="product-discount-label fw-bold">
                              7% off
                            </span>
                          </span>
                        </small>
                      </p>
                    </div>
                  </NavLink>
                  <NavLink
                    className="btn add-in-cart-btn w-100 add_to_cart  p-1 btn-sm "
                    to="/products/details"
                    data-product-id={74}
                    data-product-variant-id={139}
                    data-product-title="Apple Imac"
                    data-product-image="https://eshopweb.store/uploads/media/2020/apple-mned2hn-a-original-imaevdwvynk7vmdc.jpg"
                    data-product-price={156000}
                    data-min={1}
                    data-max={5}
                    data-type="variable_product"
                    data-step={1}
                    data-product-description="21.5-inch, 2.3 GHz Dual-core Intel Core i5, 8GB RAM"
                    data-bs-toggle="modal"
                    data-bs-target=""
                  >
                    <span className="add-in-cart">Add to Cart</span>
                    <span className="add-in-cart-icon">
                      <svg
                        className="svg-inline--fa fa-cart-shopping color-white"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="cart-shopping"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="currentColor"
                          d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"
                        />
                      </svg>
                      {/* <i class="fa-solid fa-cart-shopping color-white"></i> Font Awesome fontawesome.com */}
                    </span>
                  </NavLink>
                </div>
                <div className="product-icon-onhover">
                  <div className="product-icon-spacebtw">
                    <div className="shuffle-box">
                      <NavLink
                        className="compare text-reset text-decoration-none shuffle"
                        data-tip="Compare"
                        data-product-id={74}
                        data-product-variant-id={139}
                      >
                        <ion-icon
                          name="shuffle-outline"
                          className="ion-icon-hover pointer shuffle md hydrated"
                          role="img"
                        />
                      </NavLink>
                    </div>
                  </div>
                  <div className="product-icon-spacebtw">
                    <div
                      className="quick-search-box quickview-trigger"
                      data-tip="Quick View"
                      data-product-id={74}
                      data-product-variant-id={139}
                      data-izimodal-open="#quickview"
                    >
                      <ion-icon
                        name="search-outline"
                        className="ion-icon-hover pointer md hydrated"
                        data-bs-toggle="modal"
                        data-bs-target="#quickview"
                        role="img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 grid-card-container product-card  col-md-4 ">
              <div
                className="card grid-view-card product-card"
                data-product-id={78}
              >
                <div className="rating-div">
                  <div className="product-icon-spacebtw">
                    <div
                      className="add-to-fav-btn"
                      title="like"
                      data-product-id={78}
                    >
                      <ion-icon
                        className="ion-icon ion-icon-hover heart-outline text-dark md hydrated"
                        name="heart-outline"
                        role="img"
                      ></ion-icon>
                    </div>
                  </div>
                </div>
                <NavLink
                  to="/products/details"
                  className="text-reset text-decoration-none"
                >
                  <div>
                    <div className="img-box-h250">
                      <link
                        itemProp="image"
                        href="https://eshopweb.store/uploads/media/2020/thumb-sm/lenovo-ideacentre-a340-22iwl-original-imafvgxzhszgupcw.jpg"
                      />
                      <img
                        className="pic-1 lazy entered exited"
                        alt="Lenovo Idea Centre APU Dual Core"
                        title="Lenovo Idea Centre APU Dual Core"
                        src="https://eshopweb.store/uploads/media/2020/thumb-sm/lenovo-ideacentre-a340-22iwl-original-imafvgxzhszgupcw.jpg"
                      />
                    </div>
                  </div>
                </NavLink>
                <div className="card-body">
                  <NavLink
                    to="/products/details"
                    className="text-reset text-decoration-none"
                  >
                    <h5 className="brand-title" itemProp="name">
                      Lenovo
                    </h5>
                    <h5 className="card-title" itemProp="name">
                      Lenovo Idea Centre APU Dual Core
                    </h5>
                    {/* <p class="card-text">17.27 cm (6.8 inch) Quad HD+ Display, 200MP + 10MP + 12MP +
                                10MP | 12MP Front Camera</p> */}
                    <div className="rating-container theme-krajee-svg rating-xs rating-animate rating-disabled">
                      <div className="rating-stars" tabIndex={0}>
                        <span className="empty-stars">
                          <span className="star" title="One Star">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Two Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Three Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Four Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Five Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                        </span>
                        <span
                          className="filled-stars"
                          style={{ width: "100%" }}
                        >
                          <span className="star" title="One Star">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Two Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Three Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Four Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Five Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                        </span>
                      </div>
                    </div>
                    <input
                      id="input-3-ltr-star-md"
                      name="input-3-ltr-star-md"
                      className="kv-ltr-theme-svg-star rating-input"
                      defaultValue={5.0}
                      dir="ltr"
                      data-size="xs"
                      data-show-clear="false"
                      data-show-caption="false"
                      readOnly="readonly"
                      tabIndex={-1}
                    />
                    <div className="d-flex align-items-center gap-2">
                      <h4
                        className="card-price"
                        itemProp="offers"
                        itemType="https://schema.org/Offer"
                      >
                        <meta itemProp="price" content={36000} />
                        $33,800 <meta itemProp="priceCurrency" content="$" />
                      </h4>
                      <p className="m-0 offer_price">
                        <small>
                          <span
                            className="text-decoration-line-through fw-bold"
                            itemProp="price"
                          >
                            $36,000{" "}
                          </span>
                          <span className="text-success fw-bold">
                            <span className="product-discount-label fw-bold">
                              6% off
                            </span>
                          </span>
                        </small>
                      </p>
                    </div>
                  </NavLink>
                  <NavLink
                    className="btn add-in-cart-btn w-100 add_to_cart  p-1 btn-sm quickview-trigger"
                    to=""
                    data-product-id={78}
                    data-product-variant-id=""
                    data-product-title="Lenovo Idea Centre APU Dual Core"
                    data-product-image="https://eshopweb.store/uploads/media/2020/lenovo-ideacentre-a340-22iwl-original-imafvgxzhszgupcw.jpg"
                    data-product-price={33800}
                    data-min={1}
                    data-max={5}
                    data-type="variable_product"
                    data-step={1}
                    data-product-description="8 GB DDR4/1 TB/Windows 10 Home"
                    data-bs-toggle="modal"
                    data-bs-target="#quickview"
                  >
                    <span className="add-in-cart">Add to Cart</span>
                    <span className="add-in-cart-icon">
                      <svg
                        className="svg-inline--fa fa-cart-shopping color-white"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="cart-shopping"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="currentColor"
                          d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"
                        />
                      </svg>
                      {/* <i class="fa-solid fa-cart-shopping color-white"></i> Font Awesome fontawesome.com */}
                    </span>
                  </NavLink>
                </div>
                <div className="product-icon-onhover">
                  <div className="product-icon-spacebtw">
                    <div className="shuffle-box">
                      <NavLink
                        className="compare text-reset text-decoration-none shuffle"
                        data-tip="Compare"
                        data-product-id={78}
                        data-product-variant-id=""
                      >
                        <ion-icon
                          name="shuffle-outline"
                          className="ion-icon-hover pointer shuffle md hydrated"
                          role="img"
                        />
                      </NavLink>
                    </div>
                  </div>
                  <div className="product-icon-spacebtw">
                    <div
                      className="quick-search-box quickview-trigger"
                      data-tip="Quick View"
                      data-product-id={78}
                      data-product-variant-id={144}
                      data-izimodal-open="#quickview"
                    >
                      <ion-icon
                        name="search-outline"
                        className="ion-icon-hover pointer md hydrated"
                        data-bs-toggle="modal"
                        data-bs-target="#quickview"
                        role="img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 grid-card-container product-card  col-md-4 ">
              <div
                className="card grid-view-card product-card"
                data-product-id={81}
              >
                <div className="rating-div">
                  <div className="product-icon-spacebtw">
                    <div
                      className="add-to-fav-btn"
                      title="like"
                      data-product-id={81}
                    >
                      <ion-icon
                        className="ion-icon ion-icon-hover heart-outline text-dark md hydrated"
                        name="heart-outline"
                        role="img"
                      ></ion-icon>
                    </div>
                  </div>
                </div>
                <NavLink
                  to="/products/details"
                  className="text-reset text-decoration-none"
                >
                  <div>
                    <div className="img-box-h250">
                      <link
                        itemProp="image"
                        href="https://eshopweb.store/uploads/media/2020/thumb-sm/dask1-min.jpeg"
                      />
                      <img
                        className="pic-1 lazy entered exited"
                        alt="HP All in One PC Core i3 (9th Gen)"
                        title="HP All in One PC Core i3 (9th Gen)"
                        src="https://eshopweb.store/uploads/media/2020/thumb-sm/dask1-min.jpeg"
                      />
                    </div>
                  </div>
                </NavLink>
                <div className="card-body">
                  <NavLink
                    to="/products/details"
                    className="text-reset text-decoration-none"
                  >
                    <h5 className="brand-title" itemProp="name">
                      HP
                    </h5>
                    <h5 className="card-title" itemProp="name">
                      HP All in One PC Core i3 (9th Gen)
                    </h5>
                    {/* <p class="card-text">17.27 cm (6.8 inch) Quad HD+ Display, 200MP + 10MP + 12MP +
                                10MP | 12MP Front Camera</p> */}
                    <div className="rating-container theme-krajee-svg rating-xs rating-animate rating-disabled">
                      <div className="rating-stars" tabIndex={0}>
                        <span className="empty-stars">
                          <span className="star" title="One Star">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Two Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Three Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Four Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Five Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                        </span>
                        <span className="filled-stars" style={{ width: "96%" }}>
                          <span className="star" title="One Star">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Two Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Three Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Four Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Five Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                        </span>
                      </div>
                    </div>
                    <input
                      id="input-3-ltr-star-md"
                      name="input-3-ltr-star-md"
                      className="kv-ltr-theme-svg-star rating-input"
                      defaultValue="4.80"
                      dir="ltr"
                      data-size="xs"
                      data-show-clear="false"
                      data-show-caption="false"
                      readOnly="readonly"
                      tabIndex={-1}
                    />
                    <div className="d-flex align-items-center gap-2">
                      <h4
                        className="card-price"
                        itemProp="offers"
                        itemType="https://schema.org/Offer"
                      >
                        <meta itemProp="price" content={42000} />
                        $39,500 <meta itemProp="priceCurrency" content="$" />
                      </h4>
                      <p className="m-0 offer_price">
                        <small>
                          <span
                            className="text-decoration-line-through fw-bold"
                            itemProp="price"
                          >
                            $42,000{" "}
                          </span>
                          <span className="text-success fw-bold">
                            <span className="product-discount-label fw-bold">
                              5% off
                            </span>
                          </span>
                        </small>
                      </p>
                    </div>
                  </NavLink>
                  <NavLink
                    className="btn add-in-cart-btn w-100 add_to_cart  p-1 btn-sm quickview-trigger"
                    to=""
                    data-product-id={81}
                    data-product-variant-id=""
                    data-product-title="HP All in One PC Core i3 (9th Gen)"
                    data-product-image="https://eshopweb.store/uploads/media/2020/dask1-min.jpeg"
                    data-product-price={39500}
                    data-min={1}
                    data-max={5}
                    data-type="variable_product"
                    data-step={1}
                    data-product-description="4 GB DDR4/1 TB/Windows 10 Home"
                    data-bs-toggle="modal"
                    data-bs-target="#quickview"
                  >
                    <span className="add-in-cart">Add to Cart</span>
                    <span className="add-in-cart-icon">
                      <svg
                        className="svg-inline--fa fa-cart-shopping color-white"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="cart-shopping"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="currentColor"
                          d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"
                        />
                      </svg>
                      {/* <i class="fa-solid fa-cart-shopping color-white"></i> Font Awesome fontawesome.com */}
                    </span>
                  </NavLink>
                </div>
                <div className="product-icon-onhover">
                  <div className="product-icon-spacebtw">
                    <div className="shuffle-box">
                      <NavLink
                        className="compare text-reset text-decoration-none shuffle"
                        data-tip="Compare"
                        data-product-id={81}
                        data-product-variant-id=""
                      >
                        <ion-icon
                          name="shuffle-outline"
                          className="ion-icon-hover pointer shuffle md hydrated"
                          role="img"
                        />
                      </NavLink>
                    </div>
                  </div>
                  <div className="product-icon-spacebtw">
                    <div
                      className="quick-search-box quickview-trigger"
                      data-tip="Quick View"
                      data-product-id={81}
                      data-product-variant-id={150}
                      data-izimodal-open="#quickview"
                    >
                      <ion-icon
                        name="search-outline"
                        className="ion-icon-hover pointer md hydrated"
                        data-bs-toggle="modal"
                        data-bs-target="#quickview"
                        role="img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 grid-card-container product-card  col-md-4 ">
              <div
                className="card grid-view-card product-card"
                data-product-id={83}
              >
                <div className="rating-div">
                  <div className="product-icon-spacebtw">
                    <div
                      className="add-to-fav-btn"
                      title="like"
                      data-product-id={83}
                    >
                      <ion-icon
                        className="ion-icon ion-icon-hover heart-outline text-dark md hydrated"
                        name="heart-outline"
                        role="img"
                      ></ion-icon>
                    </div>
                  </div>
                </div>
                <NavLink
                  to="/products/details"
                  className="text-reset text-decoration-none"
                >
                  <div>
                    <div className="img-box-h250">
                      <link
                        itemProp="image"
                        href="https://eshopweb.store/uploads/media/2020/thumb-sm/gl-c292sasx-4-lg-original-imaffnu7n8wareju.jpg"
                      />
                      <img
                        className="pic-1 lazy entered exited"
                        alt="LG 300 L Frost Free Double Door"
                        title="LG 300 L Frost Free Double Door"
                        src="https://eshopweb.store/uploads/media/2020/thumb-sm/gl-c292sasx-4-lg-original-imaffnu7n8wareju.jpg"
                      />
                    </div>
                  </div>
                </NavLink>
                <div className="card-body">
                  <NavLink
                    to="/products/details"
                    className="text-reset text-decoration-none"
                  >
                    <h5 className="brand-title" itemProp="name">
                      LG
                    </h5>
                    <h5 className="card-title" itemProp="name">
                      LG 300 L Frost Free Double Door
                    </h5>
                    {/* <p class="card-text">17.27 cm (6.8 inch) Quad HD+ Display, 200MP + 10MP + 12MP +
                                10MP | 12MP Front Camera</p> */}
                    <div className="rating-container theme-krajee-svg rating-xs rating-animate rating-disabled">
                      <div className="rating-stars" tabIndex={0}>
                        <span className="empty-stars">
                          <span className="star" title="One Star">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Two Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Three Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Four Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Five Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                        </span>
                        <span className="filled-stars" style={{ width: "0%" }}>
                          <span className="star" title="One Star">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Two Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Three Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Four Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Five Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                        </span>
                      </div>
                    </div>
                    <input
                      id="input-3-ltr-star-md"
                      name="input-3-ltr-star-md"
                      className="kv-ltr-theme-svg-star rating-input"
                      defaultValue={0.0}
                      dir="ltr"
                      data-size="xs"
                      data-show-clear="false"
                      data-show-caption="false"
                      readOnly="readonly"
                      tabIndex={-1}
                    />
                    <div className="d-flex align-items-center gap-2">
                      <h4
                        className="card-price"
                        itemProp="offers"
                        itemType="https://schema.org/Offer"
                      >
                        <meta itemProp="price" content={10000} />
                        $7,100 <meta itemProp="priceCurrency" content="$" />
                      </h4>
                      <p className="m-0 offer_price">
                        <small>
                          <span
                            className="text-decoration-line-through fw-bold"
                            itemProp="price"
                          >
                            $10,000{" "}
                          </span>
                          <span className="text-success fw-bold">
                            <span className="product-discount-label fw-bold">
                              29% off
                            </span>
                          </span>
                        </small>
                      </p>
                    </div>
                  </NavLink>
                  <NavLink
                    className="btn add-in-cart-btn w-100 add_to_cart  p-1 btn-sm quickview-trigger"
                    to=""
                    data-product-id={83}
                    data-product-variant-id=""
                    data-product-title="LG 300 L Frost Free Double Door"
                    data-product-image="https://eshopweb.store/uploads/media/2020/gl-c292sasx-4-lg-original-imaffnu7n8wareju.jpg"
                    data-product-price={7100}
                    data-min={1}
                    data-max={5}
                    data-type="variable_product"
                    data-step={1}
                    data-product-description="5 Star Refrigerator"
                    data-bs-toggle="modal"
                    data-bs-target="#quickview"
                  >
                    <span className="add-in-cart">Add to Cart</span>
                    <span className="add-in-cart-icon">
                      <svg
                        className="svg-inline--fa fa-cart-shopping color-white"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="cart-shopping"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="currentColor"
                          d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"
                        />
                      </svg>
                      {/* <i class="fa-solid fa-cart-shopping color-white"></i> Font Awesome fontawesome.com */}
                    </span>
                  </NavLink>
                </div>
                <div className="product-icon-onhover">
                  <div className="product-icon-spacebtw">
                    <div className="shuffle-box">
                      <NavLink
                        className="compare text-reset text-decoration-none shuffle"
                        data-tip="Compare"
                        data-product-id={83}
                        data-product-variant-id=""
                      >
                        <ion-icon
                          name="shuffle-outline"
                          className="ion-icon-hover pointer shuffle md hydrated"
                          role="img"
                        />
                      </NavLink>
                    </div>
                  </div>
                  <div className="product-icon-spacebtw">
                    <div
                      className="quick-search-box quickview-trigger"
                      data-tip="Quick View"
                      data-product-id={83}
                      data-product-variant-id={154}
                      data-izimodal-open="#quickview"
                    >
                      <ion-icon
                        name="search-outline"
                        className="ion-icon-hover pointer md hydrated"
                        data-bs-toggle="modal"
                        data-bs-target="#quickview"
                        role="img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 grid-card-container product-card  col-md-4 ">
              <div
                className="card grid-view-card product-card"
                data-product-id={84}
              >
                <div className="rating-div">
                  <div className="product-icon-spacebtw">
                    <div
                      className="add-to-fav-btn"
                      title="like"
                      data-product-id={84}
                    >
                      <ion-icon
                        className="ion-icon ion-icon-hover heart-outline text-dark md hydrated"
                        name="heart-outline"
                        role="img"
                      ></ion-icon>
                    </div>
                  </div>
                </div>
                <NavLink
                  to="/products/details"
                  className="text-reset text-decoration-none"
                >
                  <div>
                    <div className="img-box-h250">
                      <link
                        itemProp="image"
                        href="https://eshopweb.store/uploads/media/2020/thumb-sm/230-im-pro-5s-prm-inv-sapphire-abyss-5-whirlpool-original-imaftfxdppyhcypf.jpg"
                      />
                      <img
                        className="pic-1 lazy entered exited"
                        alt="Whirpool 200 L"
                        title="Whirpool 200 L"
                        src="https://eshopweb.store/uploads/media/2020/thumb-sm/230-im-pro-5s-prm-inv-sapphire-abyss-5-whirlpool-original-imaftfxdppyhcypf.jpg"
                      />
                    </div>
                  </div>
                </NavLink>
                <div className="card-body">
                  <NavLink
                    to="/products/details"
                    className="text-reset text-decoration-none"
                  >
                    <h5 className="brand-title" itemProp="name" />
                    <h5 className="card-title" itemProp="name">
                      Whirpool 200 L
                    </h5>
                    {/* <p class="card-text">17.27 cm (6.8 inch) Quad HD+ Display, 200MP + 10MP + 12MP +
                                10MP | 12MP Front Camera</p> */}
                    <div className="rating-container theme-krajee-svg rating-xs rating-animate rating-disabled">
                      <div className="rating-stars" tabIndex={0}>
                        <span className="empty-stars">
                          <span className="star" title="One Star">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Two Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Three Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Four Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Five Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                        </span>
                        <span className="filled-stars" style={{ width: "0%" }}>
                          <span className="star" title="One Star">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Two Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Three Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Four Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                          <span className="star" title="Five Stars">
                            <span className="krajee-icon krajee-icon-star" />
                          </span>
                        </span>
                      </div>
                    </div>
                    <input
                      id="input-3-ltr-star-md"
                      name="input-3-ltr-star-md"
                      className="kv-ltr-theme-svg-star rating-input"
                      defaultValue={0.0}
                      dir="ltr"
                      data-size="xs"
                      data-show-clear="false"
                      data-show-caption="false"
                      readOnly="readonly"
                      tabIndex={-1}
                    />
                    <div className="d-flex align-items-center gap-2">
                      <h4
                        className="card-price"
                        itemProp="offers"
                        itemType="https://schema.org/Offer"
                      >
                        <meta itemProp="price" content={15000} />
                        $10,600 <meta itemProp="priceCurrency" content="$" />
                      </h4>
                      <p className="m-0 offer_price">
                        <small>
                          <span
                            className="text-decoration-line-through fw-bold"
                            itemProp="price"
                          >
                            $15,000{" "}
                          </span>
                          <span className="text-success fw-bold">
                            <span className="product-discount-label fw-bold">
                              29% off
                            </span>
                          </span>
                        </small>
                      </p>
                    </div>
                  </NavLink>
                  <NavLink
                    className="btn add-in-cart-btn w-100 add_to_cart  p-1 btn-sm quickview-trigger"
                    to=""
                    data-product-id={84}
                    data-product-variant-id=""
                    data-product-title="Whirpool 200 L"
                    data-product-image="https://eshopweb.store/uploads/media/2020/230-im-pro-5s-prm-inv-sapphire-abyss-5-whirlpool-original-imaftfxdppyhcypf.jpg"
                    data-product-price={10600}
                    data-min={1}
                    data-max={5}
                    data-type="variable_product"
                    data-step={1}
                    data-product-description="Single Door 4 Star"
                    data-bs-toggle="modal"
                    data-bs-target="#quickview"
                  >
                    <span className="add-in-cart">Add to Cart</span>
                    <span className="add-in-cart-icon">
                      <svg
                        className="svg-inline--fa fa-cart-shopping color-white"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="cart-shopping"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="currentColor"
                          d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"
                        />
                      </svg>
                      {/* <i class="fa-solid fa-cart-shopping color-white"></i> Font Awesome fontawesome.com */}
                    </span>
                  </NavLink>
                </div>
                <div className="product-icon-onhover">
                  <div className="product-icon-spacebtw">
                    <div className="shuffle-box">
                      <NavLink
                        className="compare text-reset text-decoration-none shuffle"
                        data-tip="Compare"
                        data-product-id={84}
                        data-product-variant-id=""
                      >
                        <ion-icon
                          name="shuffle-outline"
                          className="ion-icon-hover pointer shuffle md hydrated"
                          role="img"
                        />
                      </NavLink>
                    </div>
                  </div>
                  <div className="product-icon-spacebtw">
                    <div
                      className="quick-search-box quickview-trigger"
                      data-tip="Quick View"
                      data-product-id={84}
                      data-product-variant-id={156}
                      data-izimodal-open="#quickview"
                    >
                      <ion-icon
                        name="search-outline"
                        className="ion-icon-hover pointer md hydrated"
                        data-bs-toggle="modal"
                        data-bs-target="#quickview"
                        role="img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-center">
          <li className="page-item active">
            <NavLink className="page-link">1</NavLink>
          </li>
          <li className="page-item">
            <NavLink
              to="https://eshopweb.store/products/2"
              className="page-link"
              data-ci-pagination-page={2}
            >
              2
            </NavLink>
          </li>
          <li className="page-item">
            <NavLink
              to="https://eshopweb.store/products/3"
              className="page-link"
              data-ci-pagination-page={3}
            >
              3
            </NavLink>
          </li>
          <li className="page-item">
            <NavLink
              to="https://eshopweb.store/products/4"
              className="page-link"
              data-ci-pagination-page={4}
            >
              4
            </NavLink>
          </li>
          <li className="page-item">
            <NavLink
              to="https://eshopweb.store/products/2"
              className="page-link"
              data-ci-pagination-page={2}
              rel="next"
            >
              <svg
                className="svg-inline--fa fa-arrow-right"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="arrow-right"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                data-fa-i2svg=""
              >
                <path
                  fill="currentColor"
                  d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"
                />
              </svg>
              {/* <i class="fa fa-arrow-right"></i> Font Awesome fontawesome.com */}
            </NavLink>
          </li>
        </ul>{" "}
      </nav>
    </section>
  );
};

export default Products;
