import React from 'react'

const Details = () => {
  return (
    <section className="container py-4">
    <div className="Product-Detail-card row">
      {/* pc screen product photo view */}
      <div className="d-none d-lg-block product-img-section-md col-lg-6 row g-0 align-content-start">
        <div className="row align-items-center d-flex flex-row-reverse my-2">
          <div className="col-md-10">
            <div className="swiper mySwiper-preview gallery-top-1 swiper-initialized swiper-horizontal swiper-backface-hidden">
              <div
                className="swiper-wrapper"
                id="swiper-wrapper-f2598599ee5ebad10"
                aria-live="polite"
              >
                <div
                  className="product-swiper-slide swiper-slide swiper-slide-active"
                  role="group"
                  aria-label="1 / 5"
                  style={{ width: 400, marginRight: 10 }}
                >
                  <link
                    itemProp="image"
                    href="https://eshopweb.store/uploads/media/2020/potato_b.png"
                  />
                  <div
                    style={{ height: "333.225px", width: 400 }}
                    className="zoomWrapper"
                  >
                    <img
                      src="https://eshopweb.store/uploads/media/2020/potato_b.png"
                      className="zoom_03"
                      style={{ position: "absolute" }}
                    />
                  </div>
                </div>
                <div
                  className="product-swiper-slide swiper-slide swiper-slide-next"
                  role="group"
                  aria-label="2 / 5"
                  style={{ width: 400, marginRight: 10 }}
                >
                  <link
                    itemProp="image"
                    href="https://eshopweb.store/uploads/media/2020/potato_d.png"
                  />
                  <div
                    style={{ height: "333.225px", width: 400 }}
                    className="zoomWrapper"
                  >
                    <img
                      src="https://eshopweb.store/uploads/media/2020/potato_d.png"
                      className="zoom_03"
                      style={{ position: "absolute" }}
                    />
                  </div>
                </div>
                <div
                  className="product-swiper-slide swiper-slide"
                  role="group"
                  aria-label="3 / 5"
                  style={{ width: 400, marginRight: 10 }}
                >
                  <link
                    itemProp="image"
                    href="https://eshopweb.store/uploads/media/2020/potato_c.png"
                  />
                  <div
                    style={{ height: "333.225px", width: 400 }}
                    className="zoomWrapper"
                  >
                    <img
                      src="https://eshopweb.store/uploads/media/2020/potato_c.png"
                      className="zoom_03"
                      style={{ position: "absolute" }}
                    />
                  </div>
                </div>
                <div
                  className="product-swiper-slide swiper-slide"
                  role="group"
                  aria-label="4 / 5"
                  style={{ width: 400, marginRight: 10 }}
                >
                  <link
                    itemProp="image"
                    href="https://eshopweb.store/uploads/media/2020/potato_a.png"
                  />
                  <div
                    style={{ height: "333.225px", width: 400 }}
                    className="zoomWrapper"
                  >
                    <img
                      src="https://eshopweb.store/uploads/media/2020/potato_a.png"
                      className="zoom_03"
                      style={{ position: "absolute" }}
                    />
                  </div>
                </div>
                <div
                  className="product-swiper-slide swiper-slide"
                  role="group"
                  aria-label="5 / 5"
                  style={{ width: 400, marginRight: 10 }}
                >
                  <link
                    itemProp="image"
                    href="https://eshopweb.store/uploads/media/2020/potato_b.png"
                  />
                  <div
                    style={{ height: "333.225px", width: 400 }}
                    className="zoomWrapper"
                  >
                    <img
                      src="https://eshopweb.store/uploads/media/2020/potato_b.png"
                      className="zoom_03"
                      style={{ position: "absolute" }}
                    />
                  </div>
                </div>
              </div>
              <div
                className="swiper-button-next"
                tabIndex={0}
                role="button"
                aria-label="Next slide"
                aria-controls="swiper-wrapper-f2598599ee5ebad10"
                aria-disabled="false"
              />
              <div
                className="swiper-button-prev swiper-button-disabled"
                tabIndex={-1}
                role="button"
                aria-label="Previous slide"
                aria-controls="swiper-wrapper-f2598599ee5ebad10"
                aria-disabled="true"
              />
              <span
                className="swiper-notification"
                aria-live="assertive"
                aria-atomic="true"
              />
              <span
                className="swiper-notification"
                aria-live="assertive"
                aria-atomic="true"
              />
              <span
                className="swiper-notification"
                aria-live="assertive"
                aria-atomic="true"
              />
            </div>
          </div>
          <div className="image-Thumbnail-container col-md-2 p-1">
            <div className="xzoom-thumbs">
              <div
                thumbsslider=""
                className="swiper mySwiper-thumb gallery-thumbs-1 swiper-initialized swiper-vertical swiper-watch-progress swiper-backface-hidden swiper-thumbs"
              >
                <div
                  className="swiper-wrapper"
                  id="swiper-wrapper-59811d9541fac210e"
                  aria-live="polite"
                  style={{ transform: "translate3d(0px, 0px, 0px)" }}
                >
                  <div
                    className="product-swiper-slide swiper-slide swiper-slide-thumb-active swiper-slide-visible swiper-slide-active"
                    role="group"
                    aria-label="1 / 5"
                    style={{ height: "87.5px", marginBottom: 10 }}
                  >
                    <link
                      itemProp="image"
                      href="https://eshopweb.store/uploads/media/2020/potato_b.png"
                    />
                    <img src="https://eshopweb.store/uploads/media/2020/potato_b.png" />
                  </div>
                  <div
                    className="product-swiper-slide swiper-slide swiper-slide-visible swiper-slide-next"
                    role="group"
                    aria-label="2 / 5"
                    style={{ height: "87.5px", marginBottom: 10 }}
                  >
                    <link
                      itemProp="image"
                      href="https://eshopweb.store/uploads/media/2020/potato_d.png"
                    />
                    <img src="https://eshopweb.store/uploads/media/2020/potato_d.png" />
                  </div>
                  <div
                    className="product-swiper-slide swiper-slide swiper-slide-visible"
                    role="group"
                    aria-label="3 / 5"
                    style={{ height: "87.5px", marginBottom: 10 }}
                  >
                    <link
                      itemProp="image"
                      href="https://eshopweb.store/uploads/media/2020/potato_c.png"
                    />
                    <img src="https://eshopweb.store/uploads/media/2020/potato_c.png" />
                  </div>
                  <div
                    className="product-swiper-slide swiper-slide swiper-slide-visible"
                    role="group"
                    aria-label="4 / 5"
                    style={{ height: "87.5px", marginBottom: 10 }}
                  >
                    <link
                      itemProp="image"
                      href="https://eshopweb.store/uploads/media/2020/potato_a.png"
                    />
                    <img src="https://eshopweb.store/uploads/media/2020/potato_a.png" />
                  </div>
                  <div
                    className="product-swiper-slide swiper-slide"
                    role="group"
                    aria-label="5 / 5"
                    style={{ height: "87.5px", marginBottom: 10 }}
                  >
                    <link
                      itemProp="image"
                      href="https://eshopweb.store/uploads/media/2020/potato_b.png"
                    />
                    <img src="https://eshopweb.store/uploads/media/2020/potato_b.png" />
                  </div>
                </div>
                <span
                  className="swiper-notification"
                  aria-live="assertive"
                  aria-atomic="true"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* mobile screen product photo view */}
      <div className="d-sm-block d-lg-none col-lg-6 row g-0 align-content-start">
        <div className="swiper mySwiper swiper-initialized swiper-horizontal">
          <div
            className="swiper-wrapper height500px"
            id="swiper-wrapper-8852c88852f0eff8"
            aria-live="off"
            style={{ transitionDuration: "300ms" }}
          >
            <div
              className="swiper-slide text-center position-relative swiper-slide-prev"
              data-swiper-slide-index={0}
              role="group"
              aria-label="1 / 5"
            >
              <img src="https://eshopweb.store/uploads/media/2020/potato_b.png" />
            </div>
            <div
              className="swiper-slide text-center position-relative swiper-slide-active"
              data-swiper-slide-index={1}
              role="group"
              aria-label="2 / 5"
            >
              <img src="https://eshopweb.store/uploads/media/2020/potato_d.png" />
            </div>
            <div
              className="swiper-slide text-center position-relative swiper-slide-next"
              data-swiper-slide-index={2}
              role="group"
              aria-label="3 / 5"
            >
              <img src="https://eshopweb.store/uploads/media/2020/potato_c.png" />
            </div>
            <div
              className="swiper-slide text-center position-relative"
              data-swiper-slide-index={3}
              role="group"
              aria-label="4 / 5"
            >
              <img src="https://eshopweb.store/uploads/media/2020/potato_a.png" />
            </div>
            <div
              className="swiper-slide text-center position-relative"
              data-swiper-slide-index={4}
              role="group"
              aria-label="5 / 5"
            >
              <img src="https://eshopweb.store/uploads/media/2020/potato_b.png" />
            </div>
          </div>
          <div
            className="swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal swiper-pagination-bullets-dynamic"
            style={{ width: 40 }}
          >
            <span
              className="swiper-pagination-bullet swiper-pagination-bullet-active-prev"
              style={{ left: 8 }}
            />
            <span
              className="swiper-pagination-bullet swiper-pagination-bullet-active swiper-pagination-bullet-active-main"
              aria-current="true"
              style={{ left: 8 }}
            />
            <span
              className="swiper-pagination-bullet swiper-pagination-bullet-active-next"
              style={{ left: 8 }}
            />
            <span
              className="swiper-pagination-bullet swiper-pagination-bullet-active-next-next"
              style={{ left: 8 }}
            />
            <span className="swiper-pagination-bullet" style={{ left: 8 }} />
          </div>
          <span
            className="swiper-notification"
            aria-live="assertive"
            aria-atomic="true"
          />
        </div>
        <div className="d-none d-lg-none d-xl-block product-add-section align-self-center px-3">
          {/* Change the `data-field` of buttons and `name` of input field's for multiple plus minus buttons*/}
          &lt;
          <div className="input-group plus-minus-input mb-3 num-block">
            <div className="input-group-button">
              <button
                type="button"
                className="button hollow circle minus-btn minus"
                data-quantity="minus"
                data-field="quantity"
                data-min={1}
                data-step={1}
              >
                <svg
                  className="svg-inline--fa fa-minus"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="minus"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  data-fa-i2svg=""
                >
                  <path
                    fill="currentColor"
                    d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"
                  />
                </svg>
                {/* <i class="fa-solid fa-minus"></i> Font Awesome fontawesome.com */}
              </button>
            </div>
            <div className="product-quantity product-sm-quantity border-0 m-0">
              <input
                className="input-group-field input-field-cart-modal in-num"
                type="number"
                name="qty"
                defaultValue={1}
                data-step={1}
                data-min={1}
                data-max={5}
              />
            </div>
            <div className="input-group-button">
              <button
                type="button"
                className="button hollow circle plus-btn plus"
                data-quantity="plus"
                data-field="quantity"
                data-max={5}
                data-step={1}
              >
                <svg
                  className="svg-inline--fa fa-plus"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="plus"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  data-fa-i2svg=""
                >
                  <path
                    fill="currentColor"
                    d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                  />
                </svg>
                {/* <i class="fa-solid fa-plus"></i> Font Awesome fontawesome.com */}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="p-3 default-cursor">
          <h4 className="product-title">Fresh Potato </h4>
          <p className="text-muted">Fresh Potato </p>
          <div
            className=" d-flex cursor-not-allowed"
            itemProp="aggregateRating"
            itemScope=""
            itemType="https://schema.org/AggregateRating"
          >
            <meta itemProp="reviewCount" content={1} />
            <meta itemProp="ratingValue" content={4.0} />
            <div className="rating-container theme-krajee-svg rating-xs rating-animate rating-disabled">
              <div className="rating-stars" tabIndex={0}>
                <span className="empty-stars">
                  <span className="star" title="One Star">
                    <span className="krajee-icon krajee-icon-star" />
                  </span>
                  <span className="star" title="Two Stars">
                    <span className="krajee-icon krajee-icon-star" />
                  </span>
                  <span className="star" title="Three Stars">
                    <span className="krajee-icon krajee-icon-star" />
                  </span>
                  <span className="star" title="Four Stars">
                    <span className="krajee-icon krajee-icon-star" />
                  </span>
                  <span className="star" title="Five Stars">
                    <span className="krajee-icon krajee-icon-star" />
                  </span>
                </span>
                <span className="filled-stars" style={{ width: "80%" }}>
                  <span className="star" title="One Star">
                    <span className="krajee-icon krajee-icon-star" />
                  </span>
                  <span className="star" title="Two Stars">
                    <span className="krajee-icon krajee-icon-star" />
                  </span>
                  <span className="star" title="Three Stars">
                    <span className="krajee-icon krajee-icon-star" />
                  </span>
                  <span className="star" title="Four Stars">
                    <span className="krajee-icon krajee-icon-star" />
                  </span>
                  <span className="star" title="Five Stars">
                    <span className="krajee-icon krajee-icon-star" />
                  </span>
                </span>
              </div>
            </div>
            <input
              id="input-3-ltr-star-md"
              name="input-3-ltr-star-md"
              className="kv-ltr-theme-svg-star rating-input"
              defaultValue={4.0}
              dir="ltr"
              data-size="xs"
              data-show-clear="false"
              data-show-caption="false"
              readOnly="readonly"
              tabIndex={-1}
            />
            <span className="ms-2">
              <small>( 1 Reviews)</small>
            </span>
          </div>
          <div itemProp="offers" itemScope="" itemType="https://schema.org/Offer">
            <meta itemProp="price" content="10.5" />
            <meta itemProp="priceCurrency" content="$" />
          </div>
          <p className="mb-0 mt-2 price text-muted">
            <span id="price" style={{ fontSize: 20 }}>
              $10.50{" "}
            </span>
          </p>
          {/* <input type="radio" name="Item Weight" value="9" autocomplete="off" class="filter-input attributes">/ */}
          <ul
            className="d-flex gap-2 mb-2 flex-wrap align-items-center list-unstyled quickview-variant-sec"
            id="Item Weight"
          >
            <li className="text-dark-emphasis fw-semibold">Item Weight :</li>
            <style
              dangerouslySetInnerHTML={{
                __html:
                  " .product-page-details .btn-group>.active { background-color: var(--primary-color);color: white!important;}"
              }}
            />{" "}
            <li className="default-swatch swatch">
              <label className="m-0 position-relative">
                <input
                  type="radio"
                  name="Item Weight"
                  defaultValue={18}
                  autoComplete="off"
                  className="filter-input attributes"
                />
                250 g{" "}
              </label>
            </li>
            <style
              dangerouslySetInnerHTML={{
                __html:
                  " .product-page-details .btn-group>.active { background-color: var(--primary-color);color: white!important;}"
              }}
            />{" "}
            <li className="default-swatch swatch">
              <label className="m-0 position-relative">
                <input
                  type="radio"
                  name="Item Weight"
                  defaultValue={38}
                  autoComplete="off"
                  className="filter-input attributes"
                />
                2 kg{" "}
              </label>
            </li>
          </ul>
          <input
            type="hidden"
            className="variants"
            name="variants_ids"
            data-image-index={1}
            data-name=""
            defaultValue={18}
            data-id={60}
            data-price="10.5"
            data-special_price="10.5"
          />
          <input
            type="hidden"
            className="variants"
            name="variants_ids"
            data-image-index={1}
            data-name=""
            defaultValue={38}
            data-id={61}
            data-price={63}
            data-special_price={21}
          />
          <p className="mb-1 mt-1">
            <span className="text-muted">
              <small>(Inclusive of all taxes)</small>
            </span>
          </p>
        </div>
        <div></div>
        {/* offer section */}
        {/* <div class="offer-section py-3 px-3">
            <h4 class="element-title fw-semibold default-cursor">Avilable Offers</h4>
            <div class="d-flex flex-wrap gap-2 my-2">
                <div class="offer-detail pointer">
                    <h6 class="offer-head">Bank Offer</h6>
                    <p class="offer-text">Upto ₹5,000.00 discount on select Credit Cards, selec…</p>
                    <a href="#" class="text-decoration-none"><span class="offer-btn">3
                            Offer</span>
                        ></a>
                </div>
                <div class="offer-detail pointer">
                    <h6 class="offer-head">No Cost EMI</h6>
                    <p class="offer-text">Upto ₹10,924.59 EMI interest savings on select Credit Ca…</p>
                    <a href="#" class="text-decoration-none"><span class="offer-btn">3
                            Offer</span>
                        ></a>
                </div>
                <div class="offer-detail pointer">
                    <h6 class="offer-head">Partner Offers</h6>
                    <p class="offer-text">Get Free One Week BYJU’s Concept Pack worth Rs999 on p...</p>
                    <a href="#" class="text-decoration-none"><span class="offer-btn">3
                            Offer</span>
                        ></a>
                </div>
            </div>
        </div> */}
        <input
          type="hidden"
          className="variants_data"
          id="variants_data"
          data-name="Fresh Potato"
          data-image="https://eshopweb.store/uploads/media/2020/potato_b.png"
          data-id={61}
          data-price={63}
          data-special_price={21}
        />
        <div className="compare-share-btn py-3 px-3">
          <ul className="d-flex gap-3 list-unstyled m-0">
            <li>
              <a
                className="btn d-flex p-0 compare text-reset text-decoration-none shuffle"
                data-tip="Compare"
                data-product-id={33}
                data-product-variant-id=""
              >
                <img
                  src="https://eshopweb.store/assets/front_end/modern/image/icons/shuffle.png"
                  alt=""
                />
                <p>Compare</p>
              </a>
            </li>
            <li>
              <div className="d-flex align-items-center">
                Share:{" "}
                <span
                  className="ms-1 quick_view_share jssocials"
                  id="quick_view_share"
                >
                  <div className="jssocials-shares">
                    <div className="jssocials-share jssocials-share-facebook">
                      <a
                        target="_blank"
                        href="https://facebook.com/sharer/sharer.php?u=https%3A%2F%2Feshopweb.store%2Fproducts%2Fdetails%2Ffresh-potato-1"
                        className="jssocials-share-link"
                      >
                        <img
                          src="https://eshopweb.store/assets/front_end/modern/ionicons/dist/ionicons/svg/logo-facebook.svg"
                          className="jssocials-share-logo"
                        />
                      </a>
                      <div className="jssocials-share-count-box jssocials-share-no-count">
                        <span className="jssocials-share-count" />
                      </div>
                    </div>
                    <div className="jssocials-share jssocials-share-whatsapp">
                      <a
                        target="_self"
                        href="whatsapp://send?text=https%3A%2F%2Feshopweb.store%2Fproducts%2Fdetails%2Ffresh-potato-1 Fresh%20Potato%20%7C%20Eshop%20is%20an%20ecommerce%20platform"
                        className="jssocials-share-link"
                      >
                        <img
                          src="https://eshopweb.store/assets/front_end/modern/ionicons/dist/svg/logo-whatsapp.svg"
                          className="jssocials-share-logo"
                        />
                      </a>
                      <div className="jssocials-share-count-box jssocials-share-no-count">
                        <span className="jssocials-share-count" />
                      </div>
                    </div>
                    <div className="jssocials-share jssocials-share-twitter">
                      <a
                        target="_blank"
                        href="https://twitter.com/share?url=https%3A%2F%2Feshopweb.store%2Fproducts%2Fdetails%2Ffresh-potato-1&text=Fresh%20Potato%20%7C%20Eshop%20is%20an%20ecommerce%20platform"
                        className="jssocials-share-link"
                      >
                        <img
                          src="https://eshopweb.store/assets/front_end/modern/ionicons/dist/svg/logo-twitter.svg"
                          className="jssocials-share-logo"
                        />
                      </a>
                      <div className="jssocials-share-count-box jssocials-share-no-count">
                        <span className="jssocials-share-count" />
                      </div>
                    </div>
                    <div className="jssocials-share jssocials-share-linkedin">
                      <a
                        target="_blank"
                        href="https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Feshopweb.store%2Fproducts%2Fdetails%2Ffresh-potato-1"
                        className="jssocials-share-link"
                      >
                        <img
                          src="https://eshopweb.store/assets/front_end/modern/ionicons/dist/svg/logo-linkedin.svg"
                          className="jssocials-share-logo"
                        />
                      </a>
                      <div className="jssocials-share-count-box jssocials-share-no-count">
                        <span className="jssocials-share-count" />
                      </div>
                    </div>
                  </div>
                </span>
              </div>
            </li>
          </ul>
        </div>
        <div className="row py-3 px-3 product-add-section align-self-center">
          <div itemProp="offers" itemScope="" itemType="https://schema.org/Offer">
            <meta itemProp="price" content="10.5" />
            <meta itemProp="priceCurrency" content="$" />
          </div>
          <form className="mt-2" id="validate-city-form" method="POST">
            <div className="d-flex flex-wrap gap-2 form-row">
              <div className=" col-md-6">
                <input type="hidden" name="product_id" defaultValue={33} />
                <input
                  type="text"
                  className="form-control"
                  id="zipcode"
                  placeholder="City"
                  name="city"
                  autoComplete="off"
                  required=""
                  defaultValue=""
                />
              </div>
              <button
                type="submit"
                className="btn btn-primary"
                id="validate_city"
              >
                Check Availability
              </button>
            </div>
            <div className="mt-2" id="error_box"></div>
          </form>
          <div className="input-group plus-minus-input mb-3 num-block">
            <div className="input-group-button">
              <button
                type="button"
                className="button hollow circle minus-btn minus"
                data-quantity="minus"
                data-field="quantity"
                data-min={1}
                data-step={1}
              >
                <svg
                  className="svg-inline--fa fa-minus"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="minus"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  data-fa-i2svg=""
                >
                  <path
                    fill="currentColor"
                    d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"
                  />
                </svg>
                {/* <i class="fa-solid fa-minus"></i> Font Awesome fontawesome.com */}
              </button>
            </div>
            <div className="product-quantity product-sm-quantity border-0 m-0">
              <input
                className="input-group-field input-field-cart-modal in-num"
                type="number"
                name="qty"
                defaultValue={1}
                data-step={1}
                data-min={1}
                data-max={5}
                data-price="10.5"
                data-id={33}
              />
            </div>
            <div className="input-group-button">
              <button
                type="button"
                className="button hollow circle plus-btn plus"
                data-quantity="plus"
                data-field="quantity"
                data-max={5}
                data-step={1}
              >
                <svg
                  className="svg-inline--fa fa-plus"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="plus"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  data-fa-i2svg=""
                >
                  <path
                    fill="currentColor"
                    d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                  />
                </svg>
                {/* <i class="fa-solid fa-plus"></i> Font Awesome fontawesome.com */}
              </button>
            </div>
          </div>
          <div className="d-flex gap-2">
            <a href="#" className="">
              <button
                type="button"
                name="add_cart"
                className="add_to_cart btn btn-primary"
                id="add_cart"
                title="Add in Cart"
                data-product-id={33}
                data-product-title="Fresh Potato"
                data-product-image="https://eshopweb.store/uploads/media/2020/potato_b.png"
                data-product-price={21}
                data-product-description="Fresh Potato"
                data-step={1}
                data-min={1}
                data-max={5}
                data-product-variant-id=""
              >
                Add in Cart{" "}
              </button>
            </a>
            <a
              href="https://eshopweb.store/cart"
              name="buy_it_now"
              className="add_to_cart btn btn-success buy_now"
              id="buy_it_now"
              title="Buy it now"
              data-product-id={33}
              data-product-title="Fresh Potato"
              data-product-image="https://eshopweb.store/uploads/media/2020/potato_b.png"
              data-product-price={21}
              data-product-description="Fresh Potato"
              data-step={1}
              data-min={1}
              data-max={5}
              data-product-variant-id=""
            >
              Buy It Now{" "}
            </a>
            <a
              href="https://api.whatsapp.com/send?phone= 8799587762 &text=Hello, I Seen This Fresh Potato In Your Website And I Want to Buy This https://eshopweb.store/products/details/fresh-potato-1"
              target="_blank"
              title="Order From Whatsapp"
            >
              <button
                type="button"
                className="btn whatsapp d-flex justify-content-center align-items-center"
              >
                <ion-icon
                  name="logo-whatsapp"
                  className="me-2 fs-4 md hydrated"
                  role="img"
                />
                Order From Whatsapp
              </button>
            </a>
          </div>
        </div>
        <div className="delivery-service py-3 px-3">
          <ul className="gap-2 list-unstyled m-0">
            <li>
              <img
                src="https://eshopweb.store/assets/front_end/modern/image/icons/no-warranty.png"
                alt=""
              />
              <p className="m-0 text-muted">No Warranty</p>
            </li>
            <li>
              <img
                src="https://eshopweb.store/assets/front_end/modern/image/icons/cash-on-delivery.png"
                alt=""
              />
              <p className="m-0 text-muted">COD Avilable</p>
            </li>
            <li>
              <img
                src="https://eshopweb.store/assets/front_end/modern/image/icons/return-box.png"
                alt=""
              />
              <p className="m-0 text-muted">Returnable</p>
            </li>
            <li>
              <img
                src="https://eshopweb.store/assets/front_end/modern/image/icons/cancel.png"
                alt=""
              />
              <p className="m-0 text-muted">cancelable</p>
            </li>
          </ul>
        </div>
        <div className="specification py-3 px-3">
          <h4 className="element-title fw-semibold default-cursor">
            Specifications
          </h4>
          <table className="w-100">
            <tbody>
              <tr>
                <td className="col-5">
                  <span className="fw-bold">Item Weight</span>
                </td>
                <td className="col-7">
                  <span> 250 g, 2 kg</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="item-detail pb-3 px-3 overflow-hidden">
          <h4 className="element-title fw-semibold default-cursor">
            About this item
          </h4>
          <p className="m-0" />
          <p>
            <strong>
              Fresho Potatoes are nutrient-dense, non-fattening and have
              reasonable amount of calories. Include them in your regular meals so
              that the body receives a good supply of carbohydrates, dietary
              fibers and essential minerals such as copper, magnesium, and iron.
              In India, potatoes are probably the second-most consumed vegetables
              after onions.
            </strong>
          </p>
          <p />
        </div>
      </div>
    </div>
  </section>
  
  )
}

export default Details